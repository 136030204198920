import React, { Children } from "react";
import MaterialIconButton from "@mui/material/IconButton";
import { withStyles } from "@mui/styles";
import { Tooltip } from "ancient/components/Tooltip";

const StyledIconButton = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    "&:hover": {
      color: theme.palette.primary.dark,
    },
  },
}))(MaterialIconButton);

const StyledOutlinedIconButton = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    color: theme.palette.background.main,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}))(MaterialIconButton);

/**
 * Preconfigured Material UI IconButton
 */
export const IconButton = ({
  tooltipTitle = "",
  isOutlined = false,
  children,
  ...props
}) => {
  return (
    <Tooltip
      PopperProps={{
        disablePortal: true,
      }}
      variant="info"
      arrow
      title={tooltipTitle}
      {...props}
    >
        {isOutlined ? (
          <StyledOutlinedIconButton {...props}>
            {children}
          </StyledOutlinedIconButton>
        ) : (
          <StyledIconButton {...props}>{children}</StyledIconButton>
        )}
    </Tooltip>
  );
};

import React from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/material";

const Line = styled("div")(({ theme, variant }) => {
  const color = {
    default: theme.palette.grey[200],
    corporate: theme.palette.primary.main,
    white: theme.palette.grey[50],
  };

  return { height: 0, width: "100%", borderTop: `1px solid ${color[variant]}` };
});

export const Separator = ({ spacing = 0, variant = "default" }) => (
  <Box py={spacing} width="100%">
    <Line variant={variant} />
  </Box>
);

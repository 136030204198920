import React, { useState, useRef } from "react";
import { makeStyles } from "@mui/styles";
import { Stack } from "ancient/components/layouts/Stack";
import { Button } from "ancient/components/Button";
import Typography from "@mui/material/Typography";
import uniq from "lodash.uniq";
import compact from "lodash.compact";
import Box from "@mui/material/Box";
import { LegacyTextField } from "ancient/components/LegacyTextField";
import { Tiles } from "components/layouts/Tiles";
import ibanLib from "iban";
import { useRequestChangeContext } from "../context";
import { useTranslation } from "react-i18next";
import Radio from "@mui/material/Radio";
import Alert from "@mui/material/Alert";
import Checkbox from "@mui/material/Checkbox";
import { formatIBAN } from "lib/helpers/format";
import { theme } from "theme";

const useStyles = makeStyles((theme) => ({
  subscriptionIBAN: {
    backgroundColor: theme.palette.background.dark,
    padding: theme.spacing(0, 1),
    marginLeft: theme.spacing(3),
    textAlign: "center",
  },
}));

const NewIbanLit = "new-iban";

export const ChangeIban = () => {
  const { t } = useTranslation();

  const { setNewValue, subscriptions, requestConfirm, selectedSubscriptions } =
    useRequestChangeContext();

  const [isConfirmed, setIsConfirmed] = useState(false);
  const [selectedIBAN, setSelectedIBAN] = useState(null);
  const [newIBAN, setNewIBAN] = useState(null);
  const ibans = compact(uniq(subscriptions.map(({ iban }) => iban)));
  const [hasInvalidIBAN, setHasInvalidIBAN] = useState(false);
  const [isExistingIBAN, setIsExistingIBAN] = useState(false);
  const [isSameIBAN, setIsSameIBAN] = useState(false);
  const textInputRef = useRef(null);

  const showIBANSubscription =
    selectedSubscriptions.length === 1 || ibans.length === 1;

  const IBANSubscription = () => {
    if (showIBANSubscription) {
      return ibans.length === 1
        ? ibans[0]
        : subscriptions.find(({ code }) => selectedSubscriptions[0] === code)
            .iban;
    }
    return "";
  };

  const getFilteredIbans = () =>
    showIBANSubscription
      ? ibans.filter((iban) => iban !== IBANSubscription())
      : ibans;

  const clearErrors = () => {
    setTimeout(() => {
      setHasInvalidIBAN(false);
      setIsExistingIBAN(false);
      setIsSameIBAN(false);
    }, 250);
  };

  const onClick = () => {
    if (selectedIBAN === NewIbanLit) {
      if (!ibanLib.isValid(newIBAN)) {
        setHasInvalidIBAN(true);
        return;
      }
      const trimNewIBAN = newIBAN.trim().replace(/ /g, "");
      const isIncluded = getFilteredIbans().includes(trimNewIBAN);
      const isSame = IBANSubscription() === trimNewIBAN;
      if (isIncluded || isSame) {
        setIsExistingIBAN(isIncluded);
        setIsSameIBAN(isSame);
        return;
      }
      setNewValue(newIBAN);
    } else {
      setNewValue(selectedIBAN);
    }
    requestConfirm();
  };

  const getAlertMessage = () =>
    isSameIBAN
      ? "request_change.change_iban.without_effect"
      : "request_change.change_iban." +
        (hasInvalidIBAN ? "invalid_iban" : "existing_iban");

  const classes = useStyles();

  function ListIBANSelectable() {
    const ibansToList = getFilteredIbans();
    return ibansToList.map((iban) => (
      <Box
        display="flex"
        alignItems="center"
        fontSize={[12, 14]}
        cursor="pointer"
        key={iban}
        onClick={() => {
          clearErrors();
          setSelectedIBAN(iban);
        }}
      >
        <Radio value={iban} checked={iban === selectedIBAN} />
        {formatIBAN(iban)}
      </Box>
    ));
  }

  return (
    <div>
      <Stack>
        <Typography variant="h5" component="h1">
          {t("request_change.change_iban.title")}
        </Typography>
        {showIBANSubscription && (
          <Tiles columns={1} spacing={0}>
            <Box ml={3} color={theme.palette.info.dark}>
              {selectedSubscriptions.length === 1
                ? t("request_change.change_iban.subscription_IBAN")
                : t("request_change.change_iban.subscriptions_IBAN")}
            </Box>
            <Typography className={classes.subscriptionIBAN}>
              {formatIBAN(IBANSubscription())}
            </Typography>
          </Tiles>
        )}

        {ibans.length > 1 && (
          <Box border={2} my={2} borderColor={theme.palette.primary.dark}>
            <Box px={3} color={theme.palette.info.dark}>
              {t("request_change.change_iban.use_exist_iban")}
            </Box>
            <ListIBANSelectable />
          </Box>
        )}
        <Box
          display="flex"
          flexDirection="row"
          onClick={() => setSelectedIBAN(NewIbanLit)}
        >
          <Radio
            value={NewIbanLit}
            checked={selectedIBAN === NewIbanLit || ibans.length === 1}
          />
          <LegacyTextField
            ref={textInputRef}
            variant="standard"
            fullWidth={false}
            setState={setNewIBAN}
            onFocus={() => {
              clearErrors();
              setSelectedIBAN(NewIbanLit);
            }}
            label={t("request_change.change_iban.add_new_iban")}
          />
        </Box>
        {(hasInvalidIBAN || isExistingIBAN || isSameIBAN) && (
          <Box my={1}>
            <Alert severity={isExistingIBAN ? "warning" : "error"}>
              {t(getAlertMessage())}
            </Alert>
          </Box>
        )}
        <div style={{ cursor: "pointer" }}>
          <Box
            mt={4}
            display="flex"
            onClick={() => setIsConfirmed((val) => !val)}
          >
            <Checkbox checked={isConfirmed} />
            <Box display="flex" alignItems="center" fontSize="0.75rem">
              {t("request_change.change_iban.confirm_checkbox")}
            </Box>
          </Box>
        </div>
        <Box mt={2}>
          <Button
            fullWidth={false}
            onClick={onClick}
            disabled={!(isConfirmed && Boolean(selectedIBAN || newIBAN))}
          >
            {t("common.confirm")}
          </Button>
        </Box>
      </Stack>
    </div>
  );
};

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import HelpIcon from "@mui/icons-material/Help";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { Divider } from "components/Divider";
import { RadioToggle } from "components/fields/RadioToggle";
import { Form } from "components/Form";
import { Tiles, TileSpan } from "components/layouts/Tiles";
import { Trans, useTranslation } from "react-i18next";
import { Condition } from "../Condition";
import { PaymentMemberFeeGroup } from "./PaymentMemberFeeGroup";

export const Associate = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  return (
    <Tiles columns={1} spacing={6}>
      <TileSpan span={2}>
        <RadioToggle.FormField
          name="select_role"
          leftLabel={t("funnel.tariffs.step_confirm_modal.second_step.as_user")}
          leftValue={"coop_agreement"}
          rightLabel={t(
            "funnel.tariffs.step_confirm_modal.second_step.as_partner"
          )}
          rightValue={"member"}
        />
      </TileSpan>
      <Box
        sx={(theme) => ({
          display: "flex",
          padding: "16px 24px",
          alignItems: "flex-start",
          gap: "16px",
          borderRadius: "16px",
          background: theme.palette.sand.main,
        })}
      >
        <HelpIcon />
        <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
          <Typography variant="body_bold">
            {t("funnel.tariffs.step_confirm_modal.second_step.title")}
          </Typography>
          <Typography variant="body_small">
            <Trans
              i18nKey={`funnel.tariffs.step_confirm_modal.second_step.body.${
                isMobile ? "mobile" : "desktop"
              }`}
            />
          </Typography>
        </Box>
      </Box>
      <div />
      <Condition
        when="select_role"
        is={"member"}
        renderWhenFalse={
          <div>
            <Form.Submit endIcon={<ArrowForwardIcon />}>
              {t("common.continue")}
            </Form.Submit>
          </div>
        }
        initalValue={{ use_same_bank_account: true }}
      >
        <div>
          <Divider sx={{ mb: 6 }} />
          <PaymentMemberFeeGroup />
        </div>
      </Condition>
    </Tiles>
  );
};

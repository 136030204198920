import { Checkbox as MaterialCheckbox, FormControlLabel } from "@mui/material";
import { Field } from "react-final-form";
import { Text } from "ancient/components/Text";
import { CheckedIcon } from "components/icons/CheckedIcon";
import { UncheckedIcon } from "components/icons/UncheckedIcon";

export const Checkbox = ({ name, label, onChange, checked }) => {
  return (
    <FormControlLabel
      label={<Text>{label}</Text>}
      control={
        <MaterialCheckbox
          type="checkbox"
          icon={<UncheckedIcon />}
          checkedIcon={<CheckedIcon />}
          name={name}
          checked={checked}
          onChange={onChange}
        />
      }
    />
  );
};

Checkbox.FormField = ({ name, validate, initialValue, ...props }) => (
  <Field
    name={name}
    validate={validate}
    initialValue={initialValue}
    type="checkbox"
  >
    {({ input, meta }) => (
      <Checkbox {...props} checked={input.checked} onChange={input.onChange} />
    )}
  </Field>
);

const { styled } = require("@mui/material");

export const TopBarLayout = styled("div")(({ theme }) => ({
  position: "fixed",
  display: "flex",
  top: 0,
  left: 0,
  zIndex: 10,
  width: "100%",
  height: "70px",
  padding: "0px 24px",
  background: theme.palette.grey[50],
  borderBottom: `5px solid ${theme.palette.grey[900]}`,
}));

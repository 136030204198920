import { Typography } from "@mui/material";
import { SelectRow } from "components/layouts/SelectRow";
import { PricingSelect } from "components/PricingSelect";
import { Trans, useTranslation } from "react-i18next";
import { TechAction } from "./TechAction";
import { TechContent } from "./TechContent";
// review showAction, can be replaced by onClickAction
export const TechnologySelector = ({
  onClick,
  technologies,
  showAction = false,
}) => {
  const { t } = useTranslation();
  return (
    <SelectRow
      title={
        <Typography variant="body_bold">
          {t("funnel.tariffs.recommended.filters.label_bandWidth")}
        </Typography>
      }
    >
      {technologies.map((tech) => (
        <PricingSelect
          content={
            <TechContent
              literal={
                <Trans>{`funnel.tariffs.internet.offers.${tech.name}`}</Trans>
              }
              onClick={() => onClick(tech)}
            />
          }
          action={showAction && <TechAction />}
          isArrowed
          isSelected={tech.isSelected}
          key={tech.name}
        />
      ))}
    </SelectRow>
  );
};

import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export const MobilesContent = ({ mobiles, ...props }) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        textAlign: "center",
        pb: "16px",
        pt: "8px",
        px: "8px",
        cursor: "pointer",
        width: "100%",
      }}
      {...props}
    >
      <Typography variant="body_bold">
        {t("funnel.tariffs.internet_and_mobile.mobiles", { count: mobiles })}
      </Typography>
    </Box>
  );
};

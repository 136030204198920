export const ProfileIcon = () => (
  <svg
    width="45"
    height="45"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"
      stroke="#000"
    />
    <path d="M11 23l4 4 4-4" stroke="#000" />
  </svg>
);

import { Tiles } from "components/layouts/Tiles";
import { useTranslation } from "react-i18next";
import { Modal } from "components/layouts/Modal";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { Button } from "components/Button";

export const ModalAdviceOffer = ({
  onConfirm,
  onClose,
  form__id,
  ...props
}) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <Modal title={t("common.important")} onClose={onClose} {...props}>
      <Box
        sx={{
          p: isMobile ? "16px" : "48px",
          display: "flex",
          flexDirection: "column",
          bgcolor: "secondary.light",
          height: "100%",
          margin: "0 auto",
          gap: 8,
        }}
      >
        <Typography variant="body_bold">
          {t("funnel.shared.advice_offer_modal.title")}
        </Typography>
        <Tiles spacing={8}>
          <Button
            variant="outlined"
            form={form__id}
            type="submit"
            onClick={onConfirm}
          >
            {t("funnel.shared.advice_offer_modal.accept")}
          </Button>
          <Button onClick={onClose}>
            {t("funnel.shared.advice_offer_modal.decline")}
          </Button>
        </Tiles>
      </Box>
    </Modal>
  );
};

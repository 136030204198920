import React from "react";
import { PaymentStepGroup } from "./shared/StepGroups/PaymentStepGroup";
import { Container } from "ancient/components/layouts/Container";
import { Box } from "@mui/material";

export const Payment = () => {
  return (
    // <Container>
    <>
      <PaymentStepGroup />
      {/* TODO some padding at the bottom to avoid scroll jumping when collapsing boxes */}
      <Box height="200px" />
    </>
    // </Container>
  );
};

import { Box, Card, Typography, useMediaQuery } from "@mui/material";
import { Button } from "ancient/components/Button";
import { Fiber2Mobiles } from "components/icons/Fiber2Mobiles";
import { Fiber3Mobiles } from "components/icons/Fiber3Mobiles";
import { Tiles } from "components/layouts/Tiles";
import { Trans, useTranslation } from "react-i18next";
import { theme } from "theme";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
// TODO params will be contracts, calculate mobilesSharing and other needed params in the component
export const ContractsSharingData = ({ bound }) => {
  const { mobile: mobileContracts, fiber: fiberContract, boundId } = bound;
  const mobilesSharing = mobileContracts.length;
  const getIcon = (size) => {
    switch (mobilesSharing) {
      case 2:
        return <Fiber2Mobiles fontSize={size} />;
      case 3:
        return <Fiber3Mobiles fontSize={size} />;
      default:
        return <div />;
    }
  };

  const getLit = () => {
    let key = "share_data.contract.2_mob_desc";
    let mob3 = "";
    if (mobilesSharing === 3) {
      mob3 = mobileContracts[2].phone_number;
      key = "share_data.contract.3_mob_desc";
    }
    return (
      <Typography variant="body2">
        <Trans i18nKey={key}>
          {{
            mob1: mobileContracts[0].phone_number,
            mob2: mobileContracts[1].phone_number,
            mob3: mob3,
            fiber: fiberContract.description,
            address: fiberContract.address.street,
          }}
        </Trans>
      </Typography>
    );
  };

  const { t } = useTranslation();
  const history = useHistory();

  /* TODO  when show buttons , retore to xs { size: theme.spacing(10), mt: 9 } */
  const Iconstyle = useMediaQuery(theme.breakpoints.down("sm"))
    ? { size: theme.spacing(10), mt: 0 }
    : { size: theme.spacing(12), mt: 0 };

  return (
    <Box component={Card} width={1} py={2} p={4}>
      <Box display="flex">
        <Box mt={Iconstyle.mt}>{getIcon(Iconstyle.size)}</Box>
        <Box ml={5} mt={2}>
          <Tiles columns={1}>
            {getLit()}
            {/* TODO Implement stop sharing in next implementation */}
            <Tiles>
              <span></span>
              <Button
                onClick={() => history.push("share-data/limit-data/" + boundId)}
              >
                {t("common.edit")}
              </Button>
              {/* <Button onClick={() => {}}>
                  {t("share_data.stop_sharing")}
                </Button> */}
            </Tiles>
          </Tiles>
        </Box>
      </Box>
    </Box>
  );
};

import { Box, Typography } from "@mui/material";
const { useTranslation } = require("react-i18next");

export const HeaderCard = ({ description, price }) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 2,
      }}
    >
      <Typography variant="h4">{description}</Typography>
      <Typography variant="h1">{`${parseFloat(price).toFixed(2)}€`}</Typography>
      <Typography variant="body">{t("common.monthly")}</Typography>
    </Box>
  );
};

import { useStore } from "hooks/useStore";
import { PacksTariffPicker } from "../components/packs/PacksTariffPicker";

export const PackTariffs = ({ packs, ...props }) => {
  const { setLines, setIsSharedLines } = useStore();
  function onChange(matchedPack) {
    if (matchedPack) {
      setIsSharedLines(matchedPack.category === "mobile_shared_data");
      setLines(matchedPack.products);
    } else {
      setLines([]);
    }
  }
  return <PacksTariffPicker packs={packs} onChange={onChange} {...props} />;
};

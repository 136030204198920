import { Box, Typography, useMediaQuery } from "@mui/material";
import { BigCloud, SmallCloud } from "components/icons/Clouds";
import { Forest } from "components/icons/Forest";
import { ArrowCurve } from "components/icons/ArrowCurve";
import { Modal } from "components/layouts/Modal";
import { SuperCard } from "components/SuperCard";
import { useTranslation } from "react-i18next";
import { CardContractInfo } from "./packs/CardContractInfo";
import { MultiLine, Price } from "./PricePreview";
import { WLandlineChip, WOPermanenceChip } from "components/Chips";
import { formatBandwith } from "lib/helpers/format";
import { Divider } from "components/Divider";
import { getLoginUrl, getSignupUrl, openUrl } from "lib/helpers/urls";
import { Button } from "components/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Tiles } from "components/layouts/Tiles";

export const ModalContractTariff = ({
  lines,
  packs,
  isSharedLines,
  isPromotionActive,
  coop_agreement_code,
  ...props
}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  function getContent() {
    if (packs) {
      const BALine = lines.find((line) => line.category !== "mobile");
      const pack = packs.find(
        (pack) =>
          pack.has_landline_phone === BALine.has_landline_phone &&
          pack.fiber_bandwidth === BALine.bandwidth &&
          pack.mobiles_in_pack ===
            lines.filter((line) => line.category === "mobile").length
      );
      return (
        <CardContractInfo pack={pack} onContract={() => console.log("TODO")} />
      );
    }

    if (lines.length > 1) {
      return (
        <SuperCard>
          {lines.map(({ __id, name, price }) => (
            <MultiLine
              name={name}
              price={parseFloat(price).toFixed(2)}
              key={__id}
            />
          ))}
          <Box sx={{ ml: "16px" }}>
            <Price
              price={lines
                .reduce((total, { price }) => total + parseFloat(price), 0)
                .toFixed(2)}
              stackingPrice={true}
            />
          </Box>
        </SuperCard>
      );
    } else {
      const line = lines[0];
      const isInternet = line.category !== "mobile";
      const title = isInternet
        ? t(
            `funnel.tariffs.internet.offers.${line.category}${
              line.has_landline_phone ? "landline" : ""
            }`
          )
            .split("<br/>")
            .join("")
        : `${t("funnel.shared.category.mobile")} ${
            line.minutes < 99999 ? line.minutes + " min" : t("common.unlimited")
          } + ${line.data / 1024}GB`;
      return (
        <SuperCard>
          <Typography variant="body_xl_bold">{title}</Typography>
          <Box sx={{ display: "flex", alignItems: "flex-start", gap: "4px" }}>
            {line.has_landline_phone && <WLandlineChip />}
            <WOPermanenceChip />
          </Box>
          {isInternet && (
            <Typography variant="body_bold">
              {formatBandwith(
                line.category === "fiber" ? line.bandwidth : line.data
              )}
            </Typography>
          )}
          <Typography variant="body_small">
            TODO literals for each tariff: Esta tarifa es ideal para recibir
            llamadas y limitar el uso de datos. Por ejemplo, para menores de
            edad.
          </Typography>
          <Divider sx={{ mt: "-1rem" }} flexItem />
          <Price
            price={line.price.toFixed(2).toString()}
            stackingPrice={true}
          />
        </SuperCard>
      );
    }
  }

  function onWantsToBeSponsoredClick() {
    openUrl(
      getSignupUrl(lines, "sponsored", undefined, true, isSharedLines),
      "_parent"
    );
  }

  function onWantsToBeMemberClick() {
    openUrl(
      getSignupUrl(
        lines,
        "coop_agreement",
        isPromotionActive ? coop_agreement_code : "SC",
        true,
        isSharedLines,
        false,
        isPromotionActive
      ),
      "_parent"
    );
  }

  function onAlreadyUserClick() {
    openUrl(getLoginUrl(lines, isSharedLines, isPromotionActive));
  }

  return (
    <Modal title={t("common.contract")} {...props}>
      <Box
        sx={{
          bgcolor: "secondary.main",
        }}
      >
        <Box
          sx={{
            p: isMobile ? "16px" : "48px 96px 0px 96px",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            height: isMobile ? "69%" : "auto",
          }}
        >
          <Typography variant="h3">
            {t("funnel.tariffs.modal_contract.title")}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: isMobile ? "100%" : "528px",
            }}
          >
            {getContent()}
            <Box
              sx={{
                position: "relative",
                top: "30px",
              }}
            >
              <ArrowCurve variant="dashed" rotate={89} />
            </Box>
            <Box
              sx={{
                position: "relative",
                bottom: "65px",
                left: isMobile ? "30%" : "40%",
              }}
            >
              <BigCloud />
              <Box
                sx={{
                  position: "absolute",
                  top: "20px",
                  right: "92px",
                }}
              >
                <SmallCloud />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            position: "relative",
            bottom: "-7px",
            left: 0,
            textAlign: "center",
          }}
        >
          <Forest />
        </Box>
        <Box
          sx={(theme) => ({
            borderTop: `2px solid ${theme.palette.grey[900]}`,
            background: theme.palette.grey[50],
            position: "relative",
            bottom: 0,
            left: 0,
          })}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              justifyContent: "center",
              width: "100%",
              p: isMobile ? "16px 16px 24px 16px" : "32px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography variant={isMobile ? "body_small" : "body_large_bold"}>
                {t("funnel.tariffs.modal_contract.select_contract")}
              </Typography>
              {!isPromotionActive && (
                <Box>
                  <Button
                    size={isMobile ? "small" : "normal"}
                    onClick={onWantsToBeSponsoredClick}
                    variant="transparent"
                    sx={{ textTransform: "none" }}
                  >
                    {t("funnel.tariffs.modal_contract.sponsored")}
                  </Button>
                </Box>
              )}
            </Box>
            <Tiles>
              <Button
                size={isMobile ? "small" : "normal"}
                variant="secondary"
                endIcon={<ArrowForwardIcon />}
                sx={{ textTransform: "none" }}
                onClick={onAlreadyUserClick}
              >
                {t("funnel.tariffs.modal_contract.already_user")}
              </Button>
              <Button
                size={isMobile ? "small" : "normal"}
                endIcon={<ArrowForwardIcon />}
                sx={{ textTransform: "none" }}
                onClick={onWantsToBeMemberClick}
              >
                {t("funnel.tariffs.modal_contract.new_user")}
              </Button>
            </Tiles>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

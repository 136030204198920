import { Box } from "@mui/material";
import { noop } from "lib/fn/noop";
import { FormStepGroup } from "screens/SignUp/shared/FormStepGroup";

export const FormSection = ({
  index,
  title,
  children,
  getSummary,
  stepIndex,
  topRightAction = noop,
}) => {
  const isCurrent = stepIndex === index;
  return (
    <FormStepGroup
      index={index}
      title={title}
      confirmMode={!isCurrent}
      topRightAction={topRightAction(index)}
    >
      <Box px={[3, 8]} py={5}>
        {isCurrent ? children : getSummary()}
      </Box>
    </FormStepGroup>
  );
};

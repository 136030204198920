import axios from "axios";

export const getProductsList = ({
  subscriptionId,
  productType,
  isSharedBonds,
}) =>
  axios.get(`/products/`, {
    params: {
      subscription_id: subscriptionId,
      product_type: productType,
      is_shared_bonds: isSharedBonds,
    },
  });

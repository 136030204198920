import { Box, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export const CheckItem = ({ literal }) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "flex-start",
      gap: "16px",
    }}
  >
    <CheckCircleIcon color="primary" />
    <Typography variant="body_bold">{literal}</Typography>
  </Box>
);

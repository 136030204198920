import { Box, Typography } from "@mui/material";
import { formatBandwith } from "lib/helpers/format";

export const BandwidthContent = ({ bandwidth, ...props }) => (
  <Box
    sx={{
      textAlign: "center",
      pb: "16px",
      pt: "8px",
      px: "8px",
      cursor: "pointer",
      width: "100%",
    }}
    {...props}
  >
    <Typography variant="body_bold">{formatBandwith(bandwidth)}</Typography>
  </Box>
);

import { Box } from "@mui/material";
import { Modal } from "ancient/components/Modal";
import { formatIBAN } from "lib/helpers/format";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { EmbeddedRequestChange } from "screens/RequestChange";
import { EditableField } from "../shared/Fields";
import { Link } from "components/Link";

export const SubscriptionEditTab = ({ ...subscription }) => {
  const { iban, email, complete_name, available_operations } = {
    ...subscription,
  };
  const { t } = useTranslation();
  const canChangeOwner = available_operations.includes("ChangeContractHolder");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTopic, setModalTopic] = useState(null);
  function openModal(topic) {
    setModalTopic(topic);
    setIsModalOpen(true);
  }
  return (
    <>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        disableBackdropClick={true}
      >
        <EmbeddedRequestChange subscription={subscription} topic={modalTopic} />
      </Modal>
      <Box mt={3}>
        <EditableField
          label={t("subscriptions.detail.iban")}
          value={formatIBAN(iban)}
          onClick={() => openModal("iban")}
        />
        <EditableField
          label={t("subscriptions.detail.email")}
          value={email}
          onClick={() => openModal("email")}
        />
        {canChangeOwner ? (
          <EditableField
            label={t("subscriptions.detail.name")}
            value={complete_name}
            onClick={() => openModal("change_owner")}
          />
        ) : (
          <div>
            <div>{t("subscriptions.detail.name")}:</div>
            <div>
              <div>
                {
                  <Trans i18nKey="subscriptions.detail.change_owner_unavailable">
                    <Link target="_self" to={"/request-change"} />
                  </Trans>
                }
              </div>
            </div>
          </div>
        )}
      </Box>
    </>
  );
};

// vendor
import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// source
import { useCategories, useFaqsPage } from "hooks/queries/useFaqs";
import useStyles from "./useStyles";
import { DialogHelp } from "components/dialogs/DialogHelp";
import Sidebar from "./Sidebar";
import { Spinner } from "components/Spinner";
import useScroll from "./useScroll";
import PageContent from "./PageContent";

import "../../faqs.css";

export default function Faqs() {
  const { t } = useTranslation();
  const styles = useStyles();
  const location = useLocation();
  const history = useHistory();

  const main = useRef();
  const list = useRef();

  const catId = useMemo(
    () => location.pathname.match(/(?<=faqs\/)\d+/)?.[0],
    [location.pathname]
  );
  const setCatId = (catId) => history.push(`/faqs/${catId}`);

  const pageId = useMemo(
    () => location.pathname.match(/(?<=\d+\/)\d+$/)?.[0],
    [location.pathname]
  );
  const setPageId = (pageId) => {
    if (pageId) history.push(`/faqs/${catId}/${pageId}`);
    else history.push(`/faqs/${catId}`);
  };

  const {
    isLoading: isLoadingCats,
    error: catLoadError,
    data: categories = [],
  } = useCategories({ parentId: 31 });

  const pages = useMemo(
    () =>
      categories.reduce(
        (pages, cat) =>
          pages.concat(
            cat.pages.map((page) => ({ ...page, category_id: cat.id }))
          ),
        []
      ),
    [categories]
  );

  const catPages = useMemo(
    () => pages.filter((page) => page.category_id == catId) || [],
    [catId, pages]
  );

  const [searchId, setSearchId] = useState(null);
  const [searchPages, setSearchPages] = useState();
  const onSearch = Array.isArray(searchPages);

  const currentPages = onSearch ? searchPages : catPages;
  const currentPageId = onSearch ? searchId : pageId;

  const {
    isLoading: isLoadingPage,
    error: pageLoadError,
    data: { content: pageContent } = {},
  } = useFaqsPage({ pageId: currentPageId, enabled: !!currentPageId });

  useEffect(() => {
    if (isLoadingCats) return;
    if (catId || pageId) return;
    // Set default category on empty url states
    history.push("/faqs/" + categories[0].id);
  }, [isLoadingCats]);

  useEffect(() => {
    if (!catPages.length || onSearch) return;
    // set first page as default on category changes
    const pid = !!catPages.find(({ id }) => id == currentPageId);
    if (!pid) setPageId(catPages[0].id);
  }, [catPages, onSearch]);

  useScroll({
    main: main.current,
    list: list.current,
    catId,
    pageId,
    isLoading: isLoadingCats,
  });

  const selectPage = (pageId) => {
    if (pageId == currentPageId) {
      pageId = null;
    }
    if (onSearch) setSearchId(pageId);
    else setPageId(pageId);
  };

  const title = onSearch
    ? t("faqs.search.title", { pattern: "dades" })
    : categories.find(({ id }) => id == catId)?.name;

  if (catLoadError || pageLoadError)
    return <p style={{ color: "red" }}>Error</p>;
  if (isLoadingCats) return <Spinner />;

  return (
    <div className={styles.screen}>
      <Sidebar
        catId={catId}
        setCatId={setCatId}
        categories={categories}
        onSearch={onSearch}
        searchPages={searchPages}
        setSearchPages={setSearchPages}
      />
      <main className={styles.content} ref={main}>
        {(onSearch && !currentPages.length && (
          <h3 className={styles.pageTitle}>{t("faqs.page.not-found")}</h3>
        )) || (
          <>
            <Typography variant="h3">{title}</Typography>
            <Box mt={4}>
              <ul ref={list} className={styles.pages + " faqs-content"}>
                {currentPages.map((page) => (
                  <li
                    id={"page_" + page.id}
                    key={page.id}
                    className={[styles.page, "page"].join(" ")}
                    data-cat={page.category_id}
                  >
                    <h3
                      onClick={() => selectPage(page.id)}
                      className={[
                        styles.pageTitle,
                        page.id == currentPageId ? "current" : "",
                      ].join(" ")}
                    >
                      {page.name}
                    </h3>
                    <PageContent
                      isActive={page.id == currentPageId}
                      isLoading={isLoadingPage}
                      content={pageContent}
                      id={page.id}
                    />
                  </li>
                ))}
              </ul>
            </Box>
          </>
        )}
        <Box
          sx={(theme) => ({
            display: "flex",
            justifyContent: "center",
            [theme.breakpoints.up("md")]: {
              display: "none",
            },
          })}
        >
          <DialogHelp origin="faqs" />
        </Box>
      </main>
    </div>
  );
}

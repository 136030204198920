import React, { useState, useEffect } from "react";
import { checkAuth, logout } from "lib/api/auth";
import { Tiles } from "components/layouts/Tiles";
import { useTranslation } from "react-i18next";
import Alert from "@mui/material/Alert";
import { useHistory } from "react-router-dom";
import { useAuthContext } from "lib/AuthContext";
import { Box, Typography } from "@mui/material";
import { Modal } from "components/layouts/Modal";
import { Button } from "components/Button";

export const SessionListener = ({ isLoggedIn }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [isCheckingAuth, setIsCheckingAuth] = useState(false);
  const [hasLoggedInFromAnotherTab, setHasLoggedInFromAnotherTab] =
    useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const keycloak = useAuthContext();

  const onClickClose = () => {
    setHasLoggedInFromAnotherTab(false);
    setIsModalOpen(false);
    history.push("/web-somconnexio");
  };

  const onClickContinue = async () => {
    await logout(() => keycloak.clearToken());

    setHasLoggedInFromAnotherTab(false);
    setIsModalOpen(false);
  };

  useEffect(() => {
    const handleFocus = async () => {
      if (isLoggedIn) return;

      setIsModalOpen(true);
      setIsCheckingAuth(true);
      if (await checkAuth()) {
        setHasLoggedInFromAnotherTab(true);
      } else {
        setIsModalOpen(false);
      }
      setIsCheckingAuth(false);
    };
    window.addEventListener("focus", handleFocus);
    return () => {
      window.removeEventListener("focus", handleFocus);
    };
  }, [isLoggedIn]);

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        showCloseButton={false}
        showBackdrop={isCheckingAuth}
      >
        {hasLoggedInFromAnotherTab && (
          <Box sx={{ p: 4 }}>
            <Tiles columns={1}>
              <Alert severity="info">
                <Typography>{t("common.session_up")}</Typography>
              </Alert>
              <Button onClick={() => onClickContinue()}>
                {t("common.continue_hire")}
              </Button>
              <Button variant="outlined" onClick={() => onClickClose()}>
                {t("common.continue_ov")}
              </Button>
            </Tiles>
          </Box>
        )}
      </Modal>
    </>
  );
};

// vendor
import { useEffect } from "react";

export default function useScroll({ main, list, catId, pageId, isLoading }) {
  useEffect(() => {
    if (isLoading || !pageId) return;
    // Scroll to current page after loading
    const item = list?.querySelector(`#page_${pageId}`);
    if (!item) return;

    main?.scrollTo({
      top: item.offsetTop,
      left: 0,
      behavior: "instant",
    });
  }, [isLoading]);

  useEffect(
    () => window.scrollTo({ top: 0, left: 0, behavior: "smooth" }),
    [catId]
  );

  useEffect(() => {
    // scroll into page on pageId changes
    if (!(pageId && list && main)) return;
    const item = list?.querySelector(`#page_${pageId}`);
    if (!item) return;

    window.scrollTo({
      top: item.offsetTop - main.parentElement.offsetTop,
      left: 0,
      behavior: "smooth",
    });
  }, [pageId]);
}

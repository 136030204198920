import React, { useState, useEffect, Suspense } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import ReactDOM from "react-dom";
import {
  HashRouter,
  MemoryRouter,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { QueryParamProvider } from "use-query-params";

import { useAnalytics } from "./hooks/useAnalytics";
import { Spinner } from "./components/Spinner";
import { initialize } from "initializers";
import { Tariffs } from "screens/Tariffs";
import { SignUp } from "screens/SignUp";
import Faqs from "screens/Faqs";
import { Join } from "screens/Join";

import { theme } from "./theme";
import "./App.css";
import { useHistory } from "react-router-dom";
import { IdleTracker } from "components/IdleTracker";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const paths = [
  {
    path: "/join",
    Component: () => <Join isTopBar={false} />,
  },
  {
    path: "/tariffs/:type",
    Component: Tariffs,
  },
  {
    path: "/product-picker",
    Component: () => <Join isTopBar={false} />,
  },
  {
    path: "/signup",
    Component: SignUp,
  },
  {
    path: "/faqs",
    Component: Faqs,
  },
  {
    path: "/faqs/:category?/:page?",
    Component: Faqs,
  },
];

const Routes = () => {
  return (
    <Switch>
      {paths.map(({ path, Component }) => (
        <Route key={path} path={path}>
          <Component />
        </Route>
      ))}
      <Route path="*" render={() => <Redirect to="/join" />} />
    </Switch>
  );
};

const SplashScreen = () => (
  <div
    style={{
      height: "100%",
      width: "100%",
      flex: 1,
      display: "flex",
      alignItems: "center",
    }}
  >
    <Spinner />
  </div>
);

function shouldRedirect(path = "") {
  const { hash } = window.location;
  return !(hash && hash.startsWith("#" + path)) || !path ? path : null;
}

function Forms({ useHash, path }) {
  const history = useHistory();
  const [isInitializing, setIsInitializing] = useState(true);

  // const trackingOff = useAnalytics();

  useEffect(() => {
    (async () => {
      const redirection = useHash ? shouldRedirect(path) : path;
      if (redirection) history.push(redirection);
      await initialize();
      setIsInitializing(false);
    })();
  }, []);

  return (
    <Suspense fallback={() => <SplashScreen />}>
      <QueryParamProvider ReactRouterRoute={Route}>
        <QueryClientProvider client={queryClient}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <IdleTracker />
              {(isInitializing && <SplashScreen />) || <Routes />}
            </ThemeProvider>
          </StyledEngineProvider>
        </QueryClientProvider>
      </QueryParamProvider>
    </Suspense>
  );
}

export default function () {
  const Router = ({ children, useHash }) => {
    const Router = useHash ? HashRouter : MemoryRouter;
    return <Router>{children}</Router>;
  };

  return ({ el, attributes }) => {
    const { route, component } = attributes;
    const useHash = route !== undefined;
    const path = "/" + (route || component || "").replace(/^#?\//, "");

    ReactDOM.render(
      <Router useHash={useHash}>
        <Forms useHash={useHash} path={path} />
      </Router>,
      el
    );
  };
}

import { useApplicationContext } from "hooks/useApplicationContext";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { TopBarLayout } from "./layouts/TopBarLayout";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { BrandLogo } from "./icons/BrandLogo";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { useState } from "react";
import { useAuthContext } from "lib/AuthContext";
import { logout } from "lib/api/auth";
import { ProfileIcon } from "./icons/ProfileIcon";

const LocaleSelector = ({ initialLocale }) => {
  const { i18n } = useTranslation();
  const [locale, setLocale] = useState(initialLocale);

  const handleChange = ({ target }) => {
    const lang = target.value;
    setLocale(lang);
    i18n.changeLanguage(lang);
  };

  return (
    <Select variant="standard" value={locale} onChange={handleChange}>
      {[
        { value: "ca", lit: "Català" },
        { value: "es", lit: "Castellano" },
      ].map(({ value, lit }) => (
        <MenuItem value={value}>{lit}</MenuItem>
      ))}
    </Select>
  );
};

function ProfileTopBar() {
  const { t } = useTranslation();
  const history = useHistory();
  const { currentUser } = useApplicationContext();
  const [profileAnchorEl, setProfileAnchorEl] = useState(null);
  const keycloak = useAuthContext();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  async function handleLogoutClick() {
    await logout(() =>
      keycloak.logout({
        redirectUri: `${window.location.origin}/home`,
      })
    );
  }

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      {!isMobile && (
        <Box ml={2}>
          <Typography variant="body_bold">{currentUser.full_name}</Typography>
        </Box>
      )}
      <IconButton
        size={isMobile ? "small" : "medium"}
        onClick={(e) => setProfileAnchorEl(e.currentTarget)}
      >
        <ProfileIcon />
      </IconButton>
      <Menu
        anchorEl={profileAnchorEl}
        open={Boolean(profileAnchorEl)}
        onClose={() => setProfileAnchorEl(null)}
        sx={{
          "& .MuiPaper-root": {
            boxShadow:
              "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",
          },
        }}
      >
        <MenuItem
          onClick={() => {
            setProfileAnchorEl(null);
            history.push("/home");
          }}
        >
          <Typography variant="body_small">{t("summary.title")}</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setProfileAnchorEl(null);
            handleLogoutClick();
          }}
        >
          <Typography variant="body_small">{t("common.logout")}</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
}

export const TopBar = ({ locale = undefined, children }) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { currentUser } = useApplicationContext();
  const isAuthenticated = Boolean(currentUser);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  if (process.env.REACT_APP_FORM_BUILD) return children;

  return (
    <>
      <TopBarLayout>
        <Box
          width="100%"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <IconButton
            onClick={() => history.push("/web-somconnexio")}
            sx={{ "&:hover": { backgroundColor: "transparent" } }}
          >
            <BrandLogo
              size={isMobile ? "medium" : "large"}
              locale={i18n.language}
            />
          </IconButton>
          <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <LocalPhoneIcon />
            <Typography variant="body_small_bold">
              {isMobile
                ? t("funnel.signup.header.call_me_mobile")
                : t("funnel.signup.header.call_me")}
            </Typography>
          </Box>
          {isAuthenticated && <ProfileTopBar />}
          {locale && <LocaleSelector initialLocale={locale} />}
        </Box>
      </TopBarLayout>
      <Box sx={{ paddingTop: "70px", display: "flex" }}>{children}</Box>
    </>
  );
};

import { useApplicationContext } from "hooks/useApplicationContext";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "components/Link";
import { LowerDialog } from "components/icons/Dialogs";
import { HeaderIcon } from "components/icons/JoinIcon";

const Icon = ({ isMobile }) => {
  function GetDialogLit() {
    const { t } = useTranslation();
    // TODO parameterize prefix with role ?
    const prefix = "anonymous";
    return t(`funnel.join.${prefix}.dialog_lit`);
  }
  return (
    <Box
      sx={{
        position: "relative",
        bottom: "95px",
        left: isMobile ? "0px" : "75px",
        height: isMobile ? "300px" : "425px",
      }}
    >
      <HeaderIcon
        width={isMobile ? "264" : "462"}
        height={isMobile ? "241" : "422"}
      />
      <Box
        sx={{
          position: "relative",
          left: isMobile ? "42px" : "75px",
          bottom: isMobile ? "58px" : "95px",
        }}
      >
        <LowerDialog
          width={isMobile ? "159" : "278"}
          height={isMobile ? "120" : "203"}
        />
        <Typography
          sx={{
            textAlign: "center",
            fontFamily: "Rock Salt",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "22px",
            letterSpacing: "-0.4px",
            position: "relative",
            bottom: isMobile ? "75px" : "110px",
            left: isMobile ? "8px" : "6px",
            maxWidth: isMobile ? "140px" : "260px",
          }}
        >
          {GetDialogLit()}
        </Typography>
      </Box>
    </Box>
  );
};
export const Heading = ({
  optingForRole,
  coopAgreementCode,
  coopAgreement,
  isPromotionActive,
}) => {
  const { currentUser } = useApplicationContext();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  function PromotionHeading({ optingForRole, coopAgreement }) {
    const { t } = useTranslation();
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
    return (
      <>
        <Typography variant={isMobile ? "h3" : "h1"} gutterBottom>
          {t(`funnel.join.${optingForRole}.promotion.title`, {
            coopAgreement,
          })}
        </Typography>
        <Box maxWidth={600}>
          <Typography variant="h4" gutterBottom>
            {t(`funnel.join.${optingForRole}.promotion.subtitle`)}
          </Typography>
          <Typography variant="body" paragraph>
            <Trans>
              {t(`funnel.join.${optingForRole}.promotion.text_1`, {
                coopAgreement,
              })}
            </Trans>
          </Typography>
          <Typography variant="body" paragraph>
            <Trans>{t(`funnel.join.${optingForRole}.promotion.text_2`)}</Trans>
          </Typography>
          <Typography variant="body_small" paragraph>
            <Trans i18nKey={"funnel.join.coop_agreement.promotion.text_3"}>
              <Link
                target="_blank"
                to={t("funnel.join.coop_agreement.promotion.terms_url")}
              />
            </Trans>
          </Typography>
        </Box>
        {/* TODO new image  ¿¿??*/}
        {/* <Box display="flex" justifyContent="center">
            <img
              height={"350px"}
              src={`/intercooperationAgreements_${i18n.language}.png`}
              alt={"The best welcome to the best service"}
            />
          </Box> */}
      </>
    );
  }
  function PublicHeading({ optingForRole, coopAgreementCode, coopAgreement }) {
    const { t } = useTranslation();
    const isOptingForRole = Boolean(optingForRole);

    function resolveLiterals() {
      if (isOptingForRole) {
        return optingForRole === "coop_agreement" && coopAgreementCode === "SC"
          ? "self_agreement"
          : optingForRole;
      }
      return "anonymous";
    }

    const roleToLiterals = resolveLiterals();

    return (
      <>
        {!isOptingForRole && (
          <Typography variant="body_bold" sx={{ mb: 6 }}>
            {t("funnel.join.anonymous.associate")}
          </Typography>
        )}
        <Typography variant={isMobile ? "h3" : "h1"} gutterBottom>
          {t(`funnel.join.${roleToLiterals}.title`, {
            coopAgreement,
          })}
        </Typography>
        <Typography variant="h4" gutterBottom>
          {t(`funnel.join.${roleToLiterals}.subtitle`)}
        </Typography>
        <Typography variant="body" paragraph>
          {t(`funnel.join.${roleToLiterals}.text_1`, {
            coopAgreement,
          })}
        </Typography>
        <Typography variant="body_bold" paragraph>
          {t(`funnel.join.${roleToLiterals}.text_2`)}
        </Typography>
      </>
    );
  }
  function AuthenticatedHeading() {
    const { t } = useTranslation();
    return (
      <>
        <Typography variant={isMobile ? "h3" : "h1"}>
          {t("funnel.join.authenticated.title")}
        </Typography>
        <Box maxWidth={600}>
          <Typography variant="h4" gutterBottom>
            {t("funnel.join.authenticated.subtitle")}
          </Typography>
        </Box>
      </>
    );
  }

  return (
    <Box
      sx={(theme) => ({
        mb: 9,
        display: "flex",
        alignItems: isMobile ? "center" : "start",
        flexDirection: isMobile ? "column-reverse" : "row",
        p: `60px ${isMobile ? "24px" : "60px"}`,
        borderRadius: 3,
        background: theme.palette.secondary.main,
        justifyContent: "space-between",
      })}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          maxWidth: isMobile ? "100%" : "700px",
          mt: isMobile ? "-60px" : "0px",
        }}
      >
        {currentUser ? (
          <AuthenticatedHeading />
        ) : isPromotionActive ? (
          <PromotionHeading
            optingForRole={optingForRole}
            coopAgreement={coopAgreement}
          />
        ) : (
          <PublicHeading
            optingForRole={optingForRole}
            coopAgreementCode={coopAgreementCode}
            coopAgreement={coopAgreement}
          />
        )}
      </Box>
      <Icon isMobile={isMobile} />
    </Box>
  );
};

import React, { Children } from "react";
import { Box, styled } from "@mui/material";

const TileContainer = styled("div")(({ theme, spacing, columns }) => ({
  display: "grid",
  gap: theme.spacing(spacing),
  gridTemplateColumns: `repeat(${columns}, minmax(0, 1fr))`,
}));

export const SelectRow = ({ children, title, spacing = 1, maxRows = 5 }) => {
  const columns =
    Children.count(children) <= maxRows ? Children.count(children) : maxRows;
  return (
    <>
      <Box sx={{ mb: 2, width: "100%" }}>{title}</Box>
      <TileContainer columns={columns} spacing={spacing}>
        {children}
      </TileContainer>
    </>
  );
};

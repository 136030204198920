import { useLinesDerivedState } from "hooks/useLinesDerivedState";
import { InternetTariffs } from "./InternetTariffs";
import { MobileTariffs } from "./MobileTariffs";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { BandwidthAction } from "../components/internet/BandwidthAction";
import { FloatingCard } from "components/layouts/FloatingCard";

export const PersonalizedTariffs = ({ tariffs }) => {
  const { t } = useTranslation();
  const { hasInternetLineLoaded } = useLinesDerivedState();
  return (
    <>
      <InternetTariffs
        tariffs={tariffs}
        isInfoArrowed={false}
        bandwidthAction={(category, bandwidth, onClick) => (
          <BandwidthAction
            price={parseFloat(
              tariffs.find(
                (tariff) =>
                  tariff.category === category && tariff.bandwidth === bandwidth
              ).price
            ).toFixed(2)}
            onClick={onClick}
          />
        )}
      />
      {hasInternetLineLoaded && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mt: "32px",
          }}
        >
          <FloatingCard sx={{ mb: 5 }}>
            <Typography variant="body_bold">
              {t("funnel.tariffs.personalized.add_mobiles")}
            </Typography>
          </FloatingCard>
          <MobileTariffs tariffs={tariffs} />
        </Box>
      )}
    </>
  );
};

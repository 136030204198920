export const LowerDialog = ({
  width = "189",
  height = "152",
  minX = 0,
  minY = 0,
}) => (
  <svg
    width={width}
    height={height}
    viewBox={`${minX} ${minY} 189 152`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M185.727 86.04C182.72 44.3525 94.0284 55.7218 82.754 55.7218C75.7388 36.015 93.0736 18.4017 82.754 24.6457C75.2378 29.1934 65.7172 47.1317 66.2183 59.5116C55.1944 61.2802 27.2841 73.1548 27.8854 103.473C28.6371 141.371 42.9179 151.982 108.309 149.708C173.701 147.434 188.733 127.728 185.727 86.04Z"
      fill="black"
      stroke="black"
      strokeWidth="4"
    />
    <path
      d="M176.015 70.5276C172.71 25.0067 75.2171 37.4215 62.824 37.4215C55.1126 15.9025 74.1676 -3.33031 62.824 3.48776C54.5618 8.45367 44.0965 28.0414 44.6473 41.5598C32.5295 43.491 1.84944 56.4575 2.51041 89.5636C3.33662 130.946 19.0347 142.533 90.9152 140.05C162.796 137.567 179.32 116.048 176.015 70.5276Z"
      fill="white"
      stroke="black"
      strokeWidth="4"
    />
  </svg>
);

export const UpperDialog = ({
  width = "199",
  height = "148",
  minX = 0,
  minY = 0,
}) => (
  <svg
    width={width}
    height={height}
    viewBox={`${minX} ${minY} 199 148`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M48.224 84.6732C51.0496 124.877 134.402 113.913 144.998 113.913C151.591 132.918 135.3 149.905 144.998 143.883C152.062 139.497 161.009 122.197 160.538 110.258C170.899 108.552 197.129 97.0999 196.564 67.8606C195.857 31.3114 182.436 21.0777 120.981 23.2706C59.5261 25.4636 45.3985 44.4691 48.224 84.6732Z"
      fill="black"
      stroke="black"
      strokeWidth="4"
    />
    <path
      d="M3.31914 74.0981C6.82668 121.09 110.299 108.274 123.452 108.274C131.637 130.488 111.413 150.343 123.452 143.304C132.221 138.178 143.328 117.957 142.744 104.002C155.605 102.008 188.166 88.6229 187.465 54.447C186.588 11.7272 169.927 -0.234343 93.6383 2.32885C17.3493 4.89203 -0.188395 27.1063 3.31914 74.0981Z"
      fill="white"
      stroke="black"
      strokeWidth="4"
    />
  </svg>
);

import { Tooltip as MaterialTooltip } from '@mui/material';
import { withStyles } from "@mui/styles";


const DefaultTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.primary.main,
  },
  arrow: {
    color: theme.palette.primary.main,
  }
}))(MaterialTooltip);

const InfoTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.info.main,
    padding: theme.spacing(3),
  },
  arrow: {
    color: theme.palette.info.main,
  }
}))(MaterialTooltip);


export const Tooltip = ({variant = "default", ...props}) => {
  let TooltipComponent;

  if (variant === "default") {
    TooltipComponent = DefaultTooltip;
  } else if (variant === "info") {
    TooltipComponent = InfoTooltip;
  }

  return <TooltipComponent {...props} />;
};

import { Box, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { useSharedDataContext } from "../context";
import { Tiles } from "components/layouts/Tiles";
import { SharedPricePreview } from "ancient/components/shared/SharedPricePreview";
import { Text } from "ancient/components/Text";
import { Button } from "ancient/components/Button";
import { useHistory } from "react-router-dom";
import { useApplicationContext } from "hooks/useApplicationContext";
import { createTicket, getTickets } from "lib/api/tickets";
import { useState } from "react";
import { Alert } from "@mui/lab";
import { Spinner } from "components/Spinner";
import { getStartOfNextMonth } from "lib/helpers/dates";
import { Modal } from "ancient/components/Modal";
import { noop } from "lib/fn/noop";
const ConfirmationAlert = ({ onDecline, onAccept, tickets }) => {
  const { t } = useTranslation();
  const { allCatalog } = useSharedDataContext();
  return (
    <Alert severity="warning">
      <Tiles columns={1}>
        <Text>
          <Trans
            i18nKey="share_data.ends.confirmation.title"
            count={tickets.length}
          />
        </Text>
        {tickets.map(({ meta }) => (
          <Text>
            <Trans i18nKey={"share_data.ends.confirmation.line"}>
              {{
                mob: meta.find((m) => m.key === "phone_number").value,
                prod: allCatalog.find(
                  ({ code }) =>
                    code ===
                    meta.find((m) => m.key === "new_product_code").value
                ).name,
              }}
            </Trans>
          </Text>
        ))}
        <Text>
          <Trans
            i18nKey="share_data.ends.confirmation.advice"
            count={tickets.length}
          />
        </Text>

        <Button onClick={onAccept} primary>
          {t("common.yes")}
        </Button>
        <Button onClick={onDecline}>{t("common.no")}</Button>
      </Tiles>
    </Alert>
  );
};

export const Ends = () => {
  const { currentUser } = useApplicationContext();
  const { t } = useTranslation();
  const history = useHistory();
  const {
    setShouldShowStepper,
    selectedMobileContracts,
    selectedFiberContract,
    selectedPack,
  } = useSharedDataContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [overrideTickets, setOverrideTickets] = useState([]);
  const [existingMobileTickets, setExistingMobileTickets] = useState({});
  const mobilesPack = selectedPack.products.filter(
    (product) => product.category === "mobile"
  );

  setShouldShowStepper(true);

  const checkTicket = async () => {
    let tickets = [];
    await Promise.all([
      // N calls for N mobiles
      ...selectedMobileContracts.map(({ phone_number }) =>
        getTickets({
          ticket_type: "change_tariff_shared_bonds",
          liniaMobil: phone_number,
        })
      ),
    ]).then((values) => {
      values.forEach(({ data }) => {
        if (data.length > 0) {
          tickets = [...tickets, ...data];
        }
      });
    });
    setExistingMobileTickets(tickets);
    setOverrideTickets(tickets.map((t) => t.id));
    return tickets.length > 0;
  };
  const sendTicket = async (override_ticket_ids) => {
    await createTicket({
      body: "-",
      subject: "-",
      override_ticket_ids,
      meta: [
        {
          key: "ticket_type",
          value: "change_tariff_shared_bonds",
        },
        {
          key: "language",
          value: currentUser.preferred_locale + "_ES",
        },
        {
          key: "contracts",
          value: selectedMobileContracts.map(
            ({ phone_number, active_product_code, email }) => ({
              phone_number: phone_number,
              current_product_code: active_product_code,
              subscription_email: email,
            })
          ),
        },
        {
          key: "fiber_linked",
          value: selectedFiberContract[0].code,
        },

        {
          key: "new_product_code",
          value: mobilesPack[0].code,
        },

        {
          key: "effective_date",
          value: getStartOfNextMonth("otrs"),
        },
      ],
    });
  };
  const onClickFinish = async (needConfirmation = true) => {
    try {
      setIsLoading(true);
      if (needConfirmation) {
        if (await checkTicket()) {
          setIsConfirm(true);
          setIsLoading(false);
          return;
        }
      }
      await sendTicket(overrideTickets);
      history.push("success");
      return;
    } catch (e) {
      history.push("error");
    }
  };

  const linesPricePreview = () => {
    return [
      {
        price_difference: true,
        price: selectedMobileContracts
          .reduce((total, { price }) => total + parseFloat(price), 0)
          .toFixed(2),
        new_price: mobilesPack
          .reduce((total, { price }) => total + parseFloat(price), 0)
          .toFixed(2),
      },
      {
        ...selectedFiberContract[0],
        new_price: selectedFiberContract[0].price,
        price_difference: false,
      },
    ];
  };
  if (isLoading)
    return (
      <Box mt={5}>
        <Spinner />
      </Box>
    );

  return (
    <Tiles columns="1" spacing={4}>
      <Box mt={5}>
        <Typography variant="h6">
          {t("share_data.ends.how_many_mobiles")}
        </Typography>
        <Tiles columns="1" spacing={0}>
          {selectedMobileContracts.map((selectedMobile) => (
            <b>{selectedMobile.phone_number}</b>
          ))}
        </Tiles>
      </Box>
      <div>
        <Typography variant="h6">
          {t("share_data.ends.fiber_linked")}
        </Typography>
        {selectedFiberContract.map((selectedFiber) => (
          <>
            <b>{selectedFiber.description} </b>
            {selectedFiber.address.street}
          </>
        ))}
      </div>
      <SharedPricePreview
        lines={linesPricePreview()}
        dynamic={false}
        totalDifference={false}
        getPrimaryText={(line) =>
          line.subscription_technology === "fiber" ? (
            <Text>{line.description}</Text>
          ) : (
            <Text>
              {`${selectedMobileContracts.length} ${t(
                "share_data.ends.mobile_descripiton"
              )}`}
            </Text>
          )
        }
        getTotalLit={() => (
          <Text size="lg" bold>
            {t("share_data.ends.total_price")}
          </Text>
        )}
      />
      <Tiles columns="3" spacing={4}>
        <div />
        <Button
          onClick={() => {
            history.push("mobiles");
          }}
        >
          {t("common.cancel")}
        </Button>
        <Button onClick={onClickFinish}>{t("common.finish")}</Button>
      </Tiles>
      <Modal
        isOpen={isConfirm}
        disableBackdropClick={true}
        onClose={noop}
        showCloseButton={false}
      >
        <ConfirmationAlert
          onDecline={() => {
            history.push("/share-data");
          }}
          onAccept={() => {
            setIsConfirm(false);
            onClickFinish(false);
          }}
          tickets={existingMobileTickets}
        />
      </Modal>
    </Tiles>
  );
};

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Switch, Route, useParams, useRouteMatch } from "react-router-dom";
import Alert from "@mui/material/Alert";

import Box from "@mui/material/Box";

import { FullScreenCenteredLayout } from "components/layouts/FullScreenCenteredLayout";
import {
  requestPasswordReset,
  validatePasswordResetToken,
  resetPassword,
} from "lib/api/auth";

import { Spinner } from "components/Spinner";
import { LegacyTextField } from "ancient/components/LegacyTextField";
import { Button } from "ancient/components/Button";
import { Form } from "ancient/components/LegacyForm";
import { Link } from "components/Link";
import { Stack } from "ancient/components/layouts/Stack";
import { confirmEmailChange } from "lib/api/profile";
import { useApplicationContext } from "hooks/useApplicationContext";

const Success = () => {
  const { t } = useTranslation();

  return (
    <Stack>
      <Alert severity="success">{t("confirm_email.complete")}</Alert>
      <Link to="/">{t("confirm_email.go_to_app")}</Link>
    </Stack>
  );
};

export function ConfirmEmail() {
  const { token } = useParams();
  const { t } = useTranslation();
  const { updateCurrentUser } = useApplicationContext();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    async function confirmToken() {
      try {
        setIsLoading(true);
        const { data } = await confirmEmailChange({ token });
        updateCurrentUser({
          email: data.new_email,
        });
        setIsLoading(false);
        setIsSuccess(true);
      } catch (e) {
        setError(t("confirm_email.invalid_or_expired_token"));
      } finally {
        setIsLoading(false);
      }
    }

    confirmToken();
  }, [token]);

  return (
    <FullScreenCenteredLayout>
      {isLoading && <Spinner />}
      {isSuccess && <Success />}
      {error && (
        <Box width="100%">
          <Alert severity="error">{error}</Alert>
        </Box>
      )}
    </FullScreenCenteredLayout>
  );
}

import React, { useState, useEffect, useMemo } from "react";
import { useStore } from "hooks/useStore";
import { Field, useForm, useFormState } from "react-final-form";
import { Tiles } from "components/layouts/Tiles";
import { uniqBy, compact, uniqueId } from "lodash";
import { useTranslation } from "react-i18next";
import { formatFullAddress } from "../shared/formatFullAddress";
import { required } from "lib/form/validators";
import { states } from "lib/domain/somconnexio/selections";
import { SelectField } from "components/fields/SelectField";
import { InputField } from "components/fields/InputField";

const StatePicker = ({ name }) => {
  const { t } = useTranslation();

  return (
    <SelectField.FormField
      validate={required}
      name={name}
      options={states}
      label={t("funnel.address_picker.state")}
    />
  );
};

export const AddressPicker = ({ name, label }) => {
  const formState = useFormState();
  const form = useForm();
  const availableAddresses = useStore((state) => state.availableAddresses);
  const { t } = useTranslation();
  const [isInsertingNew, setIsInsertingNew] = useState(false);
  const addressId = formState.values[name]?._id;

  const options = uniqBy(
    compact(availableAddresses).filter((address) => Boolean(address.street)),
    "_id"
  ).map((address) => ({
    label: formatFullAddress(address),
    value: address._id,
  }));

  useEffect(() => {
    if (isInsertingNew) {
      return;
    }

    const willInsertNew = addressId === "add_new";

    setIsInsertingNew(willInsertNew);

    if (willInsertNew) {
      form.mutators.clearField(name);
    }
  }, [addressId, isInsertingNew, form.mutators, name]);

  const optimisticId = useMemo(() => uniqueId("address_"), []);

  if (availableAddresses.length <= 0 || isInsertingNew) {
    return (
      <Tiles columns={1} spacing={4}>
        <Field
          name={`${name}._id`}
          component="input"
          type="hidden"
          initialValue={optimisticId}
        />
        <InputField.FormField
          validate={required}
          name={`${name}.street`}
          label={`${label} ${t("funnel.address_picker.street_hint")}`}
        />
        <InputField.FormField
          validate={required}
          name={`${name}.city`}
          label={t("funnel.address_picker.city")}
        />
        <InputField.FormField
          validate={required}
          name={`${name}.zip_code`}
          label={t("funnel.address_picker.zip_code")}
        />

        <StatePicker name={`${name}.state`} />
      </Tiles>
    );
  }

  return (
    <>
      <Field name={name} component="input" type="hidden" />
      <SelectField.FormField
        name={`${name}._id`}
        validate={required}
        label={label}
        options={[
          ...options,
          { label: t("funnel.address_picker.add_new"), value: "add_new" },
        ]}
      />
    </>
  );
};

import { useState } from "react";
import { SidebarLayout } from "ancient/components/layouts/SidebarLayout";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Tiles } from "components/layouts/Tiles";
import { getContractsList } from "lib/api/contracts";
import { useQuery } from "react-query";
import { useApplicationContext } from "hooks/useApplicationContext";
import { Spinner } from "components/Spinner";
import { SharedDataContext } from "./context";
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { Initial } from "./views/Initial";
import { Stepper } from "ancient/components/Stepper";
import { Mobiles } from "./views/Mobiles";
import { Fibers } from "./views/Fibers";
import { Ends } from "./views/Ends";
import { Success } from "./views/Succes";
import { LimitData } from "./views/LimitData/LimitData";
import { getFiberContracts } from "lib/api/fiberContracts";
import { Error } from "./views/Error";
import { useContracts, useFiberContracts } from "hooks/queries/useContracts";

const Content = ({ optionalContracts, sharingContracts }) => {
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  const [selectedMobileContracts, setSelectedMobileContracts] = useState([]);
  const [selectedFiberContract, setSelectedFiberContract] = useState([]);
  const [selectedPack, setSelectedPack] = useState([]);
  const [shouldShowStepper, setShouldShowStepper] = useState(false);
  const [packs, setPacks] = useState([]);
  const [allCatalog, setAllCatalog] = useState([]);
  const [isCombineInitialized, setIsCombineInitialized] = useState(false);
  const steps = [
    "/share-data/mobiles",
    "/share-data/fibers",
    "/share-data/end",
  ];

  const contextValue = {
    sharingContracts,
    optionalContracts,
    selectedMobileContracts,
    setSelectedMobileContracts,
    selectedFiberContract,
    selectedPack,
    setSelectedFiberContract,
    setShouldShowStepper,
    setSelectedPack,
    packs,
    setPacks,
    allCatalog,
    setAllCatalog,
    isCombineInitialized,
    setIsCombineInitialized,
  };

  const WizardRoute = ({ children, ...rest }) => {
    return (
      <Route
        {...rest}
        render={() =>
          !isCombineInitialized ? <Redirect to="/share-data" /> : children
        }
      />
    );
  };
  return (
    <SharedDataContext.Provider value={contextValue}>
      {shouldShowStepper && (
        <Stepper
          currentStep={pathname}
          steps={steps.map((step) => ({
            key: step,
            label: t("share_data.header.steps." + step.split("/")[2]),
          }))}
        />
      )}
      <Switch>
        <Route exact path={path}>
          <Initial />
        </Route>
        <Route exact path={`${path}/limit-data/:boundId`}>
          <LimitData sharingContracts={sharingContracts} />
        </Route>
        <WizardRoute path={`${path}/mobiles`}>
          <Mobiles />
        </WizardRoute>
        <WizardRoute path={`${path}/fibers`}>
          <Fibers />
        </WizardRoute>
        <WizardRoute path={`${path}/end`}>
          <Ends />
        </WizardRoute>
        <WizardRoute path={`${path}/success`}>
          <Success />
        </WizardRoute>
        <WizardRoute path={`${path}/error`}>
          <Error />
        </WizardRoute>
      </Switch>
    </SharedDataContext.Provider>
  );
};
export const ShareData = () => {
  const { currentUser } = useApplicationContext();
  const { t } = useTranslation();
  const { isLoading: isContractsLoading, data: contracts } = useContracts({
    userName: currentUser.username,
  });
  const { isLoading: isFibersLoading, data: fibers } = useFiberContracts({
    userName: currentUser.username,
    mobiles_sharing_data: true,
  });
  const getSharingContracts = () => {
    let sharingContracts = [];

    const sharedBondIds = [
      ...new Set(
        contracts.data
          .map(({ shared_bond_id }) => shared_bond_id)
          .filter((shared_bond_id) => shared_bond_id)
      ),
    ];
    if (sharedBondIds) {
      sharedBondIds.forEach((sharedBondId) => {
        const mobileContracts = contracts.data.filter(
          ({ shared_bond_id }) => shared_bond_id === sharedBondId
        );
        const fiberContract = contracts.data.find(
          ({ code }) => code === mobileContracts[0].associated_fiber
        );
        // prevent contracts with sharing data it provision is incomplete
        if (mobileContracts.length > 1) {
          sharingContracts = [
            ...sharingContracts,
            {
              boundId: sharedBondId,
              fiber: fiberContract,
              mobile: mobileContracts,
            },
          ];
        }
      });
    }
    return sharingContracts;
  };
  const getOptionalContracts = () => {
    let optionalContracts = [];
    const mobileContracts = contracts.data.filter(
      ({ shared_bond_id, subscription_technology }) =>
        !shared_bond_id && subscription_technology === "mobile"
    );

    optionalContracts = { fiber: fibers, mobile: mobileContracts };

    return optionalContracts;
  };
  var { pathname } = useLocation();
  return (
    <SidebarLayout>
      <Tiles columns={1}>
        <Typography variant="h4" component="h1">
          {pathname === "/share-data/limit-data"
            ? t("share_data.limit_data.title")
            : t("share_data.title")}
        </Typography>
        {isContractsLoading || isFibersLoading ? (
          <Box mt={5}>
            <Spinner />
          </Box>
        ) : (
          <Content
            optionalContracts={getOptionalContracts()}
            sharingContracts={getSharingContracts()}
          />
        )}
      </Tiles>
    </SidebarLayout>
  );
};

import {
  Box,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Card } from "ancient/components/Card";
import { Separator } from "components/Separator";
import { Text } from "ancient/components/Text";
import { useTranslation } from "react-i18next";
import { theme } from "theme";
import { PriceTag } from "./PricePreview";
import { noop } from "lib/fn/noop";

const useStyles = makeStyles(() => ({
  listItem: {
    paddingTop: "0",
    paddingBottom: "0",
  },
  listItemSecondaryMobile: {
    right: "14%",
  },
  listItemSecondaryDesktop: {
    right: "15%",
  },
}));
export const SharedPricePreview = ({
  lines = [],
  dynamic = true,
  totalDifference = true,
  getPrimaryText = noop,
  getSecondaryText = noop,
  getTotalLit = noop,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return lines.length === 0 ? (
    <></>
  ) : (
    <Card variant="noGutter" shadow={false} isHighlighted>
      {lines.map((line) => (
        <ListItem
          className={classes.listItem}
          key={line.code}
          ContainerComponent={"div"}
        >
          <ListItemText
            id={line.code}
            primary={getPrimaryText(line)}
            secondary={getSecondaryText(line)}
          />
          <ListItemSecondaryAction
            className={
              isMobile
                ? classes.listItemSecondaryMobile
                : classes.listItemSecondaryDesktop
            }
          >
            <Box display="flex">
              {line.price_difference ? (
                <Text size="md" lineThrough>
                  {line.price}
                </Text>
              ) : (
                <div />
              )}

              <Box ml={4}>
                <Text size="md" bold>
                  {`${line.new_price}€`}
                </Text>
              </Box>
            </Box>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
      <Separator variant={"corporate"} />
      {dynamic && lines.length === 1 ? (
        <Typography variant="caption">
          {t("share_data.mobiles.price.advice")}
        </Typography>
      ) : (
        <Box display="flex" justifyContent={"space-between"} mx={2} my={2}>
          {getTotalLit()}
          {totalDifference && (
            <Box mx={2}>
              <Text size="md" lineThrough>
                {lines
                  .reduce((total, { price }) => total + parseFloat(price), 0)
                  .toFixed(2)}
              </Text>
            </Box>
          )}

          <PriceTag
            price={lines.reduce(
              (total, { new_price }) => total + parseFloat(new_price),
              0
            )}
          />
        </Box>
      )}
    </Card>
  );
};

import axios from "axios";
import { preprocessUsernameForApi } from "lib/domain/somconnexio/auth";

export const sponsorIsValid = async (sponsor_code, sponsor_vat) => {
  let hasErrors = false;
  await axios
    .get("/user/check/", {
      params: {
        code: sponsor_code,
        vat: preprocessUsernameForApi(sponsor_vat),
      },
    })
    .then((res) => {
      if (!res.data["allowed"]) {
        hasErrors =
          res.data["message"] === "maximum number of sponsees exceeded"
            ? {
                sponsor_vat: "sponsor_vat_full_sponsoreds",
              }
            : {
                sponsor_code: "sponsor_vat_or_code_not_valid",
                sponsor_vat: "sponsor_vat_or_code_not_valid",
              };
      }
    });
  return hasErrors;
};

export const phoneIsAlreadyHired = async (phone_number) => {
  let isAlreadyHired = false;
  await axios
    .get("/user/check-phone/", {
      params: {
        phone: phone_number.replace(/ /g, ""),
      },
    })
    .then((result) => {
      if (result.status === 200) {
        isAlreadyHired = true;
      }
    });
  return isAlreadyHired;
};

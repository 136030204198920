export const PlusIcon = ({
  width = "35",
  height = "36",
  minX = 0,
  minY = 0,
}) => (
  <svg
    width={width}
    height={height}
    viewBox={`${minX} ${minY} ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.0205 1.5C18.7012 12.5013 18.1826 23.4926 18.1826 34.5"
      stroke="black"
      strokeWidth="3"
      strokeLinecap="round"
    />
    <path
      d="M2.26367 19.5C12.6577 18.1405 22.9008 16.7216 33.2637 15.5"
      stroke="black"
      strokeWidth="3"
      strokeLinecap="round"
    />
  </svg>
);

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { Link } from "components/Link";
import { DialogCallYou } from "components/dialogs/DialogCallYou";
import { CheckField } from "components/fields/CheckField";
import { InputField } from "components/fields/InputField";
import { SelectField } from "components/fields/SelectField";
import { Form } from "components/Form";
import { Image } from "components/layouts/Image";
import { Tiles } from "components/layouts/Tiles";
import { Modal } from "components/layouts/Modal";
import { postWeCallYou } from "lib/api/tickets";
import { composeValidators, required, validPhone } from "lib/form/validators";
import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

function getReason(origin) {
  switch (origin) {
    case "tariffs":
      return "pagina_tarifes";
    case "help":
      return "ajuda";
    case "change_request":
      return "canvis";
    case "contract_info":
      return "contractar_info";
    case "info_coop":
      return "info_coop";
    case "more_service":
      return "mes_serveis";
    default:
      return "altres";
  }
}

export const ModalWeCallYou = ({ origin, ...props }) => {
  const { t, i18n } = useTranslation();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const privacyUrl = t("urls.privacy");
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const ScheduleOptions = [
    { value: "1012", label: "10h-12h" },
    { value: "1214", label: "12h-14h" },
    { value: "1416", label: "14h-16h" },
    { value: "1618", label: "16h-18h" },
  ];
  const onFormSubmit = async (values) => {
    await postWeCallYou({
      ...values,
      reason: getReason(origin),
      lang: `${i18n.language}_ES`,
    });
  };
  // Review extra space in the bottom
  return (
    <Modal title={t("help_we_call_you.modal.title")} {...props}>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box
          sx={{
            width: isMobile ? "100%" : "720px",
            height: isMobile ? "342px" : "405px",
            backgroundColor: "grey.100",
          }}
        >
          <Image // TODO set image
            alt="We call you"
            src=""
          />
        </Box>

        <Box
          sx={{
            position: "relative",
            zIndex: "499",
            bottom: isMobile ? "20px" : "40px",
            background: "white",
            mx: isMobile ? "16px" : "48px",
            py: "16px",
            px: "24px",
          }}
        >
          <DialogCallYou
            sx={{
              position: "absolute",
              zIndex: "500",
              left: isMobile ? "45%" : "63%",
              top: "-90px",
            }}
          />
          <Typography variant="h3" gutterBottom>
            {t("help_we_call_you.modal.help_you")}
          </Typography>
          <Typography variant="body" gutterBottom>
            {t("help_we_call_you.modal.description")}
          </Typography>
          <Box sx={{ mt: 4 }}>
            <Form id={"we-call-you"} onSubmit={onFormSubmit}>
              <Tiles columns={1} spacing={3}>
                <InputField.FormField
                  id="name"
                  name="name"
                  label={t("funnel.signup.data.steps.personal_data.name")}
                  validate={required}
                  fullWidth
                />
                <Tiles columns={2} spacing={2}>
                  <InputField.FormField
                    id="phone"
                    name="phone"
                    label={t("funnel.signup.data.steps.personal_data.phone")}
                    mask="000 00 00 00"
                    validate={composeValidators(required, validPhone)}
                  />
                  <SelectField.FormField
                    id="schedule"
                    name="schedule"
                    label={t("help_we_call_you.modal.schedule")}
                    infoLabel={t("help_we_call_you.modal.schedule_info")}
                    options={ScheduleOptions}
                    validate={required}
                  />
                </Tiles>
                {/* TODO info label link to terms and conditions */}
                <CheckField
                  label={t("help_we_call_you.modal.authorization")}
                  onChange={(event) =>
                    setIsButtonDisabled(!event.target.checked)
                  }
                  infoLabel={
                    <Trans i18nKey="help_we_call_you.modal.authorization_info">
                      <Link target="_blank" to={privacyUrl} />
                    </Trans>
                  }
                />
              </Tiles>
              <Box mt={"16px"}></Box>
              <Form.Submit
                fullWidth
                endIcon={<ArrowForwardIcon />}
                disabled={isButtonDisabled}
                sx={{
                  textTransform: "none",
                }}
              >
                {t("help_we_call_you.modal.call_me")}
              </Form.Submit>
            </Form>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

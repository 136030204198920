import React, { useEffect, useMemo, useRef, useState } from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Tiles } from "../../components/layouts/Tiles";
import { Paginator } from "./Paginator";
import { Spinner } from "../../components/Spinner";
import { Button } from "./Button";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { Text } from "./Text";
import { TextField } from "./TextField";
import { useHistory, useLocation } from "react-router-dom";
import { useQueryParams } from "hooks/useQueryParams";

const TextFilter = ({ onChange, title, mask, value }) => {
  const { t } = useTranslation();
  const _debounce = useRef();
  const debounceSearch = ({ target }) => {
    clearTimeout(_debounce.current);
    _debounce.current = setTimeout(() => {
      if (target.value !== value) {
        onChange(target.value);
      }
    }, 250);
  };

  return (
    <Box mt={2}>
      <TextField
        label={<Text semibold>{t(title)}</Text>}
        value={value}
        onChange={debounceSearch}
        mask={mask}
      />
    </Box>
  );
};

const RadioFilters = ({ value, options, onChange, litPrefix, title }) => {
  const { t } = useTranslation();
  return (
    <Box mt={1}>
      <Text semibold>{t(title)}</Text>
      <RadioGroup
        aria-label="tipus"
        name="tipus"
        value={value}
        onChange={(ev) => onChange(ev.target.value)}
        row={true}
      >
        {options.map((v) => (
          <FormControlLabel
            key={v}
            value={v}
            control={<Radio size="small" />}
            label={<Text>{t(`${litPrefix}.${v}`)}</Text>}
          />
        ))}
      </RadioGroup>
    </Box>
  );
};

export const PaginatedResourceList = ({
  paginatorQuery,
  queryKey,
  renderItem,
  renderHeader,
  radioFiltersProps = {
    enabled: false,
    options: [],
    title: "",
    litPrefix: "",
    defaultOption: "",
  },
  textFilterProps = {
    enabled: false,
    title: "",
    mask: "*",
  },
}) => {
  const defaultOffset = 0;
  const defaultSearch = "";
  const defaultFilter = radioFiltersProps.defaultOption;
  const radioFiltersEnabled = radioFiltersProps.enabled;
  const textFilterEnabled = textFilterProps.enabled;

  const { t } = useTranslation();
  const history = useHistory();
  const qp = useQueryParams();
  const { pathname } = useLocation();

  const [offset, setOffset] = useState(qp?.offset || defaultOffset);
  const [search, setSearch] = useState(qp?.search || defaultSearch);
  const [filter, setFilter] = useState(qp?.filter || defaultFilter);
  const [totalItems, setTotalItems] = useState(0);
  const [maxItemsInPage, setMaxItemsInPage] = useState(0);

  const { data, refetch, isError, isLoading, isRefetching } = useQuery(
    [queryKey, offset, search, filter],
    () => paginatorQuery(offset, search, filter)
  );

  const { resources = [] } = useMemo(() => {
    if (data?.paginControl) {
      const paginControl_total_items = parseInt(data.paginControl.total_items);
      const paginControl_max_items_in_page = parseInt(
        data.paginControl.max_items_in_page
      );
      setTotalItems((old) =>
        old !== paginControl_total_items ? paginControl_total_items : old
      );
      setMaxItemsInPage((old) =>
        old !== paginControl_max_items_in_page
          ? paginControl_max_items_in_page
          : old
      );
      return { resources: data.items };
    }
    return { resources: [] };
  }, [data]);

  history.listen((location) => {
    if (pathname === location.pathname && location.search.length === 0) {
      setOffset(defaultOffset);
      setSearch(defaultSearch);
      setFilter(defaultFilter);
    }
  });

  useEffect(() => {
    const urlParams = new URLSearchParams({
      ...qp,
      ...{
        offset: offset,
        search: search,
        filter: filter,
      },
    });
    history.replace(`${pathname}?${urlParams.toString()}`);
  }, [offset, search, filter]);

  const calculateNextOffset = (page) => setOffset((page - 1) * maxItemsInPage);
  const onChangeFilter = (setValue, value) => {
    setValue(value);
    setOffset(0);
  };

  return (
    <Tiles columns={1}>
      {renderHeader()}
      <Box py={2}>
        {radioFiltersEnabled && (
          <RadioFilters
            value={filter}
            onChange={(value) => onChangeFilter(setFilter, value)}
            {...radioFiltersProps}
          />
        )}
        {textFilterEnabled && (
          <TextFilter
            value={search}
            onChange={(value) => onChangeFilter(setSearch, value)}
            {...textFilterProps}
          />
        )}
      </Box>
      {totalItems > maxItemsInPage && (
        <Box display="flex" justifyContent="center">
          <Paginator
            maxItemsInPage={maxItemsInPage}
            totalItems={totalItems}
            onChangePage={(page) => {
              calculateNextOffset(page);
            }}
            offset={offset}
          />
        </Box>
      )}
      {isRefetching || isLoading ? (
        <Spinner />
      ) : isError ? (
        <Tiles columns={1}>
          <div>{t("common.errors.request_failed")}</div>
          <Button onClick={refetch}>{t("common.retry")}</Button>
        </Tiles>
      ) : (
        resources.map(renderItem)
      )}
    </Tiles>
  );
};

import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export const BandwidthAction = ({ price, ...props }) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        gap: "4px",
      }}
      {...props}
    >
      <Typography variant={"body_bold"}>{`${price}€ / mes`}</Typography>
      <Typography variant={"body_small"}>
        {t("price_tag.vat_included")}
      </Typography>
    </Box>
  );
};

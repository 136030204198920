import { Box } from "@mui/material";
import { CheckItem } from "./CheckItem";

export const CheckListCard = ({ literals }) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      gap: "16px",
    }}
  >
    {literals.map((literal) => (
      <CheckItem key={literal} literal={literal} />
    ))}
  </Box>
);

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useSharedDataContext } from "../context";
import { Box, Typography } from "@mui/material";
import { Button } from "ancient/components/Button";

export const Error = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { setShouldShowStepper } = useSharedDataContext();
  setShouldShowStepper(false);
  return (
    <Box display="flex" alignItems="center" flexDirection="column" mt={5}>
      <Typography>{t("common.errors.request_failed")}</Typography>
      <Box mt={5}>
        <Button
          onClick={() => {
            history.push("mobiles");
          }}
        >
          {t("common.start_again")}
        </Button>
      </Box>
    </Box>
  );
};

import {
  styled,
  InputAdornment as MaterialInputAdornment,
} from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import React from "react";

const StyledInputAdornment = styled(MaterialInputAdornment)(({ position }) => ({
  color: "inherit",
  marginRight: position === "start" ? "10px" : "0px",
  marginLeft: position === "end" ? "10px" : "0px",
}));

export const InputAdornment = ({ position, iconAdorment, isError }) => (
  <StyledInputAdornment position={position}>
    {isError ? <WarningIcon color="error" /> : iconAdorment}
  </StyledInputAdornment>
);

import { Box } from "@mui/material";
import { Divider } from "components/Divider";
import { SuperCard } from "components/SuperCard";
import React, { useEffect, useMemo, useState } from "react";
import { DataSelector } from "./DataSelector";
import { MinutesSelector } from "./MinutesSelector";
import { MobileTariffInfo } from "./MobileTariffInfo";
import { UnlimitedMinutes } from "./UnlimitedMinutes";
import { getDataSet } from "screens/Tariffs/utils/tariffsReducers";
import { FloatingCard } from "components/layouts/FloatingCard";
import { decorateOfferTariffs } from "hooks/queries/helper/tariffDecorator";
import { Field } from "react-final-form";

export const MobileTariffPicker = ({
  mobileTariffs,
  onChange,
  tariffsInOffer,
  initialTariffCode,
  inCard = true,
}) => {
  const offers = useMemo(() => {
    return mobileTariffs.filter((t) => t.offer).map(decorateOfferTariffs);
  }, [mobileTariffs]);
  const tariffs = useMemo(() => {
    return tariffsInOffer
      ? offers.concat(mobileTariffs.filter((t) => !t.offer))
      : mobileTariffs;
  }, [tariffsInOffer]);
  const initialTariff = useMemo(() => {
    return tariffs.find(({ code }) => code === initialTariffCode);
  }, [tariffs]);

  const [dataSet, setDataSet] = useState(getDataSet(tariffs, initialTariff));
  const [selectDataOffer, setSelectDataOffer] = useState(
    dataSet.find(({ isSelected }) => isSelected) || { minutes: [] }
  );

  function setDataSelected(dataOffer) {
    const dataSetCopy = [...dataSet];
    dataSetCopy.forEach(
      (item) => (item.isSelected = item.data === dataOffer.data)
    );
    if (dataOffer.minutes.length === 1) {
      dataOffer.minutes[0].isSelected = true;
    }
    setDataSet(dataSetCopy);
    setSelectDataOffer(dataOffer);
  }

  function setMinutesOfferSelected(minutes) {
    const selectDataOfferCopy = { ...selectDataOffer };
    selectDataOfferCopy.minutes.forEach(
      (item) => (item.isSelected = item.minutes === minutes.minutes)
    );
    setSelectDataOffer(selectDataOfferCopy);
  }

  const matchingTariff = useMemo(() => {
    const data = selectDataOffer.data;
    const minutes = selectDataOffer.minutes.find(
      (minutes) => minutes.isSelected
    )?.minutes;
    return tariffs.find(
      (tariff) => tariff.data === data && tariff.minutes === minutes
    );
  }, [tariffs, selectDataOffer]);

  useEffect(() => {
    if (!matchingTariff) {
      return;
    }
    onChange(matchingTariff);
  }, [matchingTariff]);
  const CardContent = () => (
    <>
      <DataSelector
        dataset={dataSet}
        onClick={setDataSelected}
        dataOffers={tariffsInOffer ? offers.map((offer) => offer.data) : []}
      />
      {selectDataOffer.minutes.length > 0 && (
        <>
          <Divider sx={{ mt: 1 }} flexItem />
          <Box sx={{ mt: 1, width: "100%" }}>
            {selectDataOffer.minutes.length > 1 ? (
              <MinutesSelector
                minutes={selectDataOffer.minutes}
                onClick={setMinutesOfferSelected}
              />
            ) : (
              /* For Now is unlimited minutes, review in the future  */
              <UnlimitedMinutes />
            )}
          </Box>
        </>
      )}
      {matchingTariff && <MobileTariffInfo tariff={matchingTariff} />}
    </>
  );
  return (
    <FloatingCard>
      {inCard ? (
        <SuperCard sx={{ gap: 0 }}>
          <CardContent />
        </SuperCard>
      ) : (
        <CardContent />
      )}
    </FloatingCard>
  );
};

MobileTariffPicker.FormField = ({ name, validate, ...props }) => (
  <Field name={name} validate={validate}>
    {({ input, meta }) => (
      <MobileTariffPicker
        {...props}
        initialTariffCode={input.value}
        onChange={({ code }) => input.onChange(code)}
        inCard={false}
      />
    )}
  </Field>
);

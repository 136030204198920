import React from "react";
import Box from "@mui/material/Box";

import { IconButton } from "ancient/components/IconButton";
import VisibilityIcon from "@mui/icons-material/VisibilityOutlined";
import { EditIcon } from "components/icons/EditIcon";

export const Field = ({ label, value }) => (
  <div>
    <div>{label}:</div>
    <div>
      <strong>{value}</strong>
    </div>
  </div>
);

export const SecretField = ({ label, discover, tooltip, disabled = false }) => {
  return (
    <Box display="flex" flex="1" marginBottom={1}>
      <Box>
        <Field label={label} value="********" />
      </Box>
      <Box mx={4}>
        <IconButton
          size="small"
          onClick={discover}
          placement={"right-end"}
          tooltipTitle={tooltip}
          disabled={disabled}
          isOutlined
        >
          <VisibilityIcon fontSize="small" />
        </IconButton>
      </Box>
    </Box>
  );
};

export const EditableField = ({ label, value, onClick }) => {
  return (
    <Box display="grid" gridTemplateColumns={"60% 40%"} mb={3}>
      <Box>
        <Field label={label} value={value} />
      </Box>
      <Box mx={4}>
        <IconButton onClick={onClick} size="large">
          <EditIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

import { useStore } from "hooks/useStore";
import { useQueryParams } from "hooks/useQueryParams";
import { RecommendedTariffsPicker } from "../components/recommended/RecommendedTariffsPicker";

export const RecommendedTariffs = ({ packs, onContract }) => {
  const { setLines, setIsSharedLines } = useStore();
  const { bandwidth = 300, landline_phone } = useQueryParams();
  const recommendedPacks = packs.filter(
    ({ fiber_bandwidth, has_landline_phone }) =>
      fiber_bandwidth === Number(bandwidth) &&
      has_landline_phone === Boolean(landline_phone)
  );
  function onClick(matchedPack) {
    if (matchedPack) {
      setIsSharedLines(matchedPack.category === "mobile_shared_data");
      setLines(matchedPack.products);
      onContract();
    } else {
      setLines([]);
    }
  }
  return (
    <RecommendedTariffsPicker
      recommendedPacks={recommendedPacks.sort((a, b) => a.price - b.price)}
      onClick={onClick}
    />
  );
};

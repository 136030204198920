import React, { useState } from "react";
import MaterialTextField from "@mui/material/TextField";
import { Box, Tooltip as MaterialTooltip } from "@mui/material";
import { withStyles } from "@mui/styles";
import { Tooltip } from "ancient/components/Tooltip";
import { InfoIcon } from "components/icons/InfoIcon";
import { StyledFormControl } from "./shared/InputCustomizations";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import { IMaskInput } from "react-imask";

const StyledTextField = withStyles((theme) => ({
  root: {
    color: theme.palette.text.main,
  },
}))(MaterialTextField);

const MaskedInput = (props) => {
  const {
    onChange,
    placeholder,
    showPlaceHolderMask = false,
    mask,
    ...other
  } = props;
  const [hasBeenFocused, setHasBeenFocused] = useState(false);

  return (
    <IMaskInput
      {...other}
      placeholder={placeholder}
      mask={mask}
      onFocus={(...args) => {
        setHasBeenFocused(true);
        props.onFocus && props.onFocus(...args);
      }}
      lazy={!(showPlaceHolderMask && hasBeenFocused)}
      mapToRadix={["/", ".", "-", " "]}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
};

/**
 * Preconfigured Material UI TextInput for consistency sake
 */
export const TextField = React.forwardRef(({ label, info, ...props }, ref) => (
  <Box display="flex" flexDirection="row">
    <StyledFormControl>
      <StyledTextField
        {...props}
        // color="text.main"
        label={label}
        variant="outlined"
        InputProps={
          props.mask
            ? {
                inputComponent: MaskedInput,
                inputProps: props,
              }
            : undefined
        }
        inputRef={ref}
        fullWidth
      />
    </StyledFormControl>

    {info && (
      <Box display="flex" alignItems="center" justifyContent="center" ml={2}>
        <Tooltip
          variant="info"
          interactive
          enterTouchDelay={0}
          leaveTouchDelay={0}
          PopperProps={{
            disablePortal: true,
          }}
          title={
            <React.Fragment>
              <div>{info}</div>
            </React.Fragment>
          }
          arrow
        >
          <div>
            <InfoIcon />
          </div>
        </Tooltip>
      </Box>
    )}
  </Box>
));

const helperText = (t, meta) => {
  const error = meta.error || meta.submitError;

  const [errorKey, errorTextInterpolationValue] = Array.isArray(error)
    ? error
    : [error];

  return (
    meta.submitFailed &&
    error &&
    t(`common.errors.validation.${errorKey}`, errorTextInterpolationValue)
  );
};

const FormField = React.forwardRef(({ name, validate, ...props }, ref) => {
  const { t } = useTranslation();

  return (
    <Field name={name} validate={validate}>
      {({ input, meta }) => (
        <>
          <TextField
            {...props}
            error={meta.error || meta.submitError}
            name={input.name}
            value={input.value}
            onChange={input.onChange}
            ref={ref}
            helperText={helperText(t, meta)}
          />
        </>
      )}
    </Field>
  );
});

TextField.FormField = FormField;

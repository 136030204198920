import React from "react";
import { Box } from "@mui/material";

export const SuperCard = ({ children, sx }) => (
  <Box
    sx={(theme) => ({
      display: "flex",
      p: "1rem",
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "1rem",
      alignSelf: "stretch",
      borderRadius: 2,
      border: `2px solid ${theme.palette.grey[900]}`,
      background: theme.palette.grey[50],
      boxShadow: 2,
      ...sx,
    })}
  >
    {children}
  </Box>
);

import axios from "axios";

export const getContractsList = () => axios.get("/contracts/");
export const getPaginatedContractsList = async ({
  offset = 0,
  limit = 5,
  search = null,
  filter = null,
} = {}) => {
  try {
    filter = filter === "all" ? null : filter;
    search = search === "" ? null : search;
    const { data, headers } = await axios.get(`/contracts/`, {
      headers: { offset, limit },
      params: { search, filter },
    });
    return { items: data, paginControl: headers };
  } catch (e) {
    console.error(e);
    /* handle error */
  }
};
export const getContractDetail = ({ id }) => axios.get(`/contracts/${id}/`);

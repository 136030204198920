import axios from "axios";

export async function getCategories(parentId = "") {
  const { data } = await axios.get(`/knowledge/categories/?parent=${parentId}`);
  return data;
}

export async function getCategory(categoryId) {
  const { data } = await axios.get(`/knowledge/categories/${categoryId}`);
  return data;
}

export async function getPage(pageId) {
  const { data } = await axios.get(`/knowledge/pages/${pageId}`);
  return data;
}

export async function search({ pattern, parent = 31 }) {
  const { data } = await axios.get(
    `/knowledge/pages/?search=${pattern}&parent=${parent}`
  );
  return data;
}

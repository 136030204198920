import { FormSection } from "../shared/FormSection";
import { Button } from "ancient/components/Button";
import { Trans, useTranslation } from "react-i18next";
import { AttachFiles } from "ancient/components/AttachFiles";
import { Text } from "ancient/components/Text";
import { useChangeOwnerContext } from "../context";
import { ImgMiniature } from "ancient/components/ImgMiniature";
import { useState } from "react";
import { Tiles } from "components/layouts/Tiles";
import { Box } from "@mui/material";

export function NewOwnerDoc() {
  const { t } = useTranslation();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const index = 2;
  const {
    stepIndex,
    newOwnerDoc,
    setNewOwnerDoc,
    getTopRightAction,
    setStepIndex,
  } = useChangeOwnerContext();
  return stepIndex >= index ? (
    <FormSection
      index={index}
      title={t("request_change.change_owner.next_data.title_doc")}
      stepIndex={stepIndex}
      getSummary={() => (
        <Tiles columns={1}>
          {newOwnerDoc.map((doc) => (
            <Box display="flex" alignItems="center" justifyContent="start">
              <ImgMiniature miniature={doc.miniature} />
              <Text>{doc.file_name}</Text>
            </Box>
          ))}
        </Tiles>
      )}
      topRightAction={getTopRightAction}
    >
      <AttachFiles
        setEncodedFiles={(files) => {
          setNewOwnerDoc(files);
          setIsButtonDisabled(!files.length);
        }}
        getTitle={() => (
          <Text>
            <Trans
              i18nKey={
                "request_change.change_owner.next_data.vat_new_owner_lit"
              }
            />
          </Text>
        )}
        id="new-owner-vat"
        info={t("request_change.change_owner.info_doc_vat")}
        maxFiles={2}
        maxSize={2048 * 1024} // 2048 KB
        accept=".jpg, .jpeg, .png, .pdf"
        multiple
      />
      <Button
        disabled={isButtonDisabled}
        onClick={() => {
          setStepIndex((prev) => prev + 1);
        }}
      >
        {t("common.continue")}
      </Button>
    </FormSection>
  ) : (
    <></>
  );
}

import { Box } from "@mui/material";
import { Heading } from "ancient/components/Heading";
import { Spinner } from "components/Spinner";
import { Container } from "ancient/components/layouts/Container";
import { Tiles } from "components/layouts/Tiles";
import { getConfirmTicket } from "lib/api/confirmTicket";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Error } from "../shared/Error";
import { Success } from "../shared/Success";
import { NewOwnerData } from "./steps/NewOwnerData";
import { ChangeOwnerContext } from "./context";
import { NewOwnerDoc } from "./steps/NewOwnerDoc";
import { Text } from "ancient/components/Text";
import { noop } from "lib/fn/noop";
import { NewOwnerConfirm } from "./steps/NewOwnerConfirm";
import { Stack } from "ancient/components/layouts/Stack";
import { Alert } from "@mui/lab";
import { useQueryParams } from "hooks/useQueryParams";
import { checkAuth, logout } from "lib/api/auth";
import { SessionListener } from "components/modals/SessionListener";
import { useApplicationContext } from "hooks/useApplicationContext";
import { FullScreenCenteredLayout } from "components/layouts/FullScreenCenteredLayout";
import { useAuthContext } from "lib/AuthContext";
import { TopBar } from "components/TopBar";

const Content = ({ ticket, token }) => {
  const { t } = useTranslation();

  const [newOwnerData, setNewOwnerData] = useState(undefined);
  const [newOwnerDoc, setNewOwnerDoc] = useState([]);
  const [stepIndex, setStepIndex] = useState(1);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { currentUser } = useApplicationContext();

  const getTopRightAction = (index) =>
    index < stepIndex ? (
      <Text
        underline
        onClick={() => {
          setStepIndex(index);
        }}
      >
        {t("common.edit")}
      </Text>
    ) : (
      noop
    );

  const contextValue = {
    ticket,
    token,
    stepIndex,
    setStepIndex,
    newOwnerData,
    setNewOwnerData,
    newOwnerDoc,
    setNewOwnerDoc,
    getTopRightAction,
    setIsSuccess,
    setIsError,
    setIsLoading,
  };

  return (
    <ChangeOwnerContext.Provider value={contextValue}>
      <Tiles columns={1} spacing={5}>
        <Heading align="center" variant="h5">
          {t("request_change.change_owner.title")}
        </Heading>
        {(() => {
          if (isError) {
            return (
              <Stack>
                <Alert severity="error">
                  {t("common.errors.request_failed")}
                </Alert>
              </Stack>
            );
          } else if (isSuccess) {
            return <Success />;
          } else if (isLoading) {
            return <Spinner />;
          } else {
            return (
              <>
                <NewOwnerData />
                <NewOwnerDoc />
                <NewOwnerConfirm />
              </>
            );
          }
        })()}
        <SessionListener isLoggedIn={Boolean(currentUser)} />
      </Tiles>
    </ChangeOwnerContext.Provider>
  );
};
export function ChangeOwner() {
  const [ticket, setTicket] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [initialLocale, setInitialLocale] = useState("ca");

  const { token } = useParams();
  const { is_pack = false } = useQueryParams();
  const keycloak = useAuthContext();

  useEffect(() => {
    async function checkIsSessionUp() {
      let isSessionUp = false;
      // Check is session up to close it
      if (await checkAuth()) {
        await logout(() => keycloak.clearToken()); // close session
        isSessionUp = true; // Force delete currentUser in APP context
      }
      return Promise.resolve(isSessionUp);
    }
    async function getTicket() {
      try {
        const ticket = await getConfirmTicket({
          type: is_pack ? "change_owner_pack" : "change_owner",
          token: token,
        });

        setTicket(ticket.data);
        setInitialLocale(ticket.data.locale_owner.replace(/_.*/, ""));
      } catch (e) {
        setIsError(true);
      }
      setIsLoading(false);
    }
    setIsLoading(true);
    checkIsSessionUp().then((isSessionUp) =>
      isSessionUp ? window.location.reload() : getTicket()
    );
  }, [token, is_pack]);

  return (
    <>
      {isLoading ? (
        /**
         * <Spinner /> render like in app.js.
         * to prevent flickering effect when the page reloads if session is up
         *  */
        <FullScreenCenteredLayout>
          <Spinner />
        </FullScreenCenteredLayout>
      ) : (
        <TopBar locale={initialLocale}>
          <Box mt={4}>
            <Container variant="narrow">
              {isError ? <Error /> : <Content ticket={ticket} token={token} />}
            </Container>
          </Box>
        </TopBar>
      )}
    </>
  );
}

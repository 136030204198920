import { Box } from "@mui/material";
import { Button } from "ancient/components/Button";
import { Text } from "ancient/components/Text";
import { Stack } from "ancient/components/layouts/Stack";
import { Tiles } from "components/layouts/Tiles";
import { useApplicationContext } from "hooks/useApplicationContext";
import i18next from "i18next";
import { getFiberContracts } from "lib/api/fiberContracts";
import { getTariffs } from "lib/api/tariffs";
import { createTicket } from "lib/api/tickets";
import { getStartOfNextMonth } from "lib/helpers/dates";
import { formatDataPlanSize, formatPrice } from "lib/helpers/format";
import { Trans, useTranslation } from "react-i18next";
import { ProductPickerModal } from "../shared/ProductPicker";

const UNLIMITED_MINUTES_VALUE = 99999;

const formatMinutes = (minutes) => {
  if (Number(minutes) === 0) {
    return i18next.t("subscriptions.detail.product_picker.minutes.no_minutes");
  } else if (Number(minutes) === UNLIMITED_MINUTES_VALUE) {
    return i18next.t(
      "subscriptions.detail.product_picker.minutes.unlimited_minutes"
    );
  } else {
    return i18next.t("subscriptions.detail.product_picker.minutes.n_minutes", {
      count: minutes,
    });
  }
};

export const ChangeTariffModal = ({ isOpen, onClose, subscription }) => {
  const { t } = useTranslation();
  const { currentUser } = useApplicationContext();

  const getChangeTariffProducts = async () => {
    const tariffs = await getTariffs();

    return tariffs.products.filter(
      (tariff) => tariff.category === subscription.subscription_type
    );
  };
  const getFiberLinked = (
    override_ticket_ids,
    packs,
    fiber_linked,
    product_is_offer
  ) =>
    product_is_offer && override_ticket_ids && fiber_linked
      ? fiber_linked
      : product_is_offer
      ? packs[0].code
      : "";
  const onSubmit = async (
    selectedProduct,
    { override_ticket_ids = [], fiber_linked = "" } = {},
    packs = []
  ) => {
    await createTicket({
      body: "-",
      subject: "-",
      override_ticket_ids,
      meta: [
        {
          key: "ticket_type",
          value: "change_tariff",
        },
        {
          key: "phone_number",
          value: subscription.phone_number,
        },
        {
          key: "new_product_code",
          value: selectedProduct.code,
        },
        {
          key: "current_product_code",
          value: subscription.active_product_code,
        },
        {
          key: "effective_date",
          value: getStartOfNextMonth("otrs"),
        },
        {
          key: "subscription_email",
          value: subscription.email,
        },
        {
          key: "language",
          value: currentUser.preferred_locale + "_ES",
        },
        {
          key: "fiber_linked",
          value: getFiberLinked(
            override_ticket_ids,
            packs,
            fiber_linked,
            selectedProduct?.isOffer
          ),
        },
      ],
    });
  };

  return (
    <ProductPickerModal
      title={t("subscriptions.detail.change_tariff")}
      submitText={t("subscriptions.detail.change_tariff_modal.change_tariff")}
      selectedText={t(
        "subscriptions.detail.change_tariff_modal.selected_tariff"
      )}
      confirmText={() => {
        const effectiveDate = getStartOfNextMonth();
        return (
          <Text>
            <Trans i18nKey={"subscriptions.detail.change_tariff_modal.confirm"}>
              <Text>{{ effectiveDate }}</Text>
            </Trans>
          </Text>
        );
      }}
      isOpen={isOpen}
      onClose={onClose}
      subscription={subscription}
      productType="change_tariff"
      getProducts={getChangeTariffProducts}
      getAvailablePacks={getFiberContracts}
      groupProductsBy="minutes"
      priceProductMonthly={true}
      renderGroupName={(group) => formatMinutes(group)}
      renderProductDescription={(product) => {
        const productName = product.name;
        return (
          <span>
            <Trans
              i18nKey={"subscriptions.detail.change_tariff_modal.productName"}
            >
              {{ productName }}
            </Trans>
          </span>
        );
      }}
      renderConfirmationStep={(
        submit,
        { override_ticket_ids, product_name, fiber_linked }
      ) => {
        return (
          <Stack>
            <Text>
              <Trans i18nKey="subscriptions.detail.change_tariff_modal.confirmation.title">
                <Text bold>{{ product_name }}</Text>
              </Trans>
            </Text>
            <Button
              onClick={() =>
                submit({
                  override_ticket_ids,
                  fiber_linked,
                })
              }
              primary
            >
              {t("common.yes")}
            </Button>
            <Button onClick={onClose}>{t("common.no")}</Button>
          </Stack>
        );
      }}
      beforeAllShowWarning={(currentProduct, warningShown) =>
        currentProduct?.isOffer && !warningShown
      }
      renderWarningStep={(onClickConfirm, { data, price, srcTariff }) => (
        <Tiles align="center" spacing={2} columns={1}>
          <Text size="xl" textAlign="center" lineHeight={1.25} bold>
            {t("subscriptions.pack_warning_modal.warning_title")}
          </Text>
          <Text>
            {t("subscriptions.pack_warning_modal.warning_body", {
              planSize: formatDataPlanSize(data),
              currentPrice: formatPrice(price, {
                frequency: "monthly",
              }),
              originalPrice: formatPrice(srcTariff.price),
            })}
          </Text>
          <Box />
          <Button onClick={onClose} fullWidth={false}>
            {t("subscriptions.pack_warning_modal.refuse_pack_change", {
              planSize: formatDataPlanSize(data),
            })}
          </Button>
          <Button onClick={() => onClickConfirm()} fullWidth={false}>
            {t("subscriptions.pack_warning_modal.confirm_pack_change")}
          </Button>
        </Tiles>
      )}
      onSubmit={onSubmit}
      onClickClose={onClose}
      sortProductsBy="data"
    />
  );
};

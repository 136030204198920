import { Checkbox } from "ancient/components/Checkbox";
import { Separator } from "components/Separator";
import { Text } from "ancient/components/Text";
import { required } from "lib/form/validators";
import { useTranslation } from "react-i18next";
import { PaymentMethodPicker } from "screens/SignUp/shared/PaymentMethodPicker";

export const NewPartner = () => {
  const { t } = useTranslation();

  return (
    <>
      <Text semibold size="xs" uppercase letterSpacing="0.1rem">
        {t("request_change.change_owner.next_data.new_partner")}
      </Text>
      <Separator />
      <PaymentMethodPicker
        name="iban_new_partner"
        label={t("request_change.change_owner.next_data.iban_new_partner")}
      />
      <Checkbox.FormField
        name="pays_in_ten_terms"
        label={t("funnel.signup.data.steps.payment_data.pays_in_ten_terms")}
        validate={required}
        initialValue={false}
      />
    </>
  );
};

import {
  composeValidators,
  matchIbanFormat,
  required,
} from "lib/form/validators";
import { Condition } from "../Condition";
import { CheckField } from "components/fields/CheckField";
import { InputField } from "components/fields/InputField";
import { RadioToggle } from "components/fields/RadioToggle";
import compact from "lodash.compact";

import { useTranslation } from "react-i18next";
import { Tiles, TileSpan } from "components/layouts/Tiles";
import { PaymentMethodPicker } from "../PaymentMethodPicker";
import { AddressPicker } from "../AddressPicker";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Form } from "components/Form";
import { useDerivedState } from "hooks/useDerivedState";

export const PaymentMemberFeeGroup = () => {
  const { t } = useTranslation();
  const { showPayMemberFee: showPayMemberFee, mustPayMonthlyBill } =
    useDerivedState();
  const isOnlyShowingOneStep =
    compact([mustPayMonthlyBill, showPayMemberFee]).length === 1;
  return (
    <Tiles columns={2} spacing={4}>
      {!mustPayMonthlyBill ? (
        <>
          <TileSpan span={2}>
            <PaymentMethodPicker
              name="iban"
              label={t("funnel.signup.data.steps.payment_data.iban")}
            />
          </TileSpan>
          {isOnlyShowingOneStep && (
            <TileSpan span={2}>
              <AddressPicker
                name="address"
                label={t("funnel.signup.data.steps.payment_data.address")}
                validate={required}
              />
            </TileSpan>
          )}
        </>
      ) : (
        <>
          <TileSpan span={2}>
            <RadioToggle.FormField
              name="use_same_bank_account"
              leftLabel={t(
                "funnel.signup.data.steps.payment_monthly_bill.use_same_bank_account_yes"
              )}
              leftValue={true}
              rightLabel={t(
                "funnel.signup.data.steps.payment_monthly_bill.use_same_bank_account_no"
              )}
              rightValue={false}
              initialValue={true}
            />
          </TileSpan>
          <Condition when="use_same_bank_account" is={false}>
            <TileSpan span={2}>
              <InputField.FormField
                name="iban"
                label={t("funnel.signup.data.steps.payment_data.iban")}
                validate={composeValidators(required, matchIbanFormat)}
              />
            </TileSpan>
          </Condition>
          <TileSpan span={2}>
            <CheckField.FormField
              initialValue={false}
              name="pays_in_ten_terms"
              label={t(
                "funnel.signup.data.steps.payment_data.pays_in_ten_terms"
              )}
              validate={required}
            />
          </TileSpan>
        </>
      )}
      <div>
        <Form.Submit endIcon={<ArrowForwardIcon />}>
          {t("common.continue")}
        </Form.Submit>
      </div>
    </Tiles>
  );
};

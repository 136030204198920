import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

export const ConfirmationSummary = ({ fields, i18nPrefix, labelResolvers }) => {
  const [isLoading, setIsLoading] = useState(Boolean(labelResolvers));
  const [resolvedLabelsByKey, setResolvedLabelsByKey] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    if (!labelResolvers) {
      return;
    }

    Promise.all(
      Object.entries(labelResolvers).map(([key, resolver]) => {
        return resolver().then((result) => [key, result]);
      })
    ).then((entries) => {
      setResolvedLabelsByKey(Object.fromEntries(entries));
    });
  }, [labelResolvers]);

  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        flexDirection: "column",
        gap: 1,
        padding: 3,
        background: theme.palette.grey[100],
        borderRadius: 2,
      })}
    >
      {Object.entries(fields)
        .filter(([_, value]) => value !== undefined && value !== null)
        .map(([key, value]) => {
          const name = t(`${i18nPrefix}.${key}`);
          const hasResolver = Boolean(resolvedLabelsByKey[key]);

          const valueLabel = hasResolver
            ? resolvedLabelsByKey[key].find((item) => item.id === value)
                ?.name || value
            : value;

          return (
            <Box
              key={key}
              sx={{ display: "flex", alignItems: "center", gap: "16px" }}
            >
              <Typography variant="body_small" color="grey.800">
                {name}:
              </Typography>
              <Typography variant="body_bold">{valueLabel}</Typography>
            </Box>
          );
        })}
    </Box>
  );
};

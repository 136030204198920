import {
  styled,
  InputLabel as MaterialInputLabel,
  Typography,
} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import React from "react";

const StyledInputLabel = styled(MaterialInputLabel)(({ theme }) => ({
  "transform": "translate(0, -12px)",
  "color": theme.palette.grey[900],
  "&.Mui-focused": { color: theme.palette.grey[900] },
  "display": "flex",
  "alignItems": "center",
  "gap": "4px",
  "alignSelf": "stretch",
  "&.Mui-disabled": { color: theme.palette.grey[900] },
  "&.Mui-error": { color: theme.palette.grey[900] },
}));
/**
 * TODO implement tooltip in hekp icon ?
 */
export const InputLabel = ({ id, label, questionLabel }) => (
  <StyledInputLabel htmlFor={id} shrink>
    <Typography variant="body_small">{label}</Typography>
    {questionLabel && <HelpIcon />}
  </StyledInputLabel>
);

import React from "react";

const DashedArrow = ({ width = "21", height = "66", minX = 0, minY = 0 }) => (
  <svg
    width={width}
    height={height}
    viewBox={`${minX} ${minY} ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.1992 2V61.5"
      stroke="black"
      strokeWidth="3"
      strokeLinecap="round"
      strokeDasharray="6 6"
    />
    <g clipPath="url(#clip0_113_1811)">
      <path
        d="M2.19922 51.5C5.5922 54.9737 7.19922 59 10.0092 62.5C12.0826 60.2807 15.6992 54.5 17.6641 51.5"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_113_1811">
        <rect
          width="20"
          height="16"
          fill="white"
          transform="translate(0.199219 49.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

const SolidArrow = ({ width = "21", height = "66", minX = 0, minY = 0 }) => (
  <svg
    width={width}
    height={height}
    viewBox={`${minX} ${minY} ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.1992 2V61.5"
      stroke="black"
      strokeWidth="3"
      strokeLinecap="round"
    />
    <g clipPath="url(#clip0_113_1803)">
      <path
        d="M2.19922 51.5C5.5922 54.9737 7.19922 59 10.0092 62.5C12.0826 60.2807 15.6992 54.5 17.6641 51.5"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_113_1803">
        <rect
          width="20"
          height="16"
          fill="white"
          transform="translate(0.199219 49.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const ArrowVertical = ({
  variant = "solid",
  width,
  height,
  minX,
  minY,
}) =>
  variant === "solid" ? (
    <SolidArrow width={width} height={height} minX={minX} minY={minY} />
  ) : (
    <DashedArrow width={width} height={height} minX={minX} minY={minY} />
  );

import { Checkbox } from "ancient/components/Checkbox";
import { Link } from "components/Link";
import { Tiles } from "components/layouts/Tiles";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

export const AcceptTerms = ({ setIsAccepted }) => {
  const { t } = useTranslation();
  const termsUrl = t("urls.terms");
  const privacyUrl = t("urls.privacy");

  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);
  const [hasAcceptedPrivacy, setHasAcceptedPrivacy] = useState(false);

  useEffect(() => {
    setIsAccepted(hasAcceptedTerms && hasAcceptedPrivacy);
  }, [hasAcceptedTerms, hasAcceptedPrivacy]);

  return (
    <Tiles columns="1">
      <Checkbox
        name="accept_terms"
        onChange={(event) => setHasAcceptedTerms(event.target.checked)}
        label={
          <Trans i18nKey="funnel.signup.confirm.accept_terms">
            <Link target="_blank" to={termsUrl} />
          </Trans>
        }
      />
      <Checkbox
        name="accept_privacy"
        onChange={(event) => setHasAcceptedPrivacy(event.target.checked)}
        label={
          <Trans i18nKey="funnel.signup.confirm.privacy_terms">
            <Link target="_blank" to={privacyUrl} />
          </Trans>
        }
      />
    </Tiles>
  );
};

import React, { useCallback, useEffect, useState } from "react";
import { styled } from "@mui/material";
import useEmblaCarousel from "embla-carousel-react";
import { DotsStepper } from "components/DotsStepper";
/*
 * https://www.embla-carousel.com/examples/predefined/#align
 */
const Wrapper = styled("div")({ overflow: "hidden" });
const Container = styled("div")(({ slideSpacing }) => ({
  display: "flex",
  margin: `0px 16px 16px ${16 - slideSpacing}px`,
  alignItems: "baseline",
}));
const Slide = styled("div")(({ slideSpacing, slideFlex }) => ({
  flex: slideFlex,
  paddingLeft: slideSpacing,
  minWidth: 0,
}));

export function Carousel({ slides, slideSpacing = 8, slideFlex = "none" }) {
  const [emblaRef, emblaApi] = useEmblaCarousel();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [steps, setSteps] = useState(0);

  const onDotButtonClick = useCallback(
    (index) => {
      if (!emblaApi) return;
      emblaApi.scrollTo(index);
    },
    [emblaApi]
  );
  const onSelect = useCallback((emblaApi) => {
    setSelectedIndex(emblaApi.selectedScrollSnap());
  }, []);
  const onInit = useCallback((emblaApi) => {
    setSteps(emblaApi.scrollSnapList().length);
  }, []);

  useEffect(() => {
    if (!emblaApi) return;
    onInit(emblaApi);
    onSelect(emblaApi);
    emblaApi.on("reInit", onInit).on("reInit", onSelect).on("select", onSelect);
  }, [emblaApi, onInit, onSelect]);

  return (
    <>
      <Wrapper ref={emblaRef}>
        <Container slideSpacing={slideSpacing}>
          {slides.map((slide, index) => (
            <Slide
              slideSpacing={slideSpacing}
              slideFlex={slideFlex}
              key={`slide_${index}`}
            >
              {slide}
            </Slide>
          ))}
        </Container>
      </Wrapper>
      <DotsStepper
        activeStep={selectedIndex}
        steps={steps}
        onStepClick={onDotButtonClick}
      />
    </>
  );
}

import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
export const UnlimitedMinutes = () => {
  const { t } = useTranslation();
  return (
    <Box sx={{ my: "10px" }}>
      <Typography variant="body_bold">{t("common.with")}</Typography>
      <br />
      <Typography variant="body_xl_bold">
        {t("subscriptions.detail.product_picker.minutes.unlimited_minutes")}
      </Typography>
    </Box>
  );
};

import { Box } from "@mui/material";

export const Footer = ({ children }) => (
  <Box
    sx={(theme) => ({
      display: "flex",
      p: "16px 16px 24px 16px",
      justifyContent: "center",
      alignItems: "flex-start",
      gap: "8px",
      borderTop: `2px solid ${theme.palette.grey[900]}`,
      background: theme.palette.grey[50],
    })}
  >
    {children}
  </Box>
);

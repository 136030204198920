import { RadioToggle } from "ancient/components/RadioToggle";
import { TextField } from "ancient/components/TextField";
import { Tiles } from "components/layouts/Tiles";
import {
  composeValidators,
  matchEmailFormat,
  matchVatFormat,
  required,
  requiredLastName,
} from "lib/form/validators";
import { useTranslation } from "react-i18next";
import { Condition } from "screens/SignUp/shared/Condition";
import { PaymentMethodPicker } from "screens/SignUp/shared/PaymentMethodPicker";

export const Common = () => {
  const { t } = useTranslation();
  return (
    <>
      <RadioToggle.FormField
        name="new_owner_is_company"
        leftLabel={t("funnel.signup.data.steps.personal_data.i_am_a_person")}
        leftValue={false}
        rightLabel={t("funnel.signup.data.steps.personal_data.i_am_a_company")}
        rightValue={true}
      />
      <Tiles columns={2} spacing={4}>
        <Condition when="new_owner_is_company" is={false}>
          <TextField.FormField
            name="vat_new_owner"
            validate={composeValidators(required, matchVatFormat)}
            label={t("request_change.change_owner.next_data.vat_new_owner")}
          />
          <TextField.FormField
            name="name_new_owner"
            label={t("request_change.change_owner.next_data.name_new_owner")}
            validate={required}
          />
          <TextField.FormField
            name="surname_new_owner"
            label={t("request_change.change_owner.next_data.surname_new_owner")}
            validate={required}
          />
          <TextField.FormField
            name="lastname_new_owner"
            label={t(
              "request_change.change_owner.next_data.lastname_new_owner"
            )}
            validate={requiredLastName}
          />
        </Condition>
        <Condition when="new_owner_is_company" is={true}>
          <TextField.FormField
            name="vat_new_owner"
            validate={composeValidators(required, matchVatFormat)}
            label={t(
              "request_change.change_owner.next_data.vat_new_owner_comp"
            )}
          />
          <TextField.FormField
            name="name_new_owner"
            label={t(
              "request_change.change_owner.next_data.name_new_owner_comp"
            )}
            validate={required}
          />
        </Condition>

        <TextField.FormField
          name="email_new_owner"
          validate={composeValidators(required, matchEmailFormat)}
          label={t("request_change.change_owner.next_data.email_new_owner")}
        />
        <PaymentMethodPicker
          name="iban_new_owner"
          label={t("request_change.change_owner.next_data.iban_service")}
        />
      </Tiles>
    </>
  );
};

import { Box, Typography } from "@mui/material";
import { UpperDialog } from "components/icons/Dialogs";
import { Trans } from "react-i18next";

export const DialogCallYou = ({ width = "199px", height = "148px", sx }) => {
  return (
    <Box
      sx={{
        position: "relative",
        width: width,
        height: height,
        ...sx,
      }}
    >
      <Typography
        sx={{
          fontFamily: "Rock Salt",
          fontSize: "13px",
          fontWeight: "400",
          lineHeight: "18px",
          letterSpacing: "-0.26px",
          textAlign: "center",
          position: "relative",
          top: "18px",
          right: "6px",
          zIndex: "250",
        }}
      >
        <Trans i18nKey="help_we_call_you.modal.remember" />
      </Typography>
      <Box sx={{ position: "relative", bottom: "74px", zIndex: "200" }}>
        <UpperDialog width={width} height={height} />
      </Box>
    </Box>
  );
};

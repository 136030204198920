function getColor(color) {
  switch (color) {
    case "black":
      return "black";
    case "white":
      return "white";
    default:
      // default
      return "purple";
  }
}

function getSize(size) {
  switch (size) {
    case "small":
      return "sm";
    case "large":
      return "big";
    default:
      // medium
      return "md";
  }
}

export const BrandLogo = ({ color = "default", size = "md", locale }) => {
  return (
    <div>
      <img
        src={`/svg/brandlogo/${locale}/${getColor(color)}_${getSize(size)}.svg`}
        alt="Brand Logo"
      />
    </div>
  );
};

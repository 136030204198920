import { CheckField } from "components/fields/CheckField";
import { InputField } from "components/fields/InputField";
import { RadioToggle } from "components/fields/RadioToggle";
import { Tiles, TileSpan } from "components/layouts/Tiles";
import { useApplicationContext } from "hooks/useApplicationContext";
import { getAvailableProviders } from "lib/api/availableProviders";
import { noop } from "lib/fn/noop";
import {
  checkPhoneIsHired,
  composeValidators,
  required,
  validMobilePhone,
} from "lib/form/validators";
import React from "react";
import { useTranslation } from "react-i18next";
import { AddressPicker } from "./AddressPicker";
import { ApiSelect } from "./ApiSelect";
import { Condition } from "./Condition";
import { PreviousOwnerFields } from "./PreviousOwnerFields";

export const MobileAdditionalData = () => {
  const { t } = useTranslation();
  const isLoggedIn = Boolean(useApplicationContext().currentUser);

  return (
    <div>
      <Tiles columns={2} spacing={4}>
        <TileSpan span="all">
          <RadioToggle.FormField
            name="keep_number"
            leftLabel={t(
              "funnel.signup.data.steps.mobile_line_additional_data.keep_number_yes"
            )}
            leftValue={true}
            rightLabel={t(
              "funnel.signup.data.steps.mobile_line_additional_data.keep_number_no"
            )}
            rightValue={false}
          />
        </TileSpan>
        <Condition when="keep_number" is={true}>
          <PreviousOwnerFields />
          <TileSpan span="all">
            <ApiSelect
              queryKey="previous_provider_mobile"
              name="previous_provider"
              validate={required}
              label={t(
                "funnel.signup.data.steps.mobile_line_additional_data.previous_provider"
              )}
              mapItem={(item) => ({ label: item.name, value: item.id })}
              query={() => getAvailableProviders({ category: "mobile" })}
            />
          </TileSpan>
          <InputField.FormField
            mask="000 00 00 00"
            name="phone_number"
            validate={composeValidators(
              required,
              validMobilePhone,
              isLoggedIn ? checkPhoneIsHired : noop
            )}
            label={t(
              "funnel.signup.data.steps.mobile_line_additional_data.phone_number"
            )}
          />
          <CheckField.FormField
            name="is_prepaid"
            initialValue={false}
            validate={required}
            label={t(
              "funnel.signup.data.steps.mobile_line_additional_data.is_prepaid"
            )}
          />
          <Condition when="is_prepaid" is={true}>
            <TileSpan span="all">
              <InputField.FormField
                validate={required}
                name="icc_donor"
                info={t(
                  "funnel.signup.data.steps.mobile_line_additional_data.icc_help_text"
                )}
                label={t(
                  "funnel.signup.data.steps.mobile_line_additional_data.icc"
                )}
              />
            </TileSpan>
          </Condition>
        </Condition>
        <TileSpan span="all">
          <CheckField.FormField
            initialValue={false}
            validate={required}
            name="has_sim_card"
            label={t(
              "funnel.signup.data.steps.mobile_line_additional_data.has_sim_card"
            )}
          />
        </TileSpan>
        {/* <Condition when="has_sim_card" is={false}>
          <TileSpan span="all">
            <AddressPicker
              validate={required}
              name="delivery_address"
              label={t(
                "funnel.signup.data.steps.mobile_line_additional_data.delivery_address"
              )}
            /> 
          </TileSpan>
        </Condition> */}
        <Condition when="has_sim_card" is={true}>
          <TileSpan span="all">
            <InputField.FormField
              validate={required}
              name="icc"
              label={t(
                "funnel.signup.data.steps.mobile_line_additional_data.icc"
              )}
              info={t(
                "funnel.signup.data.steps.mobile_line_additional_data.icc_help_text"
              )}
            />
          </TileSpan>
        </Condition>
        <div />
      </Tiles>
    </div>
  );
};

import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
export const TechAction = () => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        p: "2px",
        justifyContent: "center",
        alignItems: "center",
        gap: "4px",
        alignSelf: "stretch",
        cursor: "pointer",
      }}
    >
      <InfoIcon sx={{ fontSize: "1.25rem" }} />
      <Typography variant="link">{t("common.info")}</Typography>
    </Box>
  );
};

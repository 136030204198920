import { Modal } from "ancient/components/Modal";
import { Stack } from "ancient/components/layouts/Stack";

export const ModalLayout = ({
  isOpen,
  onClose,
  showCloseButton = true,
  disableBackdropClick = false,
  align = "center",
  spacing = 4,
  fullWidth = true,
  children,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      disableBackdropClick={disableBackdropClick}
      showCloseButton={showCloseButton}
      fullWidth={fullWidth}
    >
      <Stack align={align} spacing={spacing}>
        {children}
      </Stack>
    </Modal>
  );
};

import { Box, Radio, Typography, useMediaQuery } from "@mui/material";
import { Field } from "react-final-form";
import { RadioChecked } from "components/icons/RadioChecked";
import { RadioUnChecked } from "components/icons/RadioUnChecked";

const RadioWrapper = ({ isSelected, onClick, radioValue, label }) => {
  return (
    <Box
      onClick={onClick}
      sx={(theme) => ({
        display: "flex",
        alignItems: "center",
        gap: "10px",
        padding: "7px",
        width: "300px",
        borderRadius: "8px",
        border: isSelected && `4px solid ${theme.palette.primary.main}`,
        cursor: "pointer",
      })}
    >
      <Radio
        icon={<RadioUnChecked />}
        checkedIcon={<RadioChecked />}
        checked={isSelected}
        value={radioValue}
      />
      <Typography variant="body_bold" sx={{ opacity: isSelected ? 1.0 : 0.7 }}>
        {label}
      </Typography>
    </Box>
  );
};

export const RadioToggle = ({
  value = true,
  onChange,
  name,
  leftValue,
  rightValue,
  leftLabel,
  rightLabel,
}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        alignItems: "center",
        gap: "16px",
      }}
    >
      <RadioWrapper
        onClick={() => onChange(leftValue)}
        name={name}
        isSelected={value === leftValue}
        label={leftLabel}
      />
      <RadioWrapper
        onClick={() => onChange(rightValue)}
        name={name}
        isSelected={value === rightValue}
        label={rightLabel}
      />
    </Box>
  );
};

RadioToggle.FormField = ({ name, validate, initialValue, ...props }) => (
  <Field name={name} validate={validate} initialValue={initialValue}>
    {({ input, meta }) => (
      <RadioToggle {...props} value={input.value} onChange={input.onChange} />
    )}
  </Field>
);

import { Typography } from "@mui/material";
import { SelectRow } from "components/layouts/SelectRow";
import { PricingSelect } from "components/PricingSelect";
import { useTranslation } from "react-i18next";
import { MobilesContent } from "./MobilesContent";

export const MobilesSelector = ({ onClick, mobiles }) => {
  const { t } = useTranslation();
  return (
    <SelectRow
      title={
        <Typography variant="body_bold">
          {t("funnel.tariffs.recommended.filters.label_mobile")}
        </Typography>
      }
    >
      {mobiles.map((mobiles) => (
        <PricingSelect
          isArrowed
          isSelected={mobiles.isSelected}
          content={
            <MobilesContent
              mobiles={mobiles.mobiles}
              onClick={() => onClick(mobiles)}
            />
          }
          key={mobiles.mobiles}
        />
      ))}
    </SelectRow>
  );
};

import React from "react";
import MaterialBackdrop from "@mui/material/Backdrop";
import { withStyles } from "@mui/styles";
import { Spinner } from "components/Spinner";

const StyledBackdrop = withStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.background.dark,
  },
}))(MaterialBackdrop);

export const Backdrop = ({ ...props }) => {
  return (
    <StyledBackdrop {...props}>
      <Spinner />
    </StyledBackdrop>
  );
};

import { Button } from "../../../../ancient/components/Button";
import { useTranslation } from "react-i18next";
import { ModalLayout } from "../../../../ancient/components/layouts/ModalLayout";
import Alert from "@mui/material/Alert";

export const LogModal = ({
  isOpen,
  onClose,
  message,
  data,
  severity = "info",
}) => {
  const { t } = useTranslation();
  const messageKey = `share_data.limit_data.log.${severity}.${message}`;

  return (
    <ModalLayout
      isOpen={isOpen}
      onClose={onClose}
      showCloseButton={false}
      fullWidth={false}
    >
      <Alert severity={severity}>{t(messageKey)}</Alert>
      <Button onClick={onClose} fullWidth={false}>
        {t("common.close")}
      </Button>
    </ModalLayout>
  );
};

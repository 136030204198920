import { noop } from "lib/fn/noop";
import { useTranslation } from "react-i18next";
import { useStore } from "./useStore";
import { Button } from "components/Button";

export const useLinesDerivedState = () => {
  const state = useStore((state) => state);
  const { t } = useTranslation();

  const RemoveLineButton = (id, type, onClick) => (
    <Button
      variant={"transparent"}
      size={"small"}
      sx={{ textTransform: "none" }}
      onClick={() => {
        state.removeLine(id, type);
        onClick();
      }}
    >
      {t("funnel.shared.remove_service")}
    </Button>
  );

  const internetLines = state.lines.filter((line) => line.type === "internet");
  const internetFiberLines = internetLines.filter(
    (line) => line?.category === "fiber"
  );

  const initialMobileLines =
    state.initialIntent &&
    state.initialIntent.lines.filter((line) => line.type === "mobile");
  const mobileLines = state.lines.filter((line) => line.type === "mobile");
  const mobileLinesInOffer = mobileLines.filter(
    (line) => line?.is_offer_tariff
  );
  const isMultipleLines = state.lines.length > 1;
  //Offer with one fiber <-> one mobile.
  const hasRelationshipFiberMobileOffer = () =>
    internetFiberLines.length === 1 && mobileLinesInOffer.length === 1;
  const hasFiberLineToAssociateMobile = () =>
    internetFiberLines.length || state.fiberLinesToAssociateMobile.length;

  const isLineByIdEmpty = (id) =>
    state.lines.find(({ __id }) => __id === id)?.code === undefined;
  const hasEmptyLines = () =>
    state.lines.some((line) => isLineByIdEmpty(line.__id));
  const hasSomeLineLoaded = () =>
    state.lines.some((line) => !isLineByIdEmpty(line.__id));
  const hasInternetLineLoaded = () =>
    internetLines.some((line) => !isLineByIdEmpty(line.__id));
  const getLinesTotalAmount = () =>
    state.lines.reduce(
      (totalAmount, tariff) => totalAmount + Number(tariff.price),
      0
    );
  const canShowMobileTariffInOffer = (mobileLineId) =>
    hasFiberLineToAssociateMobile() &&
    (state.idMobileTariffInOffer < 0 ||
      state.idMobileTariffInOffer === mobileLineId);
  return {
    internetLines,
    internetFiberLines,
    initialMobileLines,
    mobileLines,
    mobileLinesInOffer,
    isMultipleLines,
    hasRelationshipFiberMobileOffer: hasRelationshipFiberMobileOffer(),
    hasFiberLineToAssociateMobile: hasFiberLineToAssociateMobile(),
    canShowMobileTariffInOffer: (mobileLineId) =>
      canShowMobileTariffInOffer(mobileLineId),
    hasEmptyLines: hasEmptyLines(),
    hasSomeLineLoaded: hasSomeLineLoaded(),
    hasInternetLineLoaded: hasInternetLineLoaded(),
    isLineByIdEmpty: (id) => isLineByIdEmpty(id),
    linesTotalAmount: getLinesTotalAmount(),
    getRemoveLineButton: (id, type, onClick = noop) =>
      RemoveLineButton(id, type, onClick),
  };
};

export const PhoneOutline = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 3C19 1.896 18.104 1 17 1H7C5.896 1 5 1.896 5 3V21C5 22.104 5.896 23 7 23H17C18.104 23 19 22.104 19 21V3ZM17 21H7V3H17V21Z"
      fill="black"
    />
    <path
      d="M9 5C9 4.44772 9.44772 4 10 4H14C14.5523 4 15 4.44772 15 5C15 5.55228 14.5523 6 14 6H10C9.44772 6 9 5.55228 9 5Z"
      fill="black"
    />
  </svg>
);

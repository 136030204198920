import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { compact } from "lodash";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { decorateOfferTariffs } from "hooks/queries/helper/tariffDecorator";
import { useDerivedState } from "hooks/useDerivedState";
import { useLinesDerivedState } from "hooks/useLinesDerivedState";
import { useLinesFormDerivedState } from "hooks/useLinesFormDerivedState";
import { useStore } from "hooks/useStore";
import { getAvailableProviders } from "lib/api/availableProviders";
import { required } from "lib/form/validators";
import { getFullName } from "lib/helpers/format";
import { capitalize } from "lib/helpers/string";
import { InternetTariffPicker } from "screens/Tariffs/components/internet/InternetTariffPicker";
import { MobileTariffPicker } from "screens/Tariffs/components/mobile/MobileTariffPicker";
import { ConfirmationSummary } from "../ConfirmationSummary";
import { formatFullAddress } from "../formatFullAddress";
import { FormStep } from "../FormStep";
import { FormStepGroup } from "../FormStepGroup";
import { InternetAdditionalData } from "../InternetAdditionalData";
import { MobileAdditionalData } from "../MobileAdditionalData";
import { ModalAdviceOffer } from "./ModalAdviceOffer";
import { Form } from "components/Form";
import { Tiles } from "components/layouts/Tiles";
import { Typography } from "@mui/material";

export const LineStepGroup = ({
  index,
  stepsBefore = 2,
  line,
  isLast,
  confirmMode,
  tariffs,
  isLastOfKind,
  isUniqueOfType,
  indexOfKind,
}) => {
  const { t } = useTranslation();
  const state = useStore((state) => state);
  const {
    saveAddress,
    hasLandlinePhone,
    formStepDataByKey,
    fiberLinesToAssociateMobile,
    isSharedLines,
    checkShipmentSimCard,
  } = state;
  const {
    getAddressById,
    clearMobileLineCanBeOfferInForm,
    setOfferMobileLineInFormStepData,
  } = useDerivedState();

  const history = useHistory();

  const { mobileLines, initialMobileLines, getRemoveLineButton } =
    useLinesDerivedState();

  const {
    internetLineInFormData,
    hasOthersMobileLinesInOffer,
    getMobileCodeByLineId,
  } = useLinesFormDerivedState();

  const canShowMobileTariffInOffer = (line__id) => {
    if (!Boolean(hasOthersMobileLinesInOffer(line__id))) {
      return internetLineInFormData?.code
        ? tariffs.find(({ code }) => code === internetLineInFormData.code)
            .category === "fiber"
        : Boolean(fiberLinesToAssociateMobile.length);
    }
    return false;
  };

  const calculateNextSteptoGo = useStore(
    (state) => state.calculateNextSteptoGo
  );

  const [canOpenModalAdviceOffer, setCanOpenModalAdviceOffer] = useState(true);
  const [isModalAdviceOfferOpen, setIsModalAdviceOfferOpen] = useState(false);

  const closeAdviceOfferModal = () => {
    setIsModalAdviceOfferOpen(false);
    setCanOpenModalAdviceOffer(false);
  };

  const updateInternetTariffData = () => {
    if (line.type === "internet") {
      const dataToUpdate = {
        internet_without_phone: !hasLandlinePhone,
      };
      let dataCurrent = formStepDataByKey[`line-${line.__id}/additional-data`];
      if (dataCurrent) {
        dataCurrent = Object.assign(dataCurrent, dataToUpdate);
      }
    }
  };

  const checkInternetTechnology = ({ code: selectedCode }) => {
    if (line.type === "internet" && mobileLines.length > 0) {
      const categorySelectedInternetTariff = tariffs.find(
        ({ code }) => code === selectedCode
      ).category;
      const categoryInternetTariffInFormData = tariffs.find(
        ({ code }) => code === internetLineInFormData.code
      ).category;

      if (
        categorySelectedInternetTariff !== "fiber" &&
        categoryInternetTariffInFormData === "fiber"
      ) {
        if (canOpenModalAdviceOffer) {
          setIsModalAdviceOfferOpen(true);
          return false;
        }
        clearMobileLineCanBeOfferInForm();
      } else if (
        categorySelectedInternetTariff === "fiber" &&
        categoryInternetTariffInFormData !== "fiber"
      ) {
        clearMobileLineCanBeOfferInForm();
      }
    }
    return true;
  };

  const saveAddressFromField = (address) => (values) => {
    saveAddress(values[address]);
  };

  const updateAdditionalData = (values) => {
    if (line.type === "internet") {
      saveAddressFromField("service_address")(values);
    } else if (line.type === "mobile") {
      checkShipmentSimCard();
    }

    if (!isLast) {
      return;
    }

    history.push("payment");
  };

  const renderLineSummary = (line) => {
    const tariffs = getTariffsToMobile(line.__id);

    const tariff = tariffs.find((tariff) => tariff.code === line.code);

    if (!tariff) {
      return "";
    }

    if (confirmMode) {
      return (
        <ConfirmationSummary
          i18nPrefix="funnel.signup.data.steps.internet_line_additional_data.confirmation"
          fields={{
            contracted_service: tariff.name,
          }}
        />
      );
    }

    return (
      <Typography variant="body_small">
        {tariff.name}
        <b>
          {" " + tariff.price}
          {t("common.euros_per_month")}
        </b>
      </Typography>
    );
  };

  const renderInternetAdditionalDataSumary = (values) => {
    if (!confirmMode) {
      return (
        <Typography variant="body_small">
          {values.service_address &&
            formatFullAddress(getAddressById(values.service_address._id))}
        </Typography>
      );
    }

    return (
      <ConfirmationSummary
        i18nPrefix="funnel.signup.data.steps.internet_line_additional_data.confirmation"
        fields={{
          phonenumber: !values.internet_without_phone
            ? values.phone_number
            : null,
          service_address:
            values.service_address &&
            formatFullAddress(getAddressById(values.service_address._id)),
        }}
      />
    );
  };

  const renderMobileAdditionalDataSumary = (values) => {
    if (!confirmMode) {
      return (
        <Typography variant="body_small">
          {compact([
            values.phone_number,
            values.other_person &&
              [
                values.previous_owner_name,
                values.previous_owner_surname,
                values.previous_owner_lastname,
              ]
                .map(capitalize)
                .join(" "),
            values.previous_owner_vat,
          ]).join(" · ")}
        </Typography>
      );
    }

    return (
      <ConfirmationSummary
        i18nPrefix="funnel.signup.data.steps.mobile_line_additional_data.confirmation"
        labelResolvers={{
          previous_provider: () =>
            getAvailableProviders({ category: line.type }),
        }}
        fields={{
          phonenumber: values.phone_number,
          other_person_fullname: values.other_person
            ? getFullName(values, {
                name: "previous_owner_name",
                surname: "previous_owner_surname",
                lastname: "previous_owner_lastname",
              })
            : null,
          other_person_vat: values.previous_owner_vat,
          previous_provider: values.previous_provider,
          current_contract_type: null,
          delivery_address: values.delivery_address
            ? formatFullAddress(getAddressById(values.delivery_address._id))
            : null,
        }}
      />
    );
  };

  const getTopRightAction = (confirmMode, indexOfKind, line) =>
    !isSharedLines &&
    !confirmMode &&
    line.type === "mobile" &&
    indexOfKind > initialMobileLines.length - 1
      ? getRemoveLineButton(line.__id, line.type)
      : null;

  const getTariffsToMobile = (line__id) => {
    const isShowingOffer = canShowMobileTariffInOffer(line__id);
    if (
      isShowingOffer &&
      //TODO peor es morirse
      getMobileCodeByLineId(line__id) === "SE_SC_REC_MOBILE_T_UNL_20480"
    ) {
      setOfferMobileLineInFormStepData(line__id);
    }
    return isShowingOffer ? tariffs.map(decorateOfferTariffs) : tariffs;
  };
  const lineType = isSharedLines
    ? line.type === "internet"
      ? "internet_shared_pack"
      : "mobile_shared_pack"
    : line.type;

  const titleI18nKey = isUniqueOfType
    ? `funnel.shared.category.${lineType}`
    : `funnel.shared.nth_${lineType}_line`;

  return (
    <FormStepGroup
      index={stepsBefore + index}
      title={t(titleI18nKey, { n: `${indexOfKind + 1}a` })}
      confirmMode={confirmMode}
      topRightAction={getTopRightAction(confirmMode, indexOfKind, line)}
    >
      <FormStep
        id={`line-${line.__id}/tariff`}
        title="Tarifa"
        initialValues={line}
        renderSummary={renderLineSummary}
        omitTitleWhenOpen
        checkToFormSubmit={checkInternetTechnology}
        onSubmit={updateInternetTariffData()}
      >
        <Tiles columns={1} spacing={4}>
          {line.type === "internet" ? (
            <InternetTariffPicker.FormField
              name="code"
              isInfoArrowed={false}
              validate={required}
              internetTariffs={tariffs
                .filter((tariff) => tariff.category !== "mobile")
                .sort(
                  (tariff_a, tariff_b) =>
                    tariff_a.bandwidth - tariff_b.bandwidth
                )}
            />
          ) : (
            <MobileTariffPicker.FormField
              name="code"
              validate={required}
              mobileTariffs={getTariffsToMobile(line.__id).filter(
                (tariff) => tariff.category === "mobile"
              )}
            />
          )}
          <Form.Submit endIcon={<ArrowForwardIcon />}>
            {t("common.continue")}
          </Form.Submit>
        </Tiles>
        <ModalAdviceOffer
          isOpen={isModalAdviceOfferOpen && canOpenModalAdviceOffer}
          onClose={() => {
            closeAdviceOfferModal();
            calculateNextSteptoGo();
          }}
          onConfirm={() => {
            clearMobileLineCanBeOfferInForm();
            closeAdviceOfferModal();
          }}
          form__id={`line-${line.__id}/tariff`}
        />
      </FormStep>
      <FormStep
        id={`line-${line.__id}/additional-data`}
        title={t("funnel.shared.additional_info")}
        renderSummary={(values) =>
          line.type === "internet"
            ? renderInternetAdditionalDataSumary(values)
            : renderMobileAdditionalDataSumary(values)
        }
        initialValues={
          line.type === "internet"
            ? {
                already_has_service: true,
                internet_without_phone: !hasLandlinePhone,
              }
            : {
                has_sim_card: false,
                is_prepaid: false,
                keep_number: true,
              }
        }
        onSubmit={(values) => updateAdditionalData(values)}
      >
        <Tiles columns={1} spacing={4}>
          {line.type === "internet" ? (
            <InternetAdditionalData line={line} isLast={isLast} />
          ) : (
            <MobileAdditionalData line={line} isLast={isLast} />
          )}
          <Form.Submit endIcon={<ArrowForwardIcon />}>
            {t("common.continue")}
          </Form.Submit>
        </Tiles>
      </FormStep>
    </FormStepGroup>
  );
};

import React, { useState } from "react";
import MaterialAlert from "@mui/material/Alert";
import MaterialAlertTitle from "@mui/material/AlertTitle";
import { Typography, Collapse } from "@mui/material";
import { withStyles } from "@mui/styles";
import { Trans, useTranslation } from "react-i18next";
import { theme } from "theme";
import { Tiles } from "components/layouts/Tiles";
import { Link } from "components/Link";

/**
 * Preconfigured Material UI Alert
 */
export const Alert = ({
  severity = "info",
  alertTitle = null,
  alertContent,
  alertSubtitle = null,
  alertSubContent,
  closable = false,
  contactUs = false,
}) => {
  const StyledAlert = withStyles((theme) => ({
    root: {
      "& .MuiAlert-icon": {
        backgroundColor:
          severity == "info"
            ? theme.palette.primary.main
            : theme.palette.secondary.main,
        color:
          severity == "info"
            ? theme.palette.background.light
            : theme.palette.background.darkest,
      },
      "& .MuiAlert-action": {
        alignItems: "flex-start",
        color:
          severity == "info"
            ? theme.palette.primary.main
            : theme.palette.primary.dark,
      },
    },
  }))(MaterialAlert);

  const StyledAlertTitle = withStyles((theme) => ({
    root: {
      color:
        severity == "info"
          ? theme.palette.primary.main
          : theme.palette.primary.dark,
      textTransform: "uppercase",
    },
  }))(MaterialAlertTitle);

  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(true);

  return (
    <Collapse in={isOpen}>
      <StyledAlert
        severity={severity}
        onClose={closable ? () => setIsOpen(false) : null}
      >
        <Tiles columns={1} spacing={0}>
          {Boolean(alertTitle) && (
            <StyledAlertTitle> {t(alertTitle)} </StyledAlertTitle>
          )}
          <Typography>
            <Trans i18nKey={alertContent}>
              {contactUs && (
                <Link
                  target="_blank"
                  to={"mailto:" + t("common.assistance_email")}
                />
              )}
            </Trans>
          </Typography>
          {Boolean(alertSubtitle) && (
            <>
              <Typography
                variant="overline"
                style={{
                  color:
                    severity == "info"
                      ? theme.palette.primary.main
                      : theme.palette.primary.dark,
                }}
              >
                {t(alertSubtitle)}
              </Typography>
              <Typography>
                <Trans i18nKey={alertSubContent} />
              </Typography>
            </>
          )}
        </Tiles>
      </StyledAlert>
    </Collapse>
  );
};

import { Typography } from "@mui/material";
import { Link } from "components/Link";
import { Footer } from "components/layouts/Footer";
import { Trans, useTranslation } from "react-i18next";

export const FaqsFooter = () => {
  const { i18n } = useTranslation();
  const locale = i18n.language;
  return (
    <Footer>
      <Typography variant="body">
        <Trans i18nKey={"faqs.tariffs-cta"}>
          {/* TODO review the new url to Link */}
          <Link
            target="_blank"
            to={
              locale === "ca"
                ? "https://somconnexio.coop/preguntes/"
                : "https://somosconexion.coop/preguntas/"
            }
          />
        </Trans>
      </Typography>
    </Footer>
  );
};

import React from "react";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import { DialogTitle, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Backdrop } from "ancient/components/Backdrop";
import { noop } from "lib/fn/noop";
import { CloseIcon } from "components/icons/CloseIcon";

const useStyles = makeStyles((theme) => ({
  paperScrollPaper: {
    maxHeight: `calc(100% - ${theme.spacing(3)})`,
  },
  paperFullWidth: {
    maxWidth: `calc(100% - ${theme.spacing(3)})`,
  },
  paper: {
    borderRadius: 0,
  },
}));

const boxStyles = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  overflow: "auto",
  px: [3, 8],
  py: [3, 5],
  paddingBottom: 8,
};

export const Modal = ({
  isOpen,
  onClose = noop,
  children,
  showCloseButton = true,
  showBackdrop = false,
  disableBackdropClick = false,
  fullWidth = true,
}) => {
  const styles = useStyles();
  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick" && disableBackdropClick) return;
    onClose();
  };
  return (
    <Dialog
      classes={styles}
      style={{ transition: "top 100ms" }}
      open={isOpen}
      onClose={handleClose}
      fullWidth={fullWidth}
      TransitionProps={{
        onExited: onClose,
      }}
    >
      <DialogTitle>
        {showCloseButton && (
          <Box position="absolute" top={0} right={0} padding={2}>
            <IconButton onClick={onClose} size="large">
              <CloseIcon />
            </IconButton>
          </Box>
        )}
      </DialogTitle>
      <Box {...boxStyles}>{children}</Box>
      <Box paddingBottom={4} />
      <Backdrop open={showBackdrop} />
    </Dialog>
  );
};

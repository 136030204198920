import { Box } from "@mui/material";
import { Alert } from "@mui/lab";
import { useTranslation } from "react-i18next";

export function Error() {
  const { t } = useTranslation();

  return (
    <Box width="100%">
      <Alert severity="error">
        {t("confirm_change.invalid_or_expired_token")}
      </Alert>
    </Box>
  );
}

import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import { useStore } from "hooks/useStore";
import {
  required,
  matchIbanFormat,
  composeValidators,
} from "lib/form/validators";
import { useForm, useFormState } from "react-final-form";
import { formatIBAN } from "lib/helpers/format";
import { InputField } from "components/fields/InputField";
import { SelectField } from "components/fields/SelectField";

export const PaymentMethodPicker = ({
  name,
  label,
  paymentMethodType = "bank-account",
}) => {
  const formState = useFormState();
  const form = useForm();
  const fieldValue = formState.values[name];
  const inputRef = useRef(null);

  const availablePaymentMethods = useStore(
    (state) => state.availablePaymentMethods
  );

  const [isInsertingNew, setIsInsertingNew] = useState(false);

  useEffect(() => {
    if (isInsertingNew) {
      return;
    }

    const willInsertNew = fieldValue === "add_new";

    setIsInsertingNew(willInsertNew);

    if (willInsertNew) {
      form.mutators.clearField(name);
    }
  }, [fieldValue, isInsertingNew, form.mutators]);

  useLayoutEffect(() => {
    if (!isInsertingNew) {
      return;
    }

    inputRef?.current?.focus();
  }, [inputRef, isInsertingNew]);

  const options = availablePaymentMethods
    .filter(({ type }) => type === paymentMethodType)
    .map(({ iban }) => ({
      label: formatIBAN(iban),
      value: iban,
    }));
  const { t } = useTranslation();

  if (availablePaymentMethods.length === 0 || isInsertingNew) {
    return (
      <InputField.FormField
        ref={inputRef}
        validate={composeValidators(required, matchIbanFormat)}
        name={name}
        label={label}
      />
    );
  }

  return (
    <SelectField.FormField
      validate={required}
      name={name}
      label={label}
      options={[
        ...options,
        { label: t("funnel.payment_method_picker.add_new"), value: "add_new" },
      ]}
    />
  );
};

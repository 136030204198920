import { Button as MaterialButton, styled } from "@mui/material";

export const StyledButton = styled(MaterialButton)(({ theme }) => ({
  borderRadius: "8px",
}));

export const Button = ({
  variant = "primary",
  size = "normal",
  children,
  ...props
}) => (
  <StyledButton disableElevation variant={variant} size={size} {...props}>
    {children}
  </StyledButton>
);

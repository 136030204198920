const DashedArrow = ({
  width = "171",
  height = "186",
  minX = 0,
  minY = 0,
  rotate = 0,
}) => (
  <svg
    width={width}
    height={height}
    viewBox={`${minX} ${minY} ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    transform={`rotate(${rotate})`}
  >
    <path
      d="M135.29 171.11C12.1165 96.0717 41.1575 9.41979 51.692 2.27117"
      stroke="black"
      strokeWidth="3"
      strokeLinecap="round"
      strokeDasharray="6 6"
    />
    <path
      d="M124.243 138.871C125.697 144.349 126.475 149.818 129.16 154.933C130.361 157.22 136.956 170.273 132.951 170.893C126.798 171.846 119.851 170.42 113.634 169.738C110.396 169.382 107.06 170.169 104.951 167.449"
      stroke="black"
      strokeWidth="3"
      strokeLinecap="round"
    />
  </svg>
);
const SolidArrow = ({
  width = "171",
  height = "186",
  minX = 0,
  minY = 0,
  rotate = 0,
}) => (
  <svg
    width={width}
    height={height}
    viewBox={`${minX} ${minY} ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    transform={`rotate(${rotate})`}
  >
    <path
      d="M135.29 171.11C12.1165 96.0717 41.1575 9.41979 51.692 2.27117"
      stroke="black"
      strokeWidth="3"
      strokeLinecap="round"
    />
    <path
      d="M124.243 138.871C125.697 144.349 126.475 149.818 129.16 154.933C130.361 157.22 136.956 170.273 132.951 170.893C126.798 171.846 119.851 170.42 113.634 169.738C110.396 169.382 107.06 170.169 104.951 167.449"
      stroke="black"
      strokeWidth="3"
      strokeLinecap="round"
    />
  </svg>
);
export const LargeArrowCurve = ({
  variant = "solid",
  width,
  height,
  minX,
  minY,
  rotate,
}) =>
  variant === "solid" ? (
    <SolidArrow
      width={width}
      height={height}
      minX={minX}
      minY={minY}
      rotate={rotate}
    />
  ) : (
    <DashedArrow
      width={width}
      height={height}
      minX={minX}
      minY={minY}
      rotate={rotate}
    />
  );

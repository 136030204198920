import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export const MinutesContent = ({ minutes, ...props }) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{ textAlign: "center", cursor: "pointer", width: "100%" }}
      {...props}
    >
      <Typography variant="link_button_small">{minutes}</Typography>
      <br />
      <Typography variant="link_button_small">
        {t("common.minutes.minutes")}
      </Typography>
    </Box>
  );
};

import { styled } from "@mui/material/styles";
const BaseIcon = styled("span")(({ theme }) => ({
  "borderRadius": 4,
  "border": `1px solid ${theme.palette.grey[900]}`,
  "background": theme.palette.grey[50],
  "width": 24,
  "height": 24,
  "input:disabled ~ &": { border: `1px solid ${theme.palette.grey[400]}` },
}));

export const Unchecked = () => <BaseIcon />;
export const Checked = () => {
  const CheckedIcon = styled(BaseIcon)(({ theme }) => ({
    "backgroundImage":
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24'%3E" +
      "%3Crect x='5' y='5' width='14' height='14' rx='2' fill='black'/%3E" +
      '%3C/svg%3E")',
    "input:disabled ~ &": {
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24'%3E" +
        `%3Crect x='5' y='5' width='14' height='14' rx='2' fill='${theme.palette.grey[400].replace(
          "#",
          "%23"
        )}'/%3E` +
        '%3C/svg%3E")',
    },
  }));
  return <CheckedIcon />;
};
export const Indeterminate = () => {
  const IndeterminateIcon = styled(BaseIcon)(({ theme }) => ({
    "backgroundImage":
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24'%3E" +
      "%3Crect x='6' y='10' width='12' height='4' rx='2' fill='black'/%3E" +
      '%3C/svg%3E")',
    "input:disabled ~ &": {
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24'%3E" +
        `%3Crect x='6' y='10' width='12' height='4' rx='2' fill='${theme.palette.grey[400].replace(
          "#",
          "%23"
        )}'/%3E` +
        '%3C/svg%3E")',
    },
  }));
  return <IndeterminateIcon />;
};

import React, { useState } from "react";
import { Box } from "@mui/material";

export const Image = ({ alt, src, sx, ...props }) => {
  const [imgSrc, setImgSrc] = useState(src);
  return (
    <Box
      component="img"
      alt={alt}
      src={imgSrc}
      onError={() => setImgSrc("/img/defect.png")}
      sx={{
        position: "relative",
        top: "50%",
        left: "50%",
        transform: "translateX(-50%) translateY(-50%)",
        ...sx,
      }}
      {...props}
    />
  );
};

import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import Alert from "@mui/material/Alert";

import { updateAssetDataPercentage } from "lib/api/assets";
import { formatDataPlanSize } from "lib/helpers/format";
import { useAssets } from "hooks/queries/useAssets";
import { Spinner } from "components/Spinner";
import { Text } from "ancient/components/Text";
import { Card } from "ancient/components/Card";
import { Button } from "ancient/components/Button";
import { Tiles } from "components/layouts/Tiles";

import { ModalLimitData } from "./ModalLimitData";
import { LogModal } from "./LogModal";

const useStyles = makeStyles((theme) => ({
  productIcon: {
    color: theme.palette.primary.main,
    fontSize: theme.spacing(10),
  },
}));

const Advice = () => {
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography variant="subtitle2" align="inherit">
        {t("share_data.limit_data.advice")}
      </Typography>
    </Box>
  );
};
const SharedMobileLines = ({ sharingContracts }) => {
  const { t } = useTranslation();
  const styles = useStyles();
  const { boundId } = useParams();

  const [currentMobileLine, setCurrentMobileLine] = useState(null);
  const [log, showLog] = useState(null);

  const boundMobileContracts =
    sharingContracts.find((item) => item.boundId === boundId)?.mobile || [];
  const assetsRefs = boundMobileContracts.map((contract) => contract.asset_ref);

  const {
    data: assets,
    isLoading,
    isError,
  } = useAssets({ id: boundId, refs: assetsRefs });
  const [isWaitingPatch, setIsWaitingPatch] = useState(false);
  const currentLineLimit = useMemo(() => {
    if (isLoading || currentMobileLine == null) return null;
    return assets.find(
      (asset) => asset.phone_number === currentMobileLine.phone_number
    ).shared_consumption_percentage;
  }, [currentMobileLine, assets]);

  const setNewDataLimit = (limit) => {
    const dry = currentLineLimit === limit;
    setCurrentMobileLine(null);
    if (dry) return;

    setIsWaitingPatch(true);

    const assetId = assets.find(
      (asset) => asset.phone_number === currentMobileLine.phone_number
    ).id;

    updateAssetDataPercentage(assetId, limit)
      .then(() => {
        const index = assets.findIndex((asset) => asset.id === assetId);
        showLog({
          message: "limit_applied",
          severity: "success",
          data: assets[index],
        });
      })
      .catch(() => {
        const index = assets.findIndex((asset) => asset.id === assetId);
        showLog({
          message: "limit_applied",
          severity: "error",
          data: assets[index],
        });
      })
      .finally(() => setIsWaitingPatch(false));
  };

  const formatDataLimit = (val) => {
    if (val === 100) {
      return t("share_data.limit_data.data_shared_none");
    } else {
      return t("share_data.limit_data.limit_to", {
        bytes: formatDataPlanSize(Math.round((val * 51200) / 100)),
      });
    }
  };

  if (isLoading || isWaitingPatch) {
    return (
      <>
        <Box mt={2}>
          <Spinner />
        </Box>
      </>
    );
  }

  if (isError) {
    return <Alert severity="error">{t("common.errors.request_failed")}</Alert>;
  }

  return (
    <>
      <Typography variant="h6" align="inherit">
        {t("share_data.limit_data.data_amount")}
      </Typography>
      {boundMobileContracts.map((item, i) => {
        return (
          <Box>
            <Card variant="noGutter" bgcolor="white">
              <Tiles columns={2}>
                <Box display="flex" p={3}>
                  <PhoneAndroidIcon className={styles.productIcon} />
                  <Box ml={2}>
                    <Box>
                      <Text size="lg" color="black">
                        {t("common.line") + " " + item.phone_number}
                      </Text>
                    </Box>
                    <Text size="sm" color="black">
                      {t("share_data.limit_data.data_shared_subtitle") + ":"}
                    </Text>
                    <Box>
                      <Text size="md" color="black" semibold>
                        {formatDataLimit(
                          assets.find(
                            (asset) => asset.phone_number === item.phone_number
                          )?.shared_consumption_percentage
                        )}
                      </Text>
                    </Box>
                  </Box>
                </Box>
                <Box
                  p={3}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <Tiles columns={2}>
                    <Box>{/* Slot for "stop sharing" button */}</Box>
                    <Box>
                      <Button
                        fullWidth="false"
                        onClick={() => setCurrentMobileLine(item)}
                      >
                        {t("common.edit")}
                      </Button>
                    </Box>
                  </Tiles>
                </Box>
              </Tiles>
            </Card>
          </Box>
        );
      })}
      {currentMobileLine && (
        <ModalLimitData
          currentLineLimit={currentLineLimit}
          isOpen={currentMobileLine != null}
          onClose={() => setCurrentMobileLine(null)}
          onConfirm={setNewDataLimit}
          mobileLine={currentMobileLine}
        />
      )}
      {log && (
        <LogModal isOpen={log != null} onClose={() => showLog(null)} {...log} />
      )}
    </>
  );
};

export const LimitData = ({ sharingContracts }) => {
  return (
    <>
      <Advice />
      <SharedMobileLines sharingContracts={sharingContracts} />
    </>
  );
};

import { Box } from "@mui/material";
import React from "react";
import cn from "classnames";

const scale = {
  "2xs": "0.625rem",
  "xs": "0.75rem",
  "sm": "0.8125rem",
  "md": "1rem",
  "lg": "1.25rem",
  "xl": "1.5rem",
  "2xl": "2.0rem",
  "3xl": "3.25rem",
};

export const Text = ({
  size = "md",
  bold = false,
  semibold = false,
  underline = false,
  lineThrough = false,
  color = "text.main",
  textAlign,
  paragraph = false,
  uppercase = false,
  capitalize = false,
  selectable = true,
  lineHeight,
  letterSpacing,
  opacity = 1.0,
  children,
  onClick,
}) => (
  <Box
    component={paragraph ? "p" : "span"}
    // gutterBottom={false} is needed ?
    color={color}
    onClick={onClick}
    sx={{
      letterSpacing:
        letterSpacing ||
        cn(size === "3xl" && "-0.05rem", size === "2xs" && "1px"),
      textDecoration: underline
        ? "underline"
        : lineThrough
        ? "line-through"
        : "none",
      cursor: Boolean(onClick) ? "pointer" : "inherit",
      fontFamily: "Work Sans",
      lineHeight: lineHeight,
      fontSize: scale[size],
      textTransform: uppercase
        ? "uppercase"
        : capitalize
        ? "capitalize"
        : "none",
      userSelect: selectable ? "auto" : "none",
      opacity: opacity,
      textAlign: textAlign,
      fontWeight: bold && "bold" || semibold && "600" || "normal"
    }}
    tyle={{ letterSpacing: letterSpacing }}
  >
    {children}
  </Box>
);

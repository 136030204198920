import { Typography } from "@mui/material";
import { Button } from "components/Button";
import AddIcon from "@mui/icons-material/Add";

export const AddButton = ({ text, ...props }) => (
  <Button
    sx={(theme) => ({
      "textTransform": "none",
      "borderRadius": "16px",
      "background": theme.palette.grey[50],
      "border": `1px dashed ${theme.palette.grey[900]}`,
      "&:hover": { background: theme.palette.grey[50] },
    })}
    variant={"outlined"}
    startIcon={<AddIcon />}
    {...props}
  >
    <Typography variant={"link"}>{text}</Typography>
  </Button>
);

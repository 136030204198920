import { Typography } from "@mui/material";
import { SelectRow } from "components/layouts/SelectRow";
import { PricingSelect } from "components/PricingSelect";
import { MinutesContent } from "./MinutesContent";
import { useTranslation } from "react-i18next";

export const MinutesSelector = ({ minutes, onClick }) => {
  const { t } = useTranslation();
  return (
    <SelectRow
      title={
        <Typography variant="body_bold">
          {t("funnel.tariffs.mobile.with_these_minutes")}
        </Typography>
      }
    >
      {minutes.map((minutes) => (
        <PricingSelect
          content={
            <MinutesContent
              minutes={minutes.minutes}
              onClick={() => onClick(minutes)}
            />
          }
          isSelected={minutes.isSelected}
          key={minutes.minutes}
        />
      ))}
    </SelectRow>
  );
};

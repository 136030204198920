import { Stack, styled } from "@mui/material";
/**
 * https://github.com/mui/material-ui/issues/13294#issuecomment-1528776876
 */
const StepsContainer = styled(Stack)({
  backgroundColor: "transparent",
  justifyContent: "center",
  flexDirection: "row",
  gap: 5,
});
const Step = styled("div")(({ theme, active }) => ({
  height: 10,
  width: active ? 20 : 10,
  borderRadius: active ? 8 : "50%",
  backgroundColor: active ? theme.palette.grey[900] : theme.palette.grey[200],
  cursor: "pointer",
  transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
}));

export const DotsStepper = ({ activeStep, steps, onStepClick }) => (
  <StepsContainer>
    {Array.from(Array(steps).keys()).map((step) => (
      <Step
        key={step}
        onClick={() => onStepClick(step)}
        active={activeStep === step}
      />
    ))}
  </StepsContainer>
);

import { getCategories, getCategory, getPage, search } from "lib/api/faqs";
import { useQuery } from "react-query";

const DEFAULTS = { enabled: true };
export function useCategories({ parentId, enabled } = DEFAULTS) {
  return useQuery("knowledge/categories", () => getCategories(parentId), {
    enabled,
  });
}

export function useCategory({ categoryId, enabled } = DEFAULTS) {
  return useQuery(
    `knowledge/categories/${categoryId}`,
    () => getCategory(categoryId),
    {
      enabled,
    }
  );
}

export function useFaqsPage({ pageId, enabled } = DEFAULTS) {
  return useQuery(`knowledge/pages/${pageId}`, () => getPage(pageId), {
    enabled,
  });
}

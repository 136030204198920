import { noop } from "lib/fn/noop";
import { Tiles } from "../../components/layouts/Tiles";
import {
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Button } from "./Button";
import { Tooltip } from "./Tooltip";
import React, { useEffect, useState } from "react";
import { InfoIcon } from "../../components/icons/InfoIcon";
import { useTranslation } from "react-i18next";
import { Text } from "./Text";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { IconButton } from "./IconButton";
import { theme } from "theme";
import { ImgMiniature } from "./ImgMiniature";

export const AttachFiles = ({
  getTitle = noop,
  setEncodedFiles = noop,
  maxFiles = Infinity,
  maxSize = Infinity,
  info,
  id,
  ...props
}) => {
  const useStyles = makeStyles({
    input: {
      display: "none",
    },
  });
  const classes = useStyles();
  const { t } = useTranslation();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const deletefile = (file) => {
    const copyUploadedFiles = [...uploadedFiles];
    copyUploadedFiles.splice(
      uploadedFiles.map((file) => file.code).indexOf(file.code),
      1
    );
    setUploadedFiles(copyUploadedFiles);
  };
  const handleFileUpload = (event) => {
    const files = Array.from(event.target.files).map((file) => ({
      file,
      code: `file_${Math.random() * 10000}`,
      miniature: URL.createObjectURL(file),
    }));
    const copyUploadedFiles = [...uploadedFiles];
    copyUploadedFiles.push(...files.slice(0, maxFiles - uploadedFiles.length));
    setUploadedFiles(copyUploadedFiles);
  };
  useEffect(() => {
    setIsButtonDisabled(uploadedFiles.length >= maxFiles);
    const data = [];
    setEncodedFiles(data);
    uploadedFiles.forEach(({ file }) => {
      const reader = new FileReader();
      const file_name = file.name;
      const file_type = file.type;
      reader.addEventListener("load", () => {
        data.push({
          /**
           * Fix: https://trello.com/c/3bnwtYyK
           * content: reader.result.replace(/^data:image\/[a-z]+;base64,/, ""),
           */
          content: reader.result.replace(`data:${file_type};base64,`, ""),
          miniature: URL.createObjectURL(file),
          file_name,
          file_type,
        });
        setEncodedFiles(data);
      });
      reader.readAsDataURL(file);
    });
  }, [uploadedFiles]);
  return (
    <>
      {getTitle()}
      <Tiles columns={2}>
        <input
          className={classes.input}
          type="file"
          onChange={handleFileUpload}
          id={id}
          disabled={isButtonDisabled}
          {...props}
        />
        <label htmlFor={id}>
          <Button disabled={isButtonDisabled} component="span">
            {t("common.atttach_file")}
          </Button>
        </label>
        {info && (
          <Box display="flex" alignItems="center" justifyContent="flex-start">
            <Tooltip
              variant="info"
              interactive
              enterTouchDelay={0}
              leaveTouchDelay={0}
              PopperProps={{
                disablePortal: true,
              }}
              title={
                <React.Fragment>
                  <div>{info}</div>
                </React.Fragment>
              }
              arrow
              placement="right"
            >
              <div>
                <InfoIcon />
              </div>
            </Tooltip>
          </Box>
        )}
      </Tiles>
      <List>
        {uploadedFiles.map((file) => (
          <ListItem key={file.code}>
            <ListItemAvatar>
              <ImgMiniature miniature={file.miniature} />
            </ListItemAvatar>
            <ListItemText
              primary={<Text>{file.file.name}</Text>}
              secondary={
                <Text size="sm">{`${file.file.type} - ${(
                  file.file.size / 1024
                ).toFixed(2)} KB`}</Text>
              }
            />
            <ListItemSecondaryAction>
              <IconButton onClick={() => deletefile(file)} size="large">
                <DeleteForeverIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </>
  );
};

export const openUrl = (url, target = "_parent") => {
  if (process.env.REACT_APP_FORM_BUILD) {
    url =
      new URL(window.axios.defaults.baseURL).origin +
      "/" +
      url.replace(/^\//, "");
    // If
    // const hash = "#/" + url.replace(/^\//, "");
    // const state = {
    //   _wpReactNavigation: true,
    //   from: window.location.hash,
    //   to: hash,
    // };
    // window.history.pushState(state, "", hash);
    // window.location.reload();
  }
  if (window.Cypress) {
    window.location = url;
  } else {
    window.open(url, target);
  }
};

export const getUrlQuery = (url, queryParams = {}) => {
  const searchParams = Object.keys(queryParams)
    .map((key) => {
      return queryParams[key]
        ? `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`
        : null;
    })
    .filter((d) => d)
    .join("&");

  if (searchParams.length === 0) return url;
  else return `${url}?${searchParams}`;
};

export const getLoginUrl = (
  lines = [],
  isSharedLines = false,
  isPromotionActive = false
) => {
  const host =
    process.env.REACT_APP_FORM_BUILD === "1"
      ? new URL(window.axios.defaults.baseURL).origin
      : window.location.origin;
  return `${host}/user/signup?redirect_to=${encodeURIComponent(
    getSignupUrl(
      lines,
      undefined,
      undefined,
      true,
      isSharedLines,
      false,
      isPromotionActive
    )
  )}`;
};

export const getSignupUrl = (
  lines = [],
  optingForRole = undefined,
  coopAgreementCode = undefined,
  isNewSignupFlow = true,
  isSharedLines = false,
  utm_campaign = false,
  isPromotionActive = false
) => {
  const serializedIntent = JSON.stringify({
    isSharedLines,
    isPromotionActive,
    optingForRole,
    isNewSignupFlow,
    coopAgreementCode,
    lines,
  });

  return propagateLocaleParam(
    `/signup/data?intent=${encodeURIComponent(
      serializedIntent
    )}&utm_campaign=${utm_campaign}`
  );
};

export const getJoinUrl = (
  preferredLocale = "ca",
  optingForRole = undefined,
  coopAgreementCode = undefined
) => {
  const protocolSomOffice = window.location.protocol;
  const hostSomOffice = window.location.hostname;
  const portSomOffice = hostSomOffice.startsWith("local") ? ":3000" : "";
  const role = optingForRole ? `&opting_for_role=${optingForRole}` : "",
    agreementCode =
      optingForRole && coopAgreementCode
        ? `&coop_agreement_code=${coopAgreementCode}`
        : "";

  return `${protocolSomOffice}//${hostSomOffice}${portSomOffice}/join?locale=${preferredLocale}${role}${agreementCode}`;
};

/**
 * If locale is set int the url, we need to propagate to the destination url (if
 * it is a relative url)
 */
export const propagateLocaleParam = (to) => {
  const isExternalLink = Boolean(to.match(/^https?:\/\/|^mailto:/));

  let currentQuery;
  if (process.env.REACT_APP_FORM_BUILD === "1") {
    [, currentQuery] = window.location.hash.split("?");
  } else {
    currentQuery = window.location.search;
  }

  const localeFromUrl = new URLSearchParams(currentQuery).get("locale");

  if (isExternalLink || !localeFromUrl) return to;

  const [, queryString] = to.split("?");
  const params = new URLSearchParams(queryString);
  params.set("locale", localeFromUrl);

  return to.replace(/\?.*$/, `?${params.toString()}`);
};

export function typeToCollection(type) {
  if (type === "personalized") {
    return [{ type: "internet" }, { type: "mobile" }];
  }

  return [{ type }];
}

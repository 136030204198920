const DashedArrow = ({
  width = "102",
  height = "51",
  minX = 0,
  minY = 0,
  rotate = 0,
}) => (
  <svg
    width={width}
    height={height}
    viewBox={`${minX} ${minY} 102 51`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    transform={`rotate(${rotate})`}
  >
    <path
      d="M99.939 28.4168C31.1784 61.9808 2.18544 17.8279 2.18893 10.2456"
      stroke="black"
      strokeWidth="3"
      strokeLinecap="round"
      strokeDasharray="6 6"
    />
    <path
      d="M82.7214 22.5679C85.5223 23.7665 88.1207 25.2894 91.127 25.7662C92.4711 25.9794 100.067 27.3198 99.1569 29.474C97.7588 32.7834 95.0967 35.6511 92.9711 38.4237C91.864 39.8678 91.223 41.7557 89.4242 41.8319"
      stroke="black"
      strokeWidth="3"
      strokeLinecap="round"
    />
  </svg>
);
const SolidArrow = ({
  width = "102",
  height = "51",
  minX = 0,
  minY = 0,
  rotate = 0,
}) => (
  <svg
    width={width}
    height={height}
    viewBox={`${minX} ${minY} 102 51`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    transform={`rotate(${rotate})`}
  >
    <path
      d="M99.9127 28.4168C31.152 61.9808 2.15907 17.8279 2.16256 10.2456"
      stroke="black"
      strokeWidth="3"
      strokeLinecap="round"
    />
    <path
      d="M82.695 22.5679C85.4959 23.7665 88.0944 25.2894 91.1006 25.7662C92.4447 25.9794 100.041 27.3198 99.1306 29.474C97.7324 32.7834 95.0703 35.6511 92.9447 38.4237C91.8377 39.8678 91.1966 41.7557 89.3978 41.8319"
      stroke="black"
      strokeWidth="3"
      strokeLinecap="round"
    />
  </svg>
);
export const ArrowCurve = ({
  variant = "solid",
  width,
  height,
  minX,
  minY,
  rotate,
}) =>
  variant === "solid" ? (
    <SolidArrow
      width={width}
      height={height}
      minX={minX}
      minY={minY}
      rotate={rotate}
    />
  ) : (
    <DashedArrow
      width={width}
      height={height}
      minX={minX}
      minY={minY}
      rotate={rotate}
    />
  );

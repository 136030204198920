import React from "react";
import MaterialButton from "@mui/material/Button";
import { theme } from "../../theme";


/**
 * Preconfigured Material UI Button for consistency sake
 */

export const Button = ({
  type = "button",
  onClick,
  children,
  fullWidth = true,
  variant = "contained",
  color = "primary",
  disabled = false,
  form,
  component = "button",
}) => (
  <MaterialButton
    fullWidth={fullWidth}
    variant={variant}
    color={color}
    type={type}
    onClick={onClick}
    disabled={disabled}
    form={form}
    component={component}
    sx={[
      {
        height: 55,
        marginBottom: 0,
        boxShadow: "none",
        fontSize: 16,
        letterSpacing: "1px",
        fontWeight: "bold",
      },
      {
        "&:hover": {
          boxShadow: "none",
        },
      },
      {
        "&.Mui-disabled": {
          backgroundColor: `${theme.palette.primary.main} !important`,
          color: "white !important",
          opacity: 0.5,
        },
      },
    ]}
  >
    {children}
  </MaterialButton>
);

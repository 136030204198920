import React from "react";
import { Stack } from "ancient/components/layouts/Stack";
import { Button } from "ancient/components/Button";
import { useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import { useRequestChangeContext } from "../context";
import { useTranslation } from "react-i18next";

export const SelectScope = () => {
  const history = useHistory();
  const { setScope, setSelectedSubscriptions, subscriptions } = useRequestChangeContext();
  const { t } = useTranslation();

  return (
    <>
      <strong>{t("request_change.select_scope.title")}</strong>
      <div>{t("request_change.select_scope.subtitle")}</div>
      <Box pl={4} mt={2}>
        <Stack spacing={2}>
          <Button
            fullWidth={false}
            onClick={() => {
              setScope("all");
              setSelectedSubscriptions(subscriptions.map(sub => sub.code));
              history.push("select-topic");
            }}
          >
            {t("request_change.select_scope.all_contracts")}
          </Button>
          <Button
            fullWidth={false}
            onClick={() => {
              setScope("some");
              history.push("select-subscriptions");
            }}
          >
            {t("request_change.select_scope.some_contracts")}
          </Button>
        </Stack>
      </Box>
    </>
  );
};

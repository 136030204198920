import React, { useState } from "react";

import { Box, Typography } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import { useTranslation } from "react-i18next";
import { LowerDialog } from "components/icons/Dialogs";
import { ModalWeCallYou } from "components/modals/WeCallYou";

export const DialogHelp = ({
  width = "189px",
  height = "152px",
  sx,
  ...props
}) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <>
      <ModalWeCallYou
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        {...props}
      />
      <Box sx={{ position: "relative", width: width, height: height, ...sx }}>
        <Box
          sx={{
            position: "relative",
            top: "52px",
            left: "27px",
            zIndex: "100",
          }}
        >
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Rock Salt",
                fontSize: "18px",
                fontWeight: "400",
                lineHeight: "38px",
                letterSpacing: "-0.36px",
              }}
            >
              {t("help_we_call_you.help_question")}
            </Typography>
            <PhoneIcon
              sx={{
                width: "2rem",
                height: "2rem",
                mt: "4px",
              }}
            />
          </Box>
        </Box>
        <Box sx={{ position: "relative", bottom: "38px", zIndex: "50" }}>
          <LowerDialog />
        </Box>
        <Box
          sx={{
            position: "relative",
            left: "27px",
            bottom: "100px",
            zIndex: "150",
            cursor: "pointer",
          }}
          onClick={() => setIsModalOpen(true)}
        >
          <Typography variant="link">
            {t("help_we_call_you.we_call_you")}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { BrowserRouter as Router } from "react-router-dom";

const FORMS_BUILD = process.env.REACT_APP_FORM_BUILD;
const IS_DEV = process.env.NODE_ENV === "development";

if (!FORMS_BUILD) {
  const { default: App } = require("./App");
  ReactDOM.render(
    <Router>
      <App />
    </Router>,
    document.getElementById("root")
  );
} else {
  const { default: setupForms } = require("./Forms");
  const startApp = setupForms();

  if (IS_DEV) {
    startApp({
      el: document.getElementById("root"),
      attributes: {
        component: window.location.hash,
      },
    });
  } else if (window.wpReact) {
    window.wpReact.startApp = startApp;
  }
}

import { Typography } from "@mui/material";
import { SelectRow } from "components/layouts/SelectRow";
import { PricingSelect } from "components/PricingSelect";
import { useTranslation } from "react-i18next";
import { BandwidthContent } from "../internet/BandwidthContent";

export const BandwithSelector = ({
  onClick,
  bandwidths,
  bandwidthAction,
  tech,
}) => {
  const { t } = useTranslation();
  function getBandwidthAction(bandwidth, onClickAction) {
    if (bandwidthAction) {
      return bandwidthAction(tech, bandwidth, onClickAction);
    }
    return bandwidthAction;
  }
  return (
    <SelectRow
      title={
        <Typography variant="body_bold">
          {t("funnel.tariffs.internet.what_speed")}
        </Typography>
      }
    >
      {bandwidths.map((bandwidth) => (
        <PricingSelect
          isArrowed
          isSelected={bandwidth.isSelected}
          content={
            <BandwidthContent
              bandwidth={bandwidth.bandwidth}
              onClick={() => onClick(bandwidth)}
            />
          }
          key={bandwidth.bandwidth}
          action={getBandwidthAction(bandwidth.bandwidth, () =>
            onClick(bandwidth)
          )}
        />
      ))}
    </SelectRow>
  );
};

import { Button } from "components/Button";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { FloatingCard } from "components/layouts/FloatingCard";

export const AddMobileButton = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <FloatingCard>
      <Button
        variant="secondary"
        onClick={onClick}
        startIcon={<AddIcon />}
        fullWidth
        sx={{ textTransform: "none" }}
      >
        <Typography variant="link_button">
          {t("funnel.tariffs.mobile.add_line")}
        </Typography>
      </Button>
    </FloatingCard>
  );
};

import { Box, Typography, useMediaQuery } from "@mui/material";
import { SuperCard } from "components/SuperCard";
import { WLandlineChip, WOPermanenceChip } from "components/Chips";
import { LargeArrowCurve } from "components/icons/LargeArrowCurve";
import { DialogHelp } from "components/dialogs/DialogHelp";
import { FloatingCard } from "components/layouts/FloatingCard";

export const CardInternetTariffInfo = ({
  content,
  hasLandLine,
  isInfoArrowed = true,
}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <FloatingCard
      sx={{
        mt: 1,
        display: "grid",
        gridTemplateRows: "100% 100%",
        gridTemplateColumns: "40% 60%",
      }}
    >
      <SuperCard sx={{ mt: 1, gridRow: "1", gridColumn: "1 / 3" }}>
        <Box sx={{ display: "flex", alignItems: "flex-start", gap: "4px" }}>
          {hasLandLine && <WLandlineChip />}
          <WOPermanenceChip />
        </Box>
        <Typography sx={{ mt: "16px" }} variant="body_small">
          {content}
        </Typography>
      </SuperCard>
      {isInfoArrowed && (
        <>
          <Box
            sx={{
              position: "relative",
              left: isMobile ? "2%" : "40%",
              gridRow: "2",
              gridColumn: "1",
            }}
          >
            <LargeArrowCurve variant="dashed" />
          </Box>
          <Box
            sx={{
              position: "relative",
              left: isMobile ? "0%" : "25%",
              bottom: "8%",
              gridRow: "2",
              gridColumn: "2",
            }}
          >
            <DialogHelp origin="tariffs" />
          </Box>
        </>
      )}
    </FloatingCard>
  );
};

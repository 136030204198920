import { SavingChip, WOPermanenceChip } from "components/Chips";
import { Price } from "../PricePreview";
import { Box, Typography } from "@mui/material";
import { Divider } from "components/Divider";
import { useTranslation } from "react-i18next";

export const MobileTariffInfo = ({ tariff }) => {
  const { t } = useTranslation();
  const isOffer = tariff?.is_offer_tariff;
  function getOfferDescription() {
    const minutes = tariff.minutes;
    const data = tariff.data / 1024;
    return `${
      minutes < 99999 ? minutes + " min" : t("common.unlimited")
    } + ${data}GB`;
  }
  return (
    <>
      <Divider sx={{ mt: 1 }} flexItem />
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          width: "100%",
          pt: 1,
          gap: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: 1,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "flex-start", gap: "4px" }}>
            <Typography variant="body">
              {t("price_tag.category.mobile")}
            </Typography>
            <Typography variant="body_bold">{getOfferDescription()}</Typography>
          </Box>
          {isOffer && (
            <Box sx={{ display: "flex", gap: "4px" }}>
              <SavingChip
                saving={Math.floor(
                  ((tariff.original_price - tariff.price) /
                    tariff.original_price) *
                    100
                )}
              />
              <Box sx={{ textDecoration: "line-through", color: "grey.700" }}>
                <Typography variant="body_large">
                  {t("common.before")}
                </Typography>
                <Typography variant="body_large_bold">
                  {` ${tariff.original_price}€`}
                </Typography>
              </Box>
            </Box>
          )}
          <WOPermanenceChip />
        </Box>
        <Box sx={{ pt: 1 }}>
          <Price
            price={parseFloat(tariff.price).toFixed(2)}
            stackingPrice={true}
          />
        </Box>
      </Box>
      <Typography sx={{ mt: 1 }} variant="body_small">
        TODO literals for each tariff: Esta tarifa es ideal para recibir
        llamadas y limitar el uso de datos. Por ejemplo, para menores de edad.
      </Typography>
    </>
  );
};

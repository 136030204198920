import { Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import React from "react";

const StyledTypography = withStyles({
  root: {
    fontSize: 28,
    color: 'rgba(62, 51, 130, 1)',
    fontWeight: 'bold',
  }
})(Typography);

export const Heading = ({ gutterBottom = false, ...props }) => (
  <StyledTypography component="h1" gutterBottom={gutterBottom} {...props} />
);

import { Divider as MaterialDivider } from "@mui/material";
export const Divider = ({ sx, ...props }) => (
  <MaterialDivider
    sx={{
      opacity: 0.4,
      borderStyle: "dashed",
      borderColor: "grey.900",
      ...sx,
    }}
    {...props}
  />
);

import { Box, Card } from "@mui/material";
import Alert from "@mui/material/Alert";
import { Button } from "ancient/components/Button";
import { Heading } from "ancient/components/Heading";
import { Stack } from "ancient/components/layouts/Stack";
import { Spinner } from "components/Spinner";
import { LegacyTextField } from "ancient/components/LegacyTextField";
import { changePassword } from "lib/api/profile";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export const ChangePassword = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("");
  const [errors, setErrors] = useState([]);
  const [isSuccess, setIsSuccess] = useState(null);
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(null);

  const clearForm = () => {
    setCurrentPassword("");
    setNewPassword("");
    setNewPasswordConfirmation("");
  };

  const onSubmit = async () => {
    if (newPassword !== newPasswordConfirmation) {
      setErrors([t("password_reset.errors.passwords_do_not_match")]);
      return;
    }

    setIsLoading(true);
    try {
      await changePassword({ currentPassword, newPassword });
      setErrors([]);
      setIsSuccess(true);
      clearForm();
    } catch (e) {
      const responseErrors = e.response.data.errors || [
        t("common.errors.request_failed"),
      ];
      setErrors(responseErrors);
      setIsSuccess(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box component={Card} p={4}>
      {isLoading && <Spinner />}
      {!isLoading && (
        <form onSubmit={onSubmit}>
          <Stack spacing={2}>
            <Heading>{t("password_reset.change_password")}</Heading>
            <LegacyTextField
              type="password"
              label={t("password_reset.current_password_hint")}
              value={currentPassword}
              setState={setCurrentPassword}
            />
            <LegacyTextField
              type="password"
              label={t("password_reset.new_password_hint")}
              value={newPassword}
              setState={setNewPassword}
            />
            <LegacyTextField
              type="password"
              label={t("password_reset.repeat_password_hint")}
              value={newPasswordConfirmation}
              setState={setNewPasswordConfirmation}
            />
            {errors.map((error, index) => (
              <Box key={index} width="100%">
                <Alert severity="error">{error}</Alert>
              </Box>
            ))}
            {isSuccess && (
              <Box width="100%">
                <Alert severity="success">{t("password_reset.complete")}</Alert>
              </Box>
            )}
            <Button onClick={onSubmit} type="submit">
              {t("common.confirm")}
            </Button>
          </Stack>
        </form>
      )}
    </Box>
  );
};

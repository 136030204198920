import React, { useCallback, useEffect, useState } from "react";
import { SidebarLayout } from "ancient/components/layouts/SidebarLayout";
import { Spinner } from "components/Spinner";
import {
  Switch,
  Route,
  useRouteMatch,
  Redirect,
  useHistory,
} from "react-router-dom";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { RequestChangeContext, useRequestChangeContext } from "./context";
import { postRequestChange } from "lib/api/tickets";
import Alert from "@mui/material/Alert";

import { Trans, useTranslation } from "react-i18next";
import { Link } from "components/Link";
import { Stack } from "ancient/components/layouts/Stack";

import { SelectScope } from "./steps/SelectScope";
import { SelectSubscriptions } from "./steps/SelectSubscriptions";
import { SelectTopic } from "./steps/SelectTopic";
import { ChangeIban } from "./steps/ChangeIban";
import { ChangeEmail } from "./steps/ChangeEmail";
import { ChangeOwner } from "./steps/ChangeOwner";
import { useApplicationContext } from "hooks/useApplicationContext";
import { noop } from "lib/fn/noop";
import { useContracts } from "hooks/queries/useContracts";

const ErrorAlert = () => {
  const { t } = useTranslation();
  return <Alert severity="error">{t("common.errors.request_failed")}</Alert>;
};
const SuccessAlert = () => {
  const { topic } = useRequestChangeContext();
  const translationSuffix = topic.includes("owner") ? topic : "default";

  return (
    <Alert severity="success">
      <Trans i18nKey={`request_change.success.${translationSuffix}`} />
    </Alert>
  );
};
const SuccessResult = () => {
  const { t } = useTranslation();
  return (
    <Stack>
      <SuccessAlert />
      <Link to="/">{t("common.back_to_home")}</Link>
    </Stack>
  );
};

const ErrorResult = () => {
  const { t } = useTranslation();
  return (
    <Stack>
      <ErrorAlert />
      <Link to="/request-change">{t("common.start_again")}</Link>
    </Stack>
  );
};

const InitialRedirect = () => {
  const { subscriptions, setWentThroughFirstStep, setConfirmNonce } =
    useRequestChangeContext();
  const { path } = useRouteMatch();
  const { t } = useTranslation();

  useEffect(() => {
    // this is need to prevent weird auto confirmation when navigating to index
    setConfirmNonce(0);
    setWentThroughFirstStep(true);
  }, []);

  if (subscriptions.length <= 0) {
    return <Alert severity="info">{t("request_change.empty_state")}</Alert>;
  }

  if (subscriptions.length > 1) {
    return <Redirect to={`${path}/select-scope`} />;
  }

  return <Redirect to={`${path}/select-topic`} />;
};

const WizardRoute = ({ children, ...rest }) => {
  const { wentThroughFirstStep } = useRequestChangeContext();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        !wentThroughFirstStep ? (
          <Redirect
            to={{ pathName: "/request-change", state: { from: location } }}
          />
        ) : (
          children
        )
      }
    />
  );
};

const Content = ({ subscriptions }) => {
  const { path } = useRouteMatch();
  const [selectedSubscriptions, setSelectedSubscriptions] = useState(
    subscriptions.map((sub) => sub.code)
  );
  const [scope, setScope] = useState("some");
  const [topic, setTopic] = useState(null);
  const [confirmNonce, setConfirmNonce] = useState(0);
  const [documentation, setDocumentation] = useState([]);
  const [newValue, setNewValue] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [wentThroughFirstStep, setWentThroughFirstStep] = useState(false);
  const history = useHistory();
  const { currentUser } = useApplicationContext();

  const onConfirm = useCallback(async () => {
    try {
      setIsLoading(true);
      await postRequestChange({
        topic,
        newValue,
        scope,
        selectedSubscriptions,
        lang: `${currentUser.preferred_locale}_ES`,
        documentation,
      });

      history.push(`${path}/success`);
    } catch (e) {
      history.push(`${path}/error`);
    } finally {
      setIsLoading(false);
    }
  }, [topic, newValue, scope, selectedSubscriptions, documentation]);

  // This is kind of weird
  useEffect(() => {
    if (confirmNonce === 0) {
      return;
    }

    onConfirm();
  }, [confirmNonce, onConfirm]);

  const requestConfirm = () => setConfirmNonce(confirmNonce + 1);

  const contextValue = {
    subscriptions,
    selectedSubscriptions,
    setSelectedSubscriptions,
    scope,
    setScope,
    topic,
    setTopic,
    setNewValue,
    wentThroughFirstStep,
    setWentThroughFirstStep,
    setConfirmNonce,
    requestConfirm,
    onConfirm,
    documentation,
    setDocumentation,
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <RequestChangeContext.Provider value={contextValue}>
      <Switch>
        <Route exact path={path}>
          <InitialRedirect />
        </Route>
        <WizardRoute path={`${path}/select-scope`}>
          <SelectScope />
        </WizardRoute>
        <WizardRoute path={`${path}/select-subscriptions`}>
          <SelectSubscriptions />
        </WizardRoute>
        <WizardRoute path={`${path}/select-topic`}>
          <SelectTopic />
        </WizardRoute>
        <WizardRoute path={`${path}/request-change/iban`}>
          <ChangeIban />
        </WizardRoute>
        <WizardRoute path={`${path}/request-change/email`}>
          <ChangeEmail />
        </WizardRoute>
        <WizardRoute path={`${path}/request-change/owner`}>
          <ChangeOwner />
        </WizardRoute>
        <WizardRoute path={`${path}/success`}>
          <SuccessResult />
        </WizardRoute>
        <WizardRoute path={`${path}/error`}>
          <ErrorResult />
        </WizardRoute>
      </Switch>
    </RequestChangeContext.Provider>
  );
};

export const RequestChange = () => {
  const { currentUser } = useApplicationContext();
  const { data: { data: subscriptions } = { data: [] }, isLoading } =
    useContracts({ userName: currentUser.username });

  return (
    <SidebarLayout>
      {isLoading ? (
        <Spinner />
      ) : (
        <Box component={Card} p={[2, 4]}>
          <Content subscriptions={subscriptions} />
        </Box>
      )}
    </SidebarLayout>
  );
};

export const EmbeddedRequestChange = ({ subscription, topic }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [newValue, setNewValue] = useState(null);
  const [isRequestConfirm, setIsRequestConfirm] = useState(false);
  const [documentation, setDocumentation] = useState([]);
  const { currentUser } = useApplicationContext();
  const setTopic = noop;

  const requestConfirm = () => setIsRequestConfirm(true);

  const scope = "some";
  const selectedSubscriptions = [subscription.code];

  const onConfirm = async () => {
    try {
      setIsLoading(true);
      await postRequestChange({
        topic,
        newValue,
        scope,
        selectedSubscriptions,
        lang: `${currentUser.preferred_locale}_ES`,
        documentation,
      });
      setIsSuccess(true);
    } catch (e) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (isRequestConfirm) {
      onConfirm();
    }
  }, [isRequestConfirm]);

  const contextValue = {
    subscriptions: [subscription],
    selectedSubscriptions,
    scope,
    topic,
    setTopic,
    setNewValue,
    requestConfirm,
    documentation,
    setDocumentation,
  };
  return (
    <Box component={Card} p={[2, 4]}>
      <RequestChangeContext.Provider value={contextValue}>
        {(() => {
          if (isError) {
            return <ErrorAlert />;
          } else if (isSuccess) {
            return <SuccessAlert />;
          } else if (isLoading) {
            return <Spinner />;
          } else {
            if (topic === "email") {
              return <ChangeEmail />;
            } else if (topic === "iban") {
              return <ChangeIban />;
            } else if (topic === "owner") {
              return <ChangeOwner />;
            }
          }
        })()}
      </RequestChangeContext.Provider>
    </Box>
  );
};

import { useStore } from "hooks/useStore";
import { useDerivedState } from "hooks/useDerivedState";
import React, { useContext } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { noop } from "lib/fn/noop";
import { FormStepGroupContext } from "./FormStepGroupContext";
import { Form } from "components/Form";
import { Button } from "components/Button";

export const FormStepContent = ({
  id,
  title,
  renderSummary = noop,
  children,
  initialValues,
  onSubmit,
  omitTitleWhenOpen = false,
  isDirty,
}) => {
  const { currentStep, isStepEditable } = useDerivedState();
  const setCurrentStep = useStore((state) => state.setCurrentStep);
  const isOpen = id === currentStep;
  const { t } = useTranslation();
  const { confirmMode } = useContext(FormStepGroupContext);

  if (isOpen) {
    return (
      <Form id={id} initialValues={initialValues} onSubmit={onSubmit}>
        <div data-qa-selector={id} data-form-step-open={isOpen}>
          <div id={`scroll/${id}`} />
          <Box px={[3, 8]} py={5}>
            {!omitTitleWhenOpen && (
              <Box mb={5}>
                <Typography variant="h4">{title}</Typography>
              </Box>
            )}
            {children}
          </Box>
        </div>
      </Form>
    );
  }

  return (
    <div data-qa-selector={id} data-form-step-open={isOpen}>
      <div id={`scroll/${id}`} />
      <Box
        sx={{
          display: "flex",
          alignItems: confirmMode ? "flex-start" : "center",
          justifyContent: "space-between",
          opacity: isDirty ? 1.0 : 0.25,
          padding: "24px",
        }}
      >
        <Box display="flex" flexDirection="column" width={"100%"}>
          {isDirty ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                {confirmMode ? (
                  <Typography variant="body_small_bold">{title}</Typography>
                ) : (
                  <Typography variant="h4">{title}</Typography>
                )}
                {isStepEditable(id, confirmMode) && (
                  <Button
                    variant={"transparent"}
                    size={"small"}
                    sx={{ textTransform: "none" }}
                    onClick={() => setCurrentStep(id)}
                  >
                    {t("common.edit")}
                  </Button>
                )}
              </Box>

              {renderSummary(initialValues)}
            </Box>
          ) : (
            <Typography variant="h4">{title}</Typography>
          )}
        </Box>
      </Box>
    </div>
  );
};

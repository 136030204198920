import { Alert } from "@mui/lab";
import { Box } from "@mui/material";
import { CheckField } from "components/fields/CheckField";
import { InputField } from "components/fields/InputField";
import { RadioToggle } from "components/fields/RadioToggle";
import { SelectField } from "components/fields/SelectField";
import { Tiles, TileSpan } from "components/layouts/Tiles";
import { useApplicationContext } from "hooks/useApplicationContext";
import { useStore } from "hooks/useStore";
import { getAvailableProviders } from "lib/api/availableProviders";
import { previousBroadbandServices } from "lib/domain/somconnexio/selections";
import { noop } from "lib/fn/noop";
import {
  checkPhoneIsHired,
  composeValidators,
  required,
  validLandLinePhone,
  validPhone,
} from "lib/form/validators";
import React from "react";
import { useTranslation } from "react-i18next";
import { AddressPicker } from "../shared/AddressPicker";
import { ApiSelect } from "../shared/ApiSelect";
import { Condition } from "../shared/Condition";
import { PreviousOwnerFields } from "./PreviousOwnerFields";

export const InternetAdditionalData = () => {
  const { t } = useTranslation();
  const isLoggedIn = Boolean(useApplicationContext().currentUser);
  const { internetTechnology, hasLandlinePhone } = useStore((state) => state);

  return (
    <div>
      <Tiles columns={1} spacing={4}>
        <Condition when="already_has_service" is={true}>
          {!hasLandlinePhone && (
            <Alert severity="warning">
              {t(
                `funnel.signup.data.steps.internet_line_additional_data.alert_hasnt_landline_${internetTechnology}`
              )}
            </Alert>
          )}
        </Condition>
        <TileSpan span="all">
          <RadioToggle.FormField
            name="already_has_service"
            leftLabel={t(
              "funnel.signup.data.steps.internet_line_additional_data.already_has_service_yes"
            )}
            leftValue={true}
            rightLabel={t(
              "funnel.signup.data.steps.internet_line_additional_data.already_has_service_no"
            )}
            rightValue={false}
          />
        </TileSpan>
        <Condition when="already_has_service" is={true}>
          {hasLandlinePhone && (
            <Tiles columns={2} spacing={4}>
              <PreviousOwnerFields />
            </Tiles>
          )}
          <ApiSelect
            queryKey="previous_provider_broadband"
            name="previous_provider"
            validate={required}
            label={t(
              "funnel.signup.data.steps.internet_line_additional_data.previous_provider"
            )}
            mapItem={(item) => ({ label: item.name, value: item.id })}
            query={() => getAvailableProviders({ category: "broadband" })}
          />
          <SelectField.FormField
            name="previous_broadband_service"
            validate={required}
            label={t(
              "funnel.signup.data.steps.internet_line_additional_data.previous_broadband_service"
            )}
            i18nPrefix="funnel.selections"
            options={previousBroadbandServices}
          />
          {hasLandlinePhone && (
            <Tiles columns={2}>
              <CheckField.FormField
                initialValue={false}
                name="internet_without_phone"
                label={t(
                  "funnel.signup.data.steps.internet_line_additional_data.internet_without_phone"
                )}
              />
              <Condition when="internet_without_phone" is={false}>
                <InputField.FormField
                  name="phone_number"
                  mask="000 00 00 00"
                  validate={composeValidators(
                    required,
                    validLandLinePhone,
                    isLoggedIn ? checkPhoneIsHired : noop
                  )}
                  label={t(
                    "funnel.signup.data.steps.internet_line_additional_data.phone_number"
                  )}
                />
              </Condition>
            </Tiles>
          )}
        </Condition>
        <TileSpan span="all">
          {isLoggedIn && (
            <>
              <InputField.FormField
                name="contact_phone"
                mask="000 00 00 00"
                validate={composeValidators(required, validPhone)}
                label={t("funnel.signup.data.steps.personal_data.phone")}
              />
              <Box mb={4} />
            </>
          )}
          <AddressPicker
            label={t(
              "funnel.signup.data.steps.internet_line_additional_data.service_address"
            )}
            name="service_address"
          />
        </TileSpan>
      </Tiles>
    </div>
  );
};

import { Box, Radio, useTheme } from "@mui/material";
import { withStyles } from "@mui/styles";
import { Tiles } from "components/layouts/Tiles";
import { Text } from "ancient/components/Text";
import { Field } from "react-final-form";
import { RadioChecked } from "components/icons/RadioChecked";
import { RadioUnChecked } from "components/icons/RadioUnChecked";

const StyledRadio = withStyles((theme) => ({}))(Radio);

const RadioWrapper = ({ isSelected, onClick, radioValue, label }) => {
  const theme = useTheme();

  return (
    <Box
      onClick={onClick}
      px={3}
      display="flex"
      flexDirection="row"
      alignItems="center"
      height={theme.spacing(8)}
      bgcolor={isSelected ? "secondary.main" : "background.light"}
    >
      <Radio
        color="secondary"
        icon={<RadioUnChecked />}
        checkedIcon={<RadioChecked />}
        checked={isSelected}
        value={radioValue}
      />
      <Box style={{ opacity: isSelected ? 1.0 : 0.7 }}>
        <Text>{label}</Text>
      </Box>
    </Box>
  );
};

export const RadioToggle = ({
  value = true,
  onChange,
  name,
  leftValue,
  rightValue,
  leftLabel,
  rightLabel,
}) => {
  return (
    <Box borderRadius="5px" overflow="hidden">
      <Tiles columns={2} spacing={0}>
        <RadioWrapper
          onClick={() => onChange(leftValue)}
          name={name}
          isSelected={value === leftValue}
          label={leftLabel}
        />
        <RadioWrapper
          onClick={() => onChange(rightValue)}
          name={name}
          isSelected={value === rightValue}
          label={rightLabel}
        />
      </Tiles>
    </Box>
  );
};

RadioToggle.FormField = ({ name, validate, initialValue, ...props }) => (
  <Field name={name} validate={validate}>
    {({ input, meta }) => (
      <RadioToggle {...props} value={input.value} onChange={input.onChange} />
    )}
  </Field>
);

import HelpIcon from "@mui/icons-material/Help";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Box,
  Collapse,
  styled,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Stack } from "ancient/components/layouts/Stack";
import { Divider } from "components/Divider";
import { Tiles } from "components/layouts/Tiles";
import { decorateOfferTariffs } from "hooks/queries/helper/tariffDecorator";
import { useDerivedState } from "hooks/useDerivedState";
import { useLinesFormDerivedState } from "hooks/useLinesFormDerivedState";
import { useStore } from "hooks/useStore";
import { formatPrice } from "lib/helpers/format";
import { compact } from "lodash";
import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

const Root = styled("div")(({ theme }) => ({
  position: "fixed",
  background: theme.palette.grey[50],
  [theme.breakpoints.down("md")]: {
    top: 70,
    left: 0,
    width: "100%",
    zIndex: 1,
    borderBottom: `1px solid ${theme.palette.grey[900]}`,
  },
  [theme.breakpoints.up("md")]: {
    position: "fixed",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    paddingTop: "70px",
    right: 0,
    top: 0,
    width: "356px",
    height: "100%",
    overflow: "hidden",
    borderLeft: `4px solid ${theme.palette.grey[900]}`,
  },
}));

const getInitialPayment = (lines, { mustPayMemberFee }) => {
  return compact([
    mustPayMemberFee && { concept: "new_signup", amount: 100 },
    ...lines
      .filter((line) => line.type === "mobile" && !line.has_sim_card)
      .map(() => ({
        concept: "new_sim_card",
        amount: 2.05,
      })),
  ]);
};

const getMonthlyBill = (lines, tariffs) => {
  return lines
    .filter((line) => line?.code)
    .map((line) => {
      let tariffsList = tariffs;
      //TODO peor es morirse
      if (line.code === "SE_SC_REC_MOBILE_PACK_UNL_20480") {
        tariffsList = tariffs.map(decorateOfferTariffs);
      }
      const tariff = tariffsList.find((t) => t.code === line.code);

      return {
        concept: tariff.name,
        amount: Number(tariff.price),
      };
    });
};

const Reason = ({ text }) => (
  <Box display="flex" alignItems="center">
    <Box ml={3}>
      <li>
        <Typography
          sx={{ textTransform: "uppercase", fontSize: "11px" }}
          variant="body_small_bold"
        >
          {text}
        </Typography>
      </li>
    </Box>
  </Box>
);

const FullSummary = ({
  initialPayments,
  initialTotalAmount,
  monthlyPayments,
  monthlyTotalAmount,
  showReasons,
}) => {
  const { t } = useTranslation();
  const { isPromotionActive } = useStore();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  function reduceDuplicates(collection, key) {
    const count = {};
    collection.forEach((item) => {
      count[item[key]] = count[item[key]] || { ...item, count: 0 };
      count[item[key]].count += 1;
    });
    return Object.values(count);
  }
  return (
    <Box px={isMobile ? 3 : 7} py={5}>
      <Tiles columns={1} spacing={2}>
        <Typography
          variant="body_small_bold"
          sx={{
            textTransform: "uppercase",
            color: "grey.800",
            marginBottom: isMobile ? 3 : 6,
          }}
        >
          {t("funnel.signup.payment_summary.title")}
        </Typography>

        {initialPayments.length > 0 && (
          <>
            <Typography variant="body_large_bold">
              {t("funnel.signup.payment_summary.initial_bill")}
            </Typography>
            <Tiles columns={1} spacing={1}>
              {reduceDuplicates(initialPayments, "concept").map((item) => {
                return (
                  <Box
                    key={`${item.concept}`}
                    display="flex"
                    justifyContent="space-between"
                  >
                    <Typography variant="body_small">
                      <Trans
                        i18nKey={`funnel.signup.payment_summary.concepts.${item.concept}`}
                        count={item.count}
                      >
                        {t(
                          `funnel.signup.payment_summary.concepts.${item.concept}`,
                          { count: item.count }
                        )}
                      </Trans>
                    </Typography>

                    <Typography variant="body_small">
                      {formatPrice(item.amount * item.count)}
                    </Typography>
                  </Box>
                );
              })}
            </Tiles>
            <Divider />
            <Box display="flex" justifyContent="space-between">
              <Typography variant="body_small_bold">
                {t("common.total")}
              </Typography>
              <Typography variant="body_small_bold">
                {formatPrice(initialTotalAmount)}
              </Typography>
            </Box>
          </>
        )}

        {monthlyPayments.length > 0 && (
          <>
            <Box mt={isMobile ? 3 : 6}>
              <div>
                <Typography variant="body_large_bold">
                  {t("funnel.signup.payment_summary.monthly_bill")}
                </Typography>
              </div>
              <div>
                <Typography
                  variant="body_small_bold"
                  sx={{
                    textTransform: "uppercase",
                    color: "grey.800",
                    fontSize: "11px",
                  }}
                >
                  ({t("common.vat_included")})
                </Typography>
              </div>
            </Box>

            <Tiles columns={1} spacing={1}>
              {reduceDuplicates(monthlyPayments, "concept").map((item) => {
                return (
                  <Box>
                    <Box display="flex" justifyContent="space-between">
                      <Box>
                        <Typography variant="body_small">
                          {item.count > 1 && (
                            <>
                              {"(" + item.concept + ")"}
                              <strong>{" x " + item.count}</strong>
                            </>
                          )}
                          {item.count === 1 && item.concept}
                        </Typography>
                      </Box>
                      <Typography variant="body_small">
                        {formatPrice(item.amount * item.count)}
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
            </Tiles>
            <Divider />
            <Box display="flex" justifyContent="space-between">
              <Typography variant="body_small_bold">
                {t("common.total")}
              </Typography>
              <Typography variant="body_small_bold">
                {formatPrice(monthlyTotalAmount)}
              </Typography>
            </Box>
            {isPromotionActive && (
              <Box display="flex" justifyContent="space-between">
                <Typography variant="body_small">
                  {t("funnel.signup.payment_summary.promotion")}
                </Typography>
                <Typography variant="body_small">
                  -{formatPrice(monthlyTotalAmount)}
                </Typography>
              </Box>
            )}
          </>
        )}
        {showReasons && (
          <Box
            sx={{
              display: "flex",
              gap: 1,
              alignItems: "flex-start",
              px: 3,
              py: 1,
              borderRadius: 1,
              bgcolor: "sand.main",
              mt: isMobile ? 3 : 6,
            }}
          >
            <HelpIcon />
            <Tiles columns={1} spacing={0}>
              <Reason text={t("funnel.signup.payment_summary.reason_1")} />
              <Reason text={t("funnel.signup.payment_summary.reason_2")} />
              <Reason text={t("funnel.signup.payment_summary.reason_3")} />
            </Tiles>
          </Box>
        )}
      </Tiles>
    </Box>
  );
};

export const MobileSummary = ({
  children,
  initialTotalAmount,
  monthlyTotalAmount,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        minHeight: "70px",
        height: "auto",
        position: "relative",
        boxShadow: isOpen ? 3 : 0,
      }}
    >
      <Box
        p="12px"
        height="70px"
        display="flex"
        flexDirection="row"
        alignItems="center"
        position="absolute"
        justifyContent="space-between"
        width="100%"
        onClick={() => setIsOpen((prevState) => !prevState)}
      >
        <Box
          style={{ visibility: isOpen ? "hidden" : "visible" }}
          display="flex"
          flex={1}
        >
          <Stack spacing={0}>
            <Typography
              variant="body_small_bold"
              sx={{
                fontSize: "11px",
                textTransform: "uppercase",
                color: "grey.800",
              }}
            >
              {t("funnel.signup.payment_summary.initial_bill")}
            </Typography>
            <Typography variant="body_small_bold">
              {formatPrice(initialTotalAmount)}
            </Typography>
          </Stack>
        </Box>
        <Box
          style={{ visibility: isOpen ? "hidden" : "visible" }}
          display="flex"
          flex={1}
        >
          <Stack spacing={0}>
            <Typography
              variant="body_small_bold"
              sx={{
                fontSize: "11px",
                textTransform: "uppercase",
                color: "grey.800",
              }}
            >
              {t("funnel.signup.payment_summary.monthly_bill")}
            </Typography>
            <Typography variant="body_small_bold">
              {formatPrice(monthlyTotalAmount)}
            </Typography>
          </Stack>
        </Box>
        {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </Box>
      <Box width="100%">
        <Collapse in={isOpen}>{children}</Collapse>
      </Box>
    </Box>
  );
};

export const PaymentSummary = ({ tariffs }) => {
  const { mustPayMemberFee } = useDerivedState();
  const { getLinesFromState } = useLinesFormDerivedState();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const initialPayments = getInitialPayment(getLinesFromState(), {
    mustPayMemberFee,
  });
  const monthlyPayments = getMonthlyBill(getLinesFromState(), tariffs);

  const computeTotal = (list) =>
    list.reduce((sum, { amount }) => sum + amount, 0);

  const initialTotalAmount = computeTotal(initialPayments);
  const monthlyTotalAmount = computeTotal(monthlyPayments);

  return (
    <Root>
      {isMobile ? (
        <MobileSummary
          initialTotalAmount={initialTotalAmount}
          monthlyTotalAmount={monthlyTotalAmount}
        >
          <FullSummary
            initialTotalAmount={initialTotalAmount}
            initialPayments={initialPayments}
            monthlyTotalAmount={monthlyTotalAmount}
            monthlyPayments={monthlyPayments}
            showReasons={true}
          />
        </MobileSummary>
      ) : (
        <FullSummary
          initialTotalAmount={initialTotalAmount}
          initialPayments={initialPayments}
          monthlyTotalAmount={monthlyTotalAmount}
          monthlyPayments={monthlyPayments}
          showReasons={true}
        />
      )}
    </Root>
  );
};

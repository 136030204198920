import React from "react";
import Box from "@mui/material/Box";

export const Columns = ({
  children,
  spacing = 1,
  collapseOnSmallScreens = true,
  align = "flex-start"
}) => (
  <Box
    display="flex"
    width={1}
    flex={1}
    flexDirection={collapseOnSmallScreens ? ["column", "row"] : "row"}
    alignItems={align}
    mx={
      collapseOnSmallScreens
        ? {
            xs: 0,
            sm: -spacing / 2
          }
        : -spacing / 2
    }
  >
    {React.Children.map(
      children,
      child =>
        child && (
          <Box
            display="flex"
            flexDirection="column"
            flex={1}
            width={collapseOnSmallScreens ? ['100%', 'auto'] : 'auto'}
            mx={
              collapseOnSmallScreens ?  [0, spacing / 2] : spacing / 2
            }
            my={
              collapseOnSmallScreens ?  [spacing / 2, 0] : 0
            }
          >
            {child}
          </Box>
        )
    )}
  </Box>
);

import axios from "axios";

export const createTicket = async ({
  body,
  subject,
  meta,
  override_ticket_ids,
}) => {
  return await axios.post(`/tickets/`, {
    body,
    subject,
    meta,
    override_ticket_ids,
  });
};

const createPublicTicket = async ({ body, subject, meta }) => {
  return await axios.post(`/tickets/public/`, {
    body,
    subject,
    meta,
  });
};

export const getTickets = async ({ type, ...filters }) => {
  return await axios.get(`/tickets/`, {
    params: { ticket_type: type, ...filters },
  });
};

export const postRequestChange = async ({
  topic,
  newValue,
  scope,
  selectedSubscriptions,
  lang,
  documentation,
}) => {
  return await createTicket({
    body: "",
    subject: "-",
    meta: [
      {
        key: "ticket_type",
        value: `change_${topic}`,
      },
      {
        key: "new_value",
        value: newValue,
      },
      {
        key: "scope",
        value: scope,
      },
      {
        key: "selected_subscriptions",
        value: selectedSubscriptions,
      },
      {
        key: "language",
        value: lang,
      },
      {
        key: "docs",
        value: documentation,
      },
    ],
  });
};

export const postWeCallYou = async ({
  lang,
  name,
  schedule,
  phone,
  reason,
}) => {
  return await createPublicTicket({
    body: "",
    subject: "-",
    meta: [
      {
        key: "ticket_type",
        value: "we_call_you",
      },
      {
        key: "language",
        value: lang,
      },
      {
        key: "name",
        value: name,
      },
      {
        key: "schedule",
        value: schedule,
      },
      {
        key: "phone",
        value: phone,
      },
      {
        key: "reason",
        value: reason,
      },
    ],
  });
};

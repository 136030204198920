import { useStore } from "./useStore";

const canAddLineOfType = (state) => (type) => {
  if (!state.initialIntent) {
    return false;
  }

  const wantedMobileInitially = state.initialIntent.lines.some(
    (line) => line.type === "mobile"
  );
  const wantedInternetInitially = state.initialIntent.lines.some(
    (line) => line.type === "internet"
  );
  /*
   * for type === "internet" is deprecated
   */
  if (type === "internet") {
    return (
      (state.optingForRole || state.currentRole) === "member" &&
      wantedInternetInitially &&
      state.lines.filter((line) => line.type === "internet").length < 2
    );
  } else if (type === "mobile") {
    return (
      wantedMobileInitially &&
      state.lines.filter((line) => line.type === "mobile").length < 4
    );
  } else {
    return false;
  }
};

const clearMobileLine20GBInFormStepData = (state) => {
  const formStgepsDataWith20GBMobile = Object.entries(
    state.formStepDataByKey
  ).filter(
    ([key, value]) =>
      key.startsWith("line") &&
      value.type === "mobile" &&
      //TODO peor es morirse
      (value.code === "SE_SC_REC_MOBILE_PACK_UNL_20480" ||
        value.code === "SE_SC_REC_MOBILE_T_UNL_20480")
  );
  if (formStgepsDataWith20GBMobile.length > 0) {
    formStgepsDataWith20GBMobile.forEach((formStgepDataWith20GB) => {
      state.setFormStepData(formStgepDataWith20GB[0], {
        __id: formStgepDataWith20GB[1].__id,
        type: "mobile",
      });
    });
    return true;
  }
  return false;
};

const setOfferMobileLineInFormStepData = (line__id, state) => {
  const mobileLineToUpdate = state.formStepDataByKey[`line-${line__id}/tariff`];
  state.setFormStepData(
    `line-${line__id}/tariff`,
    Object.assign(mobileLineToUpdate, {
      code: "SE_SC_REC_MOBILE_PACK_UNL_20480",
      is_offer_tariff: true,
    })
  );
  return true;
};

const resolveStepEditable = (id, confirmMode, state) => {
  if (!confirmMode & state.isSharedLines) return false;
  return confirmMode
    ? !id.endsWith("/tariff")
    : state.steps.indexOf(id) < state.currentIndex;
};

export const showPayMemberFee = (state) =>
  !state.loggedIn && state.optingForRole === "member";

export const mustPayMemberFee = (state) =>
  !state.loggedIn &&
  (state.optingForRole === "member" || state.optingForRole === "new_member");

export const mustPayMonthlyBill = (state) => (state.lines.length || []) > 0;

export const mustShipmentSimCard = (state) =>
  !Object.keys(state.formStepDataByKey)
    .filter((key) =>
      state.formStepDataByKey[key].hasOwnProperty("has_sim_card")
    )
    .reduce((hasSim, key) => {
      return hasSim && state.formStepDataByKey[key].has_sim_card;
    }, true);

export const mustSelectRole = (state) =>
  !state.loggedIn &&
  ((state.optingForRole === "coop_agreement" &&
    state.coopAgreementCode === "SC") ||
    state.optingForRole === "new_member");

export const useDerivedState = () => {
  const state = useStore((state) => state);
  return {
    isStepEditable: (id, confirmMode) =>
      resolveStepEditable(id, confirmMode, state),
    currentStep: state.steps[state.currentIndex],
    canAddLineOfType: canAddLineOfType(state),
    shouldAskForPersonalData: !state.loggedIn,
    showPayMemberFee: showPayMemberFee(state),
    mustPayMemberFee: mustPayMemberFee(state),
    mustPayMonthlyBill: mustPayMonthlyBill(state),
    mustShipmentSimCard: mustShipmentSimCard(state),
    mustSelectRole: mustSelectRole(state),
    getAddressById: (id) => state.availableAddresses.find((a) => a._id === id),
    clearMobileLineCanBeOfferInForm: () =>
      clearMobileLine20GBInFormStepData(state),
    setOfferMobileLineInFormStepData: (line__id) =>
      setOfferMobileLineInFormStepData(line__id, state),
  };
};

import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export const DataContent = ({
  data,
  isOffer = false,
  isSelected,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        textAlign: "center",
        width: "40px",
        cursor: "pointer",
        margin: "0 auto",
      }}
      {...props}
    >
      {isOffer && (
        <Box
          sx={(theme) => ({
            background: theme.palette.primary.main,
            color: theme.palette.grey[50],
            m: isSelected ? "-12px -4px -4px" : "-12px -8px -4px",
            textTransform: "uppercase",
            height: "20px",
          })}
        >
          <Typography
            sx={{ fontSize: "10px", fontWeight: "700" }}
            variant="body_small_bold"
          >
            {t("common.saving")}
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mb: isOffer ? "-4px" : "0px",
        }}
      >
        <Typography variant="body_bold">{data / 1024}</Typography>
        <Typography sx={{ mt: "-6px" }} variant="body_small_bold">
          GB
        </Typography>
      </Box>
    </Box>
  );
};

// vendor
import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  screen: {
    margin: "17px",
    display: "flex",
    flexDirection: "column",
    gap: "56px",
    [theme.breakpoints.up("md")]: {
      margin: "56px",
      flexDirection: "row",
    },
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: "360px",
      flexShrink: 0,
    },
  },
  categories: {
    listStyle: "none",
    textAlign: "right",
    margin: "14px 0",
    padding: "0",
  },
  category: {
    "padding": "16px",
    "cursor": "pointer",
    "&:hover": {
      fontWeight: "bold",
    },
    "&.current": {
      fontWeight: "bold",
      borderRight: "4px solid",
    },
  },
  content: {
    flexGrow: 1,
    [theme.breakpoints.up("md")]: {
      height: "100%",
      marginRight: "-16px",
      paddingRight: "16px",
      overflowY: "auto",
    },
  },
  pages: {
    display: "flex",
    flexDirection: "column",
    listStyle: "none",
    gap: "16px",
    padding: 0,
    margin: 0,
  },
  page: {
    backgroundColor: theme.palette.grey["100"],
    borderRadius: "16px",
    padding: "32px",
    margin: 0,
  },
  pageTitle: {
    "position": "relative",
    "paddingRight": "1em",
    "cursor": "pointer",
    "&::after": {
      content: "'+'",
      position: "absolute",
      right: "0",
      top: "50%",
      transform: "translateY(-50%)",
      fontSize: "1.5em",
      fontWeight: "bold",
      height: "1.5em",
      lineHeight: "1.5em",
      fontSize: "1em",
    },
    "&.current::after": {
      content: "'-'",
    },
  },
}));

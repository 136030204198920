import React from "react";
import { CardActions, Card, Box } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import { Divider } from "./Divider";
import { ArrowVertical } from "./icons/ArrowVertical";
export const PricingSelect = ({
  content,
  action,
  isSelected = false,
  isArrowed = false,
}) => (
  <div>
    <Card
      variant="outlined"
      sx={(theme) => ({
        display: "flex",
        p: isSelected ? "4px" : 1,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "4px",
        alignSelf: "stretch",
        borderRadius: 2,
        background: isSelected
          ? theme.palette.grey[50]
          : theme.palette.grey[200],
        border: isSelected ? `4px solid ${theme.palette.primary.main}` : 0,
      })}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          height: "100%",
          width: "100%",
        }}
      >
        {content}
      </CardContent>
      {action && (
        <>
          <Divider aria-hidden="true" flexItem />
          <CardActions
            sx={{
              p: "2px",
            }}
          >
            {action}
          </CardActions>
        </>
      )}
    </Card>
    {isSelected && isArrowed && (
      <Box
        sx={{
          mt: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <ArrowVertical variant="dashed" height="27" minY="38" />
      </Box>
    )}
  </div>
);

import React, { useEffect, useMemo, useState } from "react";
import { BandwithSelector } from "./BandwithSelector";
import { CardInternetTariffInfo } from "./CardInternetTariffInfo";
import { TechnologySelector } from "./TechnologySelector";
import { getTechnologicalOffers } from "screens/Tariffs/utils/tariffsReducers";
import { Typography } from "@mui/material";
import { formatBandwith } from "lib/helpers/format";
import { Field } from "react-final-form";

function getInfo(selectTechnoOffer) {
  return (
    <>
      {selectTechnoOffer.bandwidths.length === 1 && (
        <Typography variant="h4">
          {formatBandwith(
            selectTechnoOffer.category === "fiber"
              ? selectTechnoOffer.bandwidths.find(
                  (bandwidth) => bandwidth.isSelected
                ).bandwidth
              : selectTechnoOffer.data
          )}
        </Typography>
      )}

      {
        "TODO literals for each tecno & bandwidth: Esta velocidad es algo justa si quieres hacer videoconferencias y tal pascual, pero bueno está bien para esto y lo otro."
      }
    </>
  );
}

export const InternetTariffPicker = ({
  internetTariffs,
  onChange,
  bandwidthAction,
  initialTariffCode,
  ...props
}) => {
  const initialTariff = useMemo(() => {
    return internetTariffs.find(({ code }) => code === initialTariffCode);
  }, [internetTariffs]);
  const [technologicalOffers, setTechnologicalOffers] = useState(
    getTechnologicalOffers(internetTariffs, initialTariff)
  );
  const [selectTechnoOffer, setSelectTechnoOffer] = useState(
    technologicalOffers.find(({ isSelected }) => isSelected) || {
      bandwidths: [],
    }
  );

  function setTechnologicalOfferSelected(technologicalOffer) {
    const technologicalOffersCopy = [...technologicalOffers];
    technologicalOffersCopy.forEach(
      (item) => (item.isSelected = item.name === technologicalOffer.name)
    );
    if (technologicalOffer.bandwidths.length === 1) {
      technologicalOffer.bandwidths[0].isSelected = true;
    }
    setTechnologicalOffers(technologicalOffersCopy);
    setSelectTechnoOffer(technologicalOffer);
  }

  function setBandwidthSelected(bandwidth) {
    const selectTechnoOfferCopy = { ...selectTechnoOffer };
    selectTechnoOfferCopy.bandwidths.forEach(
      (item) => (item.isSelected = item.bandwidth === bandwidth.bandwidth)
    );
    setSelectTechnoOffer(selectTechnoOfferCopy);
  }

  const matchingTariff = useMemo(() => {
    const category = selectTechnoOffer.category;
    const hasLandLine = selectTechnoOffer.hasLandLine;
    const bandwidth = selectTechnoOffer.bandwidths.find(
      (bandwidth) => bandwidth.isSelected
    )?.bandwidth;
    return internetTariffs.find(
      (tariff) =>
        tariff.category === category &&
        tariff.has_landline_phone === hasLandLine &&
        tariff.bandwidth === bandwidth
    );
  }, [selectTechnoOffer]);

  useEffect(() => {
    if (!matchingTariff) {
      return;
    }
    onChange(matchingTariff);
  }, [matchingTariff]);

  return (
    <>
      <TechnologySelector
        onClick={setTechnologicalOfferSelected}
        technologies={technologicalOffers}
        showAction={true}
      />
      {selectTechnoOffer.bandwidths.length > 1 && (
        <BandwithSelector
          onClick={setBandwidthSelected}
          bandwidths={selectTechnoOffer.bandwidths}
          bandwidthAction={bandwidthAction}
          tech={selectTechnoOffer.category}
        />
      )}
      {matchingTariff && (
        <CardInternetTariffInfo
          hasLandLine={selectTechnoOffer.hasLandLine}
          content={getInfo(selectTechnoOffer)}
          {...props}
        />
      )}
    </>
  );
};

InternetTariffPicker.FormField = ({ name, validate, ...props }) => (
  <Field name={name} validate={validate}>
    {({ input, meta }) => (
      <InternetTariffPicker
        {...props}
        initialTariffCode={input.value}
        onChange={({ code }) => input.onChange(code)}
      />
    )}
  </Field>
);

import { Box, styled, Typography } from "@mui/material";
import React from "react";

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  padding: "4px 20px",
  alignItems: "center",
  gap: "4px",
  borderRadius: "0px 0px 4px 4px",
  background: theme.palette.error.main,
}));

export const InputErrorLabel = ({ label }) => (
  <StyledBox>
    <Typography
      sx={(theme) => ({ color: theme.palette.error.contrastText })}
      variant="body_small"
    >
      {label}
    </Typography>
  </StyledBox>
);

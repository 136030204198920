export const Forest = () => (
  <svg
    width="353"
    height="89"
    viewBox="0 0 353 89"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M335.71 26.1674C333.827 26.309 332.31 27.7146 331.044 29.1093C326.772 33.8137 323.408 39.3319 321.19 45.2758C319.17 50.6898 318.115 56.7422 319.942 62.2248C321.733 67.5981 326.246 72.1474 332.442 72.0151L338.423 71.86C343.784 72.6357 349.437 68.6248 351.142 63.5067C353.563 56.2369 348.35 38.747 341.477 29.7095C340.054 27.8376 338.063 25.9911 335.71 26.1674Z"
      fill="#FFBC3F"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M339.196 69.7399C339.633 68.7022 340.295 67.7764 340.972 66.876C342.069 65.4165 343.22 63.9977 344.421 62.623C344.636 62.3773 344.863 62.1088 344.888 61.7844C344.93 61.2626 344.397 60.8323 343.873 60.8078C343.349 60.7832 342.857 61.0543 342.43 61.36C341.117 62.3019 340.139 63.6259 339 64.7728C338.883 64.8905 338.732 65.0167 338.57 64.9769C338.415 64.9388 338.338 64.7677 338.288 64.6169C337.712 62.8779 337.76 60.9924 337.995 59.1755C338.436 55.7687 339.518 52.4457 341.169 49.4303C341.688 48.4816 342.283 47.416 341.965 46.3834C341.903 46.181 341.789 45.9726 341.591 45.8964C341.37 45.8108 341.124 45.9159 340.917 46.0302C338.999 47.0882 337.75 49.0593 336.959 51.0972C336.169 53.1352 335.759 55.3011 335.043 57.3662C334.018 54.6286 332.852 51.7097 330.406 50.1011C330.267 50.0096 330.115 49.9198 329.948 49.9139C329.569 49.9004 329.306 50.3391 329.35 50.7144C329.394 51.0896 329.641 51.4056 329.868 51.708C331.688 54.1305 332.802 57.074 333.04 60.092C333.097 60.8103 333.091 61.5828 332.692 62.1825C331.361 61.1229 330.029 60.0632 328.698 59.0036C328.336 58.7156 327.751 58.4479 327.476 58.8189C327.357 58.979 327.363 59.1984 327.398 59.3949C327.587 60.4766 328.425 61.3067 329.127 62.1529C332.673 66.4262 335.043 75.4987 335.043 82.4115C335.043 89.3243 338.57 87.4173 338.288 85.987C339.058 82.1604 337.891 76.7262 338.491 72.5309C338.626 71.5779 338.826 70.6276 339.199 69.7399H339.196Z"
      fill="black"
    />
    <path
      d="M341.303 86.2911C338.836 85.719 338.377 82.5566 338.456 81.0469C337.507 80.3318 335.277 79.9324 334.897 83.3649C334.517 86.7975 326.592 87.4173 325.643 87.0062C324.694 86.5952 323.745 87.9597 323.745 87.9597H348.422C348.422 87.9597 348.896 87.4173 348.422 87.0062C347.947 86.5952 343.771 86.8632 341.303 86.2911Z"
      fill="black"
    />
    <path
      d="M333.991 86.2922C333.773 85.9442 332.819 84.1183 332.819 84.1183"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M333.517 87.2457C333.299 86.8977 332.345 85.0717 332.345 85.0717"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M343.918 77.1674C344.568 77.4725 346.22 79.2412 345.479 80.6047C344.739 81.9682 343.785 82.9264 343.918 82.75C344.051 82.5736 342.789 81.0481 343.311 79.2174C343.833 77.3867 343.918 77.1674 343.918 77.1674Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M342.965 86.893C342.965 86.893 344.588 82.2685 344.426 78.7359"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M333.469 37.5265C334.26 38.2934 335.314 38.9324 335.709 38.549C336.026 38.2422 336.632 37.7395 336.895 37.5265"
      stroke="black"
      strokeLinecap="round"
    />
    <path
      d="M328.138 40.1797C328.928 40.9466 329.983 41.5856 330.378 41.2022C330.695 40.8954 331.301 40.3927 331.564 40.1797"
      stroke="black"
      strokeLinecap="round"
    />
    <path
      d="M338.418 41.3168C339.209 42.0837 340.263 42.7227 340.659 42.3393C340.975 42.0325 341.581 41.5298 341.845 41.3168"
      stroke="black"
      strokeLinecap="round"
    />
    <path
      d="M332.326 44.728C333.117 45.4949 334.171 46.1339 334.567 45.7505C334.883 45.4438 335.489 44.941 335.753 44.728"
      stroke="black"
      strokeLinecap="round"
    />
    <path
      d="M324.711 46.2441C325.502 47.011 326.556 47.65 326.951 47.2666C327.268 46.9599 327.874 46.4572 328.138 46.2441"
      stroke="black"
      strokeLinecap="round"
    />
    <path
      d="M343.368 49.2763C344.159 50.0432 345.213 50.6822 345.609 50.2988C345.925 49.9921 346.531 49.4894 346.795 49.2763"
      stroke="black"
      strokeLinecap="round"
    />
    <path
      d="M335.372 31.8411C336.163 32.608 337.217 33.247 337.613 32.8636C337.929 32.5569 338.535 32.0541 338.799 31.8411"
      stroke="black"
      strokeLinecap="round"
    />
    <path
      d="M322.808 51.9295C323.598 52.6964 324.653 53.3354 325.048 52.952C325.364 52.6453 325.971 52.1425 326.234 51.9295"
      stroke="black"
      strokeLinecap="round"
    />
    <path
      d="M341.464 54.2037C342.255 54.9705 343.309 55.6096 343.704 55.2261C344.021 54.9194 344.627 54.4167 344.891 54.2037"
      stroke="black"
      strokeLinecap="round"
    />
    <path
      d="M344.891 58.373C345.681 59.1398 346.736 59.7789 347.131 59.3955C347.447 59.0887 348.054 58.586 348.317 58.373"
      stroke="black"
      strokeLinecap="round"
    />
    <path
      d="M326.234 55.7198C327.025 56.4866 328.08 57.1257 328.475 56.7423C328.791 56.4355 329.397 55.9328 329.661 55.7198"
      stroke="black"
      strokeLinecap="round"
    />
    <path
      d="M323.569 62.9213C324.36 63.6881 325.414 64.3272 325.81 63.9438C326.126 63.637 326.732 63.1343 326.996 62.9213"
      stroke="black"
      strokeLinecap="round"
    />
    <g clip-path="url(#clip0_808_24688)">
      <path
        d="M69.7342 86.1178C69.4976 85.7391 68.4639 83.7522 68.4639 83.7522"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M82.6436 84.1257V86.5484L84.1968 85.3396"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M63.0071 87.5238C63.5728 87.3526 94.5397 87.5238 94.5397 87.5238L93.223 86.123C93.223 86.123 78.8685 87.7364 77.3616 82.3722C75.8547 77.008 75.15 82.3722 75.15 82.3722C75.15 82.3722 75.2889 83.8456 72.7945 84.6497C70.3001 85.4538 63.7117 86.6418 63.5677 86.6885C63.4237 86.7352 63.002 87.5238 63.002 87.5238H63.0071Z"
        fill="black"
      />
      <path
        d="M77.5747 19.277C77.6518 20.9162 77.7051 22.876 77.7381 25.0834C77.8401 31.9022 77.7471 41.0036 77.5775 50.1162C77.4078 59.2262 77.1617 68.3373 76.9579 75.1715C76.8561 78.5884 76.7648 81.4358 76.699 83.4288C76.6661 84.4253 76.6396 85.2082 76.6213 85.7419L76.6181 85.8345C76.4741 85.9038 76.349 85.9783 76.2461 86.0432C76.1164 86.125 75.9879 86.2145 75.8843 86.2867C75.8652 86.3 75.8469 86.3128 75.8296 86.3248C75.8031 86.3432 75.7783 86.3602 75.7552 86.376C75.7449 86.3506 75.7341 86.3228 75.7228 86.2926C75.7214 86.2806 75.7115 86.225 75.6992 86.1023C75.6821 85.9309 75.6654 85.6915 75.6501 85.3821C75.6195 84.7653 75.5959 83.9104 75.5797 82.8463C75.5472 80.7203 75.5443 77.7905 75.5697 74.3266C75.6204 67.4003 75.7838 58.3579 76.0472 49.3801C76.3107 40.4003 76.6738 31.4959 77.1232 24.8418C77.2658 22.7304 77.4165 20.8535 77.5747 19.277ZM75.4814 86.5393C75.4815 86.5391 75.4846 86.5378 75.4904 86.536C75.4842 86.5386 75.4813 86.5395 75.4814 86.5393Z"
        fill="black"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M86.7317 76.707C87.4363 77.0391 89.2261 78.9638 88.4238 80.4475C87.6215 81.9312 86.5877 82.974 86.7317 82.782C86.8757 82.5901 85.5076 80.9299 86.0734 78.9378C86.6391 76.9457 86.7317 76.707 86.7317 76.707Z"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M85.6982 87.2903C85.6982 87.2903 87.4572 82.2581 87.2823 78.4138"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M91.9986 80.8879C91.9986 80.8879 92.1889 83.9747 90.4917 84.5454C88.7945 85.116 88.4036 85.0382 88.4036 85.0382C88.4036 85.0382 87.9973 82.3613 89.6482 81.5053C91.2992 80.6493 92.0038 80.8879 92.0038 80.8879H91.9986Z"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M86.7266 87.1496C86.9169 86.8176 88.9381 84.1562 91.0571 82.356"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M62.5547 82.1828C62.8376 82.3229 64.2468 84.9843 65.944 84.9843C67.6413 84.9843 66.1806 82.1361 64.9102 81.9442C63.6399 81.7522 62.5547 82.1828 62.5547 82.1828Z"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M64.6328 82.8572C64.6328 82.8572 67.1169 84.3358 67.1169 86.6911"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M76.3225 41.0094C76.3225 41.0094 86.3105 52.376 90.4044 56.2513C94.7092 60.3238 101.951 62.8399 95.2338 65.2938C88.5169 67.7476 75.4893 67.3585 71.2411 67.2288C66.9928 67.0991 55.6111 64.775 54.6493 62.8399C53.6875 60.9048 62.3126 58.5962 67.3529 52.376C72.3931 46.1558 76.3174 41.0094 76.3174 41.0094"
        fill="#65AA4B"
      />
      <path
        d="M76.3225 41.0094C76.3225 41.0094 86.3105 52.376 90.4044 56.2513C94.7092 60.3238 101.951 62.8399 95.2338 65.2938C88.5169 67.7476 75.4893 67.3585 71.2411 67.2288C66.9928 67.0991 55.6111 64.775 54.6493 62.8399C53.6875 60.9048 62.3126 58.5962 67.3529 52.376C72.3931 46.1558 76.3174 41.0094 76.3174 41.0094"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M80.0874 51.7586C81.2806 51.7223 83.2864 51.4111 85.2922 50.9649C81.0183 46.3581 76.3226 41.0094 76.3226 41.0094C76.3226 41.0094 72.8047 45.6214 68.1553 51.3903C72.6658 51.9973 77.9684 51.8261 80.0874 51.7586Z"
        fill="black"
      />
      <path
        d="M76.6006 29.9696C76.6006 29.9696 68.4642 39.1314 64.9205 41.7772C61.6341 44.231 59.0008 46.0157 63.6142 47.821C68.2276 49.6264 77.1715 49.3411 80.0877 49.2477C83.0038 49.1543 90.8162 47.4423 91.4797 46.0209C92.1432 44.5994 83.3638 37.5854 79.9025 33.0149C76.4412 28.4444 76.6057 29.9748 76.6057 29.9748"
        fill="#65AA4B"
      />
      <path
        d="M76.6006 29.9696C76.6006 29.9696 68.4642 39.1314 64.9205 41.7772C61.6341 44.231 59.0008 46.0157 63.6142 47.821C68.2276 49.6264 77.1715 49.3411 80.0877 49.2477C83.0038 49.1543 90.8162 47.4423 91.4797 46.0209C92.1432 44.5994 83.3638 37.5854 79.9025 33.0149C76.4412 28.4444 76.6057 29.9748 76.6057 29.9748"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M81.4557 38.763C81.2294 37.8604 80.8488 37.077 80.5402 36.5375C81.2036 37.4142 82.4689 38.6333 83.9964 37.767C84.0529 37.7358 84.1095 37.7047 84.1661 37.6788C82.5306 36.0498 80.9671 34.4156 79.9024 33.0149C76.4411 28.4444 76.6057 29.9748 76.6057 29.9748C76.6057 29.9748 73.2729 33.7256 69.9453 37.1185C71.9254 36.9317 73.5249 36.0031 73.6895 35.9149C73.9724 35.7748 71.1488 38.763 73.2678 38.763C75.3868 38.763 81.8825 40.4698 81.4557 38.763Z"
        fill="black"
      />
      <path
        d="M85.8421 30.5195C89.576 33.1083 86.2536 34.8566 83.9957 36.138C81.7379 37.4194 80.0406 34.1458 80.0406 34.1458C80.0406 34.1458 81.0281 35.4272 81.455 37.1392C81.8819 38.8512 75.3861 37.1392 73.2671 37.1392C71.1482 37.1392 73.9717 34.1458 73.6889 34.2911C73.406 34.4364 70.387 36.2936 66.9976 33.5907C65.1101 32.0811 68.2989 30.2809 71.8888 27.578C74.7381 25.4302 76.5124 22.8363 77.3251 22.8363C78.8423 22.8363 82.1082 27.936 85.8421 30.5247V30.5195Z"
        fill="#65AA4B"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M64.5771 59.7976C64.6146 59.9463 64.9894 61.9091 65.4575 61.1646C65.7082 60.7658 65.7235 61.2588 66.0366 61.4723C66.702 61.9259 66.9891 61.4056 67.5733 61.2191C67.8652 61.1259 68.1441 61.2056 68.4381 61.2386C69.2243 61.3267 69.914 61.2114 70.6929 61.1996"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M80.9785 60.5688C81.4267 60.8241 82.6812 61.769 83.0634 60.9309C83.1152 60.8175 83.0865 60.5994 83.2411 60.7245C83.5133 60.945 83.7346 61.1457 84.1098 61.1646C84.4014 61.1793 84.5967 61.1023 84.8241 60.9271C84.9504 60.8297 85.1618 60.4155 85.3492 60.4052C85.6849 60.3868 86.0636 60.689 86.4186 60.7051C86.5981 60.7132 86.7831 60.7062 86.9553 60.6544C87.9046 60.369 87.0023 60.4605 87.8588 60.0079"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M68.6074 42.6226C68.7219 42.8642 69.0633 43.9746 69.5456 43.8961C69.8175 43.8519 69.8402 43.4611 69.9124 43.2691C69.9708 43.1139 70.0906 43.5483 70.1827 43.6858C70.3875 43.9918 70.6917 44.3196 71.1016 44.2739C71.4441 44.2358 71.6082 43.9191 71.8391 43.717C72.1211 43.4701 72.3477 43.6103 72.6692 43.7053C73.7654 44.0294 74.7977 43.9357 75.9047 43.7443"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M82.2988 43.1133C82.6699 43.0833 83.0398 43.029 83.4108 43.0043C83.7843 42.9794 83.9285 42.8438 84.2448 42.6927"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M74.0986 29.2331C74.1268 29.5656 74.096 30.1318 74.4925 30.2885C74.889 30.4453 75.254 30.0517 75.5195 29.8329C75.7824 29.6162 75.9157 29.4693 76.2492 29.6304C76.7211 29.8583 77.1172 30.1998 77.67 30.2068C77.9221 30.2099 78.0011 30.0767 78.2144 30.0276C78.3602 29.9941 78.578 30.1667 78.6971 30.2107C79.533 30.5194 80.2873 30.2997 81.0484 29.9341"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M70.5537 54.9606C71.201 55.287 71.9754 55.81 72.7468 55.7161C73.126 55.6699 73.1206 55.3526 73.4108 55.2721C73.7011 55.1917 74.3582 55.4878 74.6155 55.5253C75.4292 55.6439 76.1426 55.4866 76.9475 55.4513"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <g clip-path="url(#clip1_808_24688)">
      <path
        d="M29.0175 63.1688C31.9923 62.7222 34.9055 61.932 37.6867 60.8154C40.0718 59.8619 42.4569 58.565 43.8035 56.4176C45.2557 54.0899 45.2293 51.1953 45.1589 48.4725C45.1325 47.5105 45.0972 46.5142 44.6748 45.6381C44.2699 44.7963 43.5306 44.1521 42.7561 43.6024C40.9255 42.2968 38.7868 41.4121 36.5513 41.0428C39.7461 39.9434 43.205 38.6206 44.8332 35.7174C45.5461 34.4462 45.8277 32.986 46.0918 31.5602C46.3294 30.3062 46.5582 29.0264 46.3558 27.7637C46.171 26.6471 45.6517 25.6078 45.0092 24.663C42.3689 20.7463 37.5459 18.3413 32.7492 18.5388C33.6293 17.3878 35.196 16.8897 36.0673 15.7301C37.053 14.3988 36.8682 12.5435 36.2785 11.006C34.2278 5.6205 28.2342 2.59706 22.4255 1.65224C19.5827 1.18842 16.6255 1.10253 13.8444 1.85838C11.0632 2.61424 8.46686 4.2548 6.99707 6.67698C6.55701 7.39849 6.21377 8.21447 6.27537 9.04763C6.32818 9.92374 6.81224 10.714 7.29631 11.4612C9.98946 15.6013 13.2019 19.4321 16.8368 22.8249C14.4956 22.0776 11.9169 22.4813 9.58461 23.2715C5.34245 24.7059 1.23232 27.9355 0.853866 32.2989C0.827463 32.6081 0.818662 32.9345 0.941878 33.2179C1.1179 33.6216 1.51395 33.8793 1.8924 34.1112C5.06082 36.0095 8.59888 37.3236 12.2602 37.9678C7.43713 39.3936 4.51514 44.0405 2.04202 48.3265C1.17071 49.8382 0.264189 51.4788 0.449014 53.2052C0.633838 54.9059 1.8748 56.3489 3.36219 57.2508C4.84959 58.1527 6.57461 58.5736 8.27324 58.9944C12.7706 60.1025 17.2592 61.2191 21.7566 62.3271L29.0087 63.1946L29.0175 63.1688Z"
        fill="#AC6540"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.877 26.6471C16.1674 26.7158 18.7109 30.7614 22.8563 29.5589"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.0059 49.5548C15.5163 49.9842 20.0929 55.6532 22.9269 52.3205C25.7609 48.9879 24.916 39.5568 24.916 39.5568C24.916 39.5568 29.7566 41.3262 31.7896 35.5113"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.1061 8.42751C24.8486 7.47138 25.5079 27.7891 26.1232 36.6504C26.7385 45.5117 29.525 80.1885 28.4438 83.7911C27.3626 87.3936 24.2685 88.8961 23.1873 84.696C22.1061 80.4958 22.7214 37.1114 22.1061 30.2051C21.4908 23.2987 19.9437 9.18729 22.1061 8.43604V8.42751Z"
        fill="black"
      />
      <path
        d="M32.5383 85.2754C28.8995 84.4527 28.2231 79.9049 28.3397 77.7339C26.9403 76.7055 23.6516 76.1311 23.0917 81.0673C22.5319 86.0036 10.846 86.8948 9.44649 86.3038C8.04697 85.7127 6.64746 87.6749 6.64746 87.6749H43.0348C43.0348 87.6749 43.7346 86.8948 43.0348 86.3038C42.3351 85.7127 36.177 86.0981 32.5383 85.2754Z"
        fill="black"
      />
      <path
        d="M4.19824 81.4478C4.58311 81.6329 6.50044 85.15 8.80964 85.15C11.1188 85.15 9.13153 81.3861 7.40313 81.1325C5.67473 80.8788 4.19824 81.4478 4.19824 81.4478Z"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.02539 82.3391C7.02539 82.3391 10.4052 84.293 10.4052 87.4056"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.8879 83.8097C17.538 84.838 17.1951 87.0881 16.7881 86.0541C16.6281 85.6474 16.1571 84.8463 16.0947 84.541"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.2744 86.2176C31.3535 85.0786 31.52 83.9955 31.7472 82.8825"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <path
      d="M136.108 75.6906C137.048 76.4027 138.608 81.8828 138.608 81.8828C138.608 81.8828 137.644 81.0234 136.479 81.2444C135.852 81.3649 135.384 81.9542 134.462 82.3494C134.271 82.211 134.08 82.0726 133.891 81.9342C133.828 81.8873 133.761 81.8359 133.734 81.7623C133.666 81.5837 133.862 81.4252 134.021 81.3181C134.179 81.2087 134.338 80.9877 134.206 80.8493C134.159 80.8002 134.087 80.7823 134.017 80.7667C133.648 80.6819 133.277 80.5971 132.906 80.5122C132.827 80.4944 132.735 80.4676 132.699 80.3939C132.675 80.347 132.679 80.2912 132.693 80.2399C132.818 79.7198 133.601 79.4966 133.594 78.9631C133.59 78.5769 133.174 78.3492 132.83 78.1729C132.76 78.1371 132.688 78.0947 132.661 78.0233C132.621 77.9206 132.693 77.809 132.771 77.7309C133.046 77.463 133.437 77.3514 133.752 77.1304C134.066 76.9094 134.3 76.4652 134.082 76.1505C133.972 75.992 133.754 75.8647 133.79 75.675C133.812 75.55 133.94 75.4741 134.057 75.425C134.372 75.2933 134.714 75.2196 135.056 75.2107L135.074 75.1549C135.496 75.291 135.825 75.4763 136.108 75.6906Z"
      fill="black"
    />
    <path
      d="M134.022 81.318C133.862 81.4252 133.667 81.5837 133.734 81.7623C133.761 81.8359 133.828 81.8873 133.891 81.9341C134.08 82.0725 134.271 82.2109 134.463 82.3493C134.397 82.3761 134.33 82.4051 134.26 82.4297C133.482 82.7177 132.398 82.8538 130.712 82.5212C126.728 81.7355 123.211 77.4853 123.211 77.4853C123.211 77.4853 122.964 78.3693 123.063 79.4251C123.094 79.7666 123.216 80.2912 123.357 80.8872C123.061 81.1038 122.247 81.8984 121.642 81.9855C121.601 81.9386 121.554 81.8962 121.505 81.8582C120.994 81.481 120.587 81.0234 120.025 80.6707C119.974 80.1684 119.917 79.818 119.834 79.818C119.618 79.818 117.141 79.2733 117.114 80.1149C115.699 80.126 114.993 80.2131 114.993 80.2131C114.993 80.2131 115.034 80.7645 115.088 81.5703C115.016 81.577 113.417 81.7109 113.41 81.6618C113.343 81.1038 113.246 80.7086 113.114 80.6551L113.089 72.2998C114.029 72.6436 117.197 70.677 120.266 72.0297C123.335 73.3802 126.404 75.6415 130.069 75.1258C132.628 74.7642 134.098 74.8357 135.074 75.1549L135.056 75.2107C134.714 75.2196 134.373 75.2933 134.058 75.425C133.941 75.4741 133.813 75.55 133.79 75.675C133.754 75.8647 133.972 75.9919 134.083 76.1504C134.301 76.4652 134.067 76.9094 133.752 77.1304C133.437 77.3514 133.046 77.463 132.772 77.7309C132.693 77.809 132.621 77.9206 132.662 78.0233C132.689 78.0947 132.76 78.1371 132.83 78.1728C133.174 78.3492 133.59 78.5769 133.595 78.963C133.601 79.4965 132.819 79.7198 132.693 80.2399C132.68 80.2912 132.675 80.347 132.7 80.3939C132.736 80.4676 132.828 80.4944 132.907 80.5122C133.278 80.597 133.649 80.6819 134.017 80.7667C134.087 80.7823 134.159 80.8002 134.206 80.8493C134.339 80.9877 134.179 81.2087 134.022 81.318Z"
      fill="black"
    />
    <path
      d="M123.357 80.8872C123.652 82.1328 124.041 83.6887 123.906 84.5369C123.706 85.7914 123.335 87.4612 122.667 87.5594C121.999 87.6576 120.612 87.8786 120.859 87.3138C121.109 86.7491 121.999 86.5035 122.098 85.9142C122.193 85.3539 122.442 82.8516 121.642 81.9855C122.247 81.8984 123.06 81.1038 123.357 80.8872Z"
      fill="black"
    />
    <path
      d="M117.113 80.1149C117.14 79.2733 119.618 79.818 119.834 79.818C119.917 79.818 119.973 80.1685 120.025 80.6707C119.524 80.356 118.896 80.1261 117.963 80.1149C117.655 80.1104 117.372 80.1104 117.113 80.1149Z"
      fill="black"
    />
    <path
      d="M117.113 80.1149C117.372 80.1104 117.655 80.1104 117.963 80.1149C118.896 80.1261 119.523 80.356 120.025 80.6707C120.112 81.5257 120.189 82.8204 120.353 83.5414C120.612 84.6843 119.424 87.854 119.276 87.8094C119.128 87.7625 117.828 87.7804 117.383 87.8094C116.935 87.8362 116.974 87.0437 117.864 86.6017C118.757 86.1598 118.831 85.3115 118.608 83.874C118.386 82.4364 117.234 80.3716 117.122 80.2243C117.115 80.1863 117.111 80.1484 117.113 80.1149Z"
      fill="black"
    />
    <path
      d="M115.413 68.5877C115.661 68.735 114.523 68.8578 114.696 68.9069C114.869 68.956 116.182 69.9158 116.059 69.9649H116.054L112.369 69.9203C112.369 69.9203 110.847 68.5028 110.141 68.3801C109.437 68.2573 107.766 69.8377 107.766 69.8377C107.766 69.8377 103.863 70.244 103.168 70.2819H103.114L104.373 69.1033C104.373 69.1033 103.582 68.956 103.656 68.956C103.69 68.956 104.079 67.9983 104.888 66.9559C105.025 67.2282 105.154 67.418 105.257 67.4805C105.394 67.5631 105.653 67.4626 105.965 67.2617C106.674 66.8086 107.67 65.8375 108.205 65.2951C108.436 65.0607 108.582 64.9045 108.582 64.9045C108.582 64.9045 108.373 64.7839 108.045 64.5964C108.567 64.4268 109.142 64.3308 109.77 64.3375C110.415 64.3442 110.991 64.4446 111.501 64.6121C111.089 64.8955 110.813 65.0942 110.813 65.0942C110.813 65.0942 110.995 65.2639 111.269 65.5161C111.881 66.0809 112.956 67.0697 113.504 67.5519C113.68 67.7082 113.803 67.8131 113.835 67.8309C113.889 67.8622 114.078 67.3711 114.284 66.6813C114.993 67.6278 115.301 68.5207 115.413 68.5877ZM112.466 67.9336C112.466 67.764 112.322 67.6256 112.144 67.6256C111.966 67.6256 111.822 67.764 111.822 67.9336C111.822 68.1033 111.966 68.2394 112.144 68.2394C112.322 68.2394 112.466 68.1033 112.466 67.9336ZM108.432 67.9336C108.432 67.764 108.288 67.6256 108.11 67.6256C107.933 67.6256 107.789 67.764 107.789 67.9336C107.789 68.1033 107.933 68.2394 108.11 68.2394C108.288 68.2394 108.432 68.1033 108.432 67.9336Z"
      fill="black"
    />
    <path
      d="M115.087 81.5703C115.24 83.7288 115.503 87.7067 115.413 87.7067C115.29 87.7067 113.361 88.0013 113.138 87.5839C112.913 87.1665 113.583 86.479 113.583 86.1106C113.583 85.8428 113.597 83.1641 113.41 81.6618C113.417 81.7109 115.015 81.577 115.087 81.5703Z"
      fill="black"
    />
    <path
      d="M114.768 62.6789C115.344 62.5584 114.759 65.1255 114.285 66.6813C114.156 66.505 114.01 66.3286 113.851 66.1545C114.1 65.0005 114.206 63.9803 113.837 64.0562C113.525 64.1232 112.888 64.4803 112.216 64.9022C111.993 64.7929 111.755 64.6924 111.501 64.612C112.47 63.9424 114.183 62.8017 114.768 62.6789Z"
      fill="black"
    />
    <path
      d="M114.285 66.6813C114.078 67.3711 113.889 67.8622 113.835 67.8309C113.804 67.8131 113.68 67.7081 113.505 67.5519C113.629 67.1077 113.752 66.621 113.851 66.1545C114.011 66.3286 114.157 66.505 114.285 66.6813Z"
      fill="black"
    />
    <path
      d="M113.837 64.0562C114.206 63.9803 114.1 65.0005 113.85 66.1545C113.423 65.6858 112.886 65.2371 112.216 64.9022C112.888 64.4804 113.524 64.1232 113.837 64.0562Z"
      fill="black"
    />
    <path
      d="M113.504 67.5519C112.956 67.0697 111.881 66.0808 111.27 65.5161C111.573 65.313 111.897 65.1009 112.216 64.9022C112.886 65.2371 113.424 65.6857 113.851 66.1545C113.752 66.621 113.628 67.1077 113.504 67.5519Z"
      fill="black"
    />
    <path
      d="M113.113 80.6551C111.366 74.8178 113.088 72.2998 113.088 72.2998L113.113 80.6551Z"
      fill="black"
    />
    <path
      d="M112.144 67.6255C112.321 67.6255 112.465 67.7639 112.465 67.9336C112.465 68.1032 112.321 68.2394 112.144 68.2394C111.966 68.2394 111.822 68.1032 111.822 67.9336C111.822 67.7639 111.966 67.6255 112.144 67.6255Z"
      fill="black"
    />
    <path
      d="M111.501 64.612C111.756 64.6924 111.994 64.7928 112.217 64.9022C111.897 65.1009 111.573 65.313 111.27 65.5161C110.996 65.2638 110.813 65.0942 110.813 65.0942C110.813 65.0942 111.09 64.8955 111.501 64.612Z"
      fill="black"
    />
    <path
      d="M109.536 69.2663C109.48 68.9448 110.797 69.0006 110.748 69.2015C110.712 69.3488 110.602 69.9091 110.386 69.9203C110.17 69.9337 109.574 69.4805 109.536 69.2663Z"
      fill="black"
    />
    <path
      d="M110.885 81.4721C110.57 83.2378 110.303 87.066 109.695 87.3384C108.929 87.6821 107.22 87.7804 106.973 87.6576C106.37 87.3585 107.294 87.1419 108.038 86.8718C108.78 86.6017 108.409 83.3583 108.583 81.6373C108.596 81.51 108.598 81.3672 108.592 81.2109C108.81 81.2109 110.885 81.4721 110.885 81.4721Z"
      fill="black"
    />
    <path
      d="M110.386 69.9203C110.602 69.9091 110.712 69.3488 110.748 69.2015C110.798 69.0006 109.48 68.9448 109.537 69.2663C109.575 69.4805 110.171 69.9337 110.386 69.9203ZM116.055 69.9649L103.169 70.2819C103.864 70.244 107.767 69.8377 107.767 69.8377C107.767 69.8377 109.438 68.2573 110.141 68.3801C110.847 68.5028 112.37 69.9203 112.37 69.9203L116.055 69.9649Z"
      fill="black"
    />
    <path
      d="M108.583 64.9045C108.583 64.9045 108.437 65.0607 108.205 65.2951C107.971 65.1634 107.692 65.0094 107.402 64.8531C107.607 64.7549 107.821 64.6701 108.043 64.5964H108.045C108.374 64.7839 108.583 64.9045 108.583 64.9045Z"
      fill="black"
    />
    <path
      d="M107.402 64.8531C107.692 65.0094 107.971 65.1634 108.205 65.2951C107.67 65.8375 106.674 66.8086 105.966 67.2617C105.829 66.9849 105.678 66.6099 105.543 66.2059C106.051 65.6857 106.667 65.1991 107.402 64.8531Z"
      fill="black"
    />
    <path
      d="M108.111 67.6255C108.288 67.6255 108.432 67.7639 108.432 67.9336C108.432 68.1032 108.288 68.2394 108.111 68.2394C107.933 68.2394 107.789 68.1032 107.789 67.9336C107.789 67.7639 107.933 67.6255 108.111 67.6255Z"
      fill="black"
    />
    <path
      d="M108.044 64.5964C107.821 64.6701 107.607 64.7549 107.403 64.8531C106.557 64.4 105.606 63.929 105.298 63.929C104.947 63.929 105.193 65.1522 105.543 66.2058C105.3 66.4514 105.082 66.7059 104.889 66.9559C104.248 65.6924 103.403 62.6789 104.023 62.6789C104.601 62.6789 106.967 63.9825 108.044 64.5964Z"
      fill="black"
    />
    <path
      d="M105.297 63.929C105.605 63.929 106.556 64.4 107.402 64.8531C106.667 65.1991 106.051 65.6858 105.542 66.2059C105.192 65.1522 104.947 63.929 105.297 63.929Z"
      fill="black"
    />
    <path
      d="M105.543 66.2058C105.678 66.6099 105.829 66.9849 105.966 67.2617C105.653 67.4626 105.395 67.563 105.257 67.4804C105.154 67.4179 105.026 67.2282 104.889 66.9559C105.082 66.7059 105.3 66.4514 105.543 66.2058Z"
      fill="black"
    />
    <path
      d="M106.552 71.8556C106.429 71.8065 103.112 70.2841 103.112 70.2841H103.169L116.054 69.9649H116.059C115.935 70.014 112.147 71.9561 113.089 72.2998C113.089 72.2998 111.366 74.8178 113.113 80.6551C112.616 80.4586 111.627 80.2622 111.157 80.606C111.058 80.6774 110.971 81.0011 110.885 81.4721C110.885 81.4721 108.81 81.2109 108.592 81.2109C108.522 79.5122 107.371 76.3335 106.863 74.5098C106.305 72.5208 106.676 71.9047 106.552 71.8556Z"
      fill="black"
    />
    <path
      d="M302.201 71.361C303.51 70.6279 304.089 72.4741 304.045 72.6314L302.887 73.8536C302.729 73.8633 302.21 73.7869 301.39 73.4043C300.366 72.9261 300.892 72.0941 302.201 71.361Z"
      fill="black"
    />
    <path
      d="M317.052 65.8703C318.281 67.8286 318.225 69.7083 318.043 70.4033L317.25 70.222C316.92 69.8594 316.131 68.9074 315.615 68.0008C314.971 66.8676 314.327 64.0571 314.723 63.5585C315.12 63.0598 315.516 63.4225 317.052 65.8703Z"
      fill="black"
    />
    <path
      d="M318.874 76.0798C317.653 75.5142 316.982 74.823 316.86 74.8858L315.334 76.394C315.843 76.5826 317.165 77.0099 318.386 77.211C319.912 77.4623 321.01 79.5361 321.376 79.7246C321.743 79.9132 321.926 79.6618 321.743 78.9705C321.559 78.2793 320.095 76.6454 318.874 76.0798Z"
      fill="black"
    />
    <path
      d="M302.028 84.6293C301.854 85.2737 301.854 85.549 302.028 85.6906C302.458 85.6906 302.949 85.6906 303.38 85.1879C303.81 84.6852 304.424 81.166 304.854 80.5516C305.279 79.9441 306.245 79.7734 308.405 79.3918L308.479 79.3785C310.249 79.0657 311.019 76.0828 311.183 74.6305L303.625 78.1496C303.523 78.4662 303.294 79.5126 303.195 81.166C303.072 83.2328 302.188 84.0373 302.028 84.6293Z"
      fill="black"
    />
    <path
      d="M298.222 84.0589C297.75 84.7969 298.17 84.6985 298.222 85.3873C299.062 85.6825 299.64 84.6493 299.955 84.0589C300.27 83.4684 299.85 81.0083 302.685 80.4179C304.953 79.9456 306.08 78.417 306.36 77.7118C306.938 77.433 308.135 76.8557 308.303 76.777C308.513 76.6786 310.98 77.761 314.551 76.5802C317.407 75.6355 317.159 74.4176 317.264 73.8764C317.614 73.8764 318.715 75.2373 319.71 74.819C321.055 74.2534 321.089 74.2758 321.299 72.9966C321.509 71.7173 319.542 70.0524 317.652 69.7572C315.762 69.462 312.345 71.3263 309.195 70.8343C306.045 70.3423 304.102 72.1467 302.895 73.5243C301.687 74.902 302.16 78.0562 301.477 79.0403C300.795 80.0243 300.585 79.3847 300.27 79.6307C299.955 79.8767 298.695 83.3208 298.222 84.0589Z"
      fill="black"
    />
    <path
      d="M314.677 79.0922C313.029 78.8409 311.625 76.3272 311.503 76.2015L315.714 76.013C315.653 76.1177 315.568 76.5409 315.714 77.3955C315.897 78.4638 317.301 78.8409 317.911 79.595C318.522 80.3491 318.827 81.2289 318.583 81.8573C318.339 82.4857 318.034 82.2972 317.667 81.4174C317.301 80.5376 316.325 79.3436 314.677 79.0922Z"
      fill="black"
    />
    <ellipse
      cx="318.255"
      cy="71.5994"
      rx="0.267401"
      ry="0.275323"
      fill="black"
    />
    <path
      d="M321.033 72.9316C320.949 72.6942 320.275 72.6942 320.022 72.7733C319.82 73.0265 320.275 73.4591 320.528 73.6438C320.724 73.4855 321.101 73.1215 321.033 72.9316Z"
      fill="black"
    />
    <path
      d="M311.702 64.5132C312.615 64.2018 315.887 68.2471 317.409 70.3087L316.622 70.6547C316.018 70.3808 314.473 69.66 313.119 68.968C311.426 68.103 310.56 64.9025 311.702 64.5132Z"
      fill="black"
    />
    <path
      d="M316.941 70.5234L316.406 70.707L315.515 70.2481C314.564 69.9116 312.573 69 312.217 68.0456C311.86 67.0911 311.89 66.0571 311.949 65.6594C311.949 65.3841 312.235 65.1088 313.376 66.21C314.516 67.3113 316.228 69.5445 316.941 70.5234Z"
      fill="black"
    />
    <path
      d="M239.839 86.6887C239.182 86.8958 238.488 86.9822 237.801 86.9424C237.07 86.8992 236.282 86.6694 235.874 86.0572C235.646 85.7136 235.565 85.2892 235.561 84.8762C235.559 84.7123 235.568 84.5462 235.625 84.3938C235.7 84.1981 235.848 84.0388 236.012 83.9079C236.648 83.3982 238.232 83.4835 238.321 83.3208C238.41 83.1581 235.057 82.6859 235.057 85.2244C235.057 88.491 240.86 87.4396 240.86 87.4396C240.86 87.4396 240.754 86.5738 240.684 86.5738C240.614 86.5738 239.841 86.6898 239.841 86.6898L239.839 86.6887Z"
      fill="black"
    />
    <path
      d="M247.5 79.3033C247.712 79.4682 247.63 79.8244 247.422 79.9063C247.345 79.9359 247.236 79.9211 247.152 79.9154C246.998 79.9052 246.916 79.7959 246.804 79.6878C246.794 79.6776 246.782 79.6662 246.774 79.6548C246.867 79.6401 246.92 79.6332 246.92 79.6332C246.92 79.6332 247.133 79.425 247.088 79.2157C247.157 79.1815 247.246 79.1997 247.326 79.2248C247.386 79.243 247.448 79.2646 247.5 79.3044V79.3033Z"
      fill="black"
    />
    <path
      d="M246.971 86.9561C247.109 87.005 247.238 87.1051 247.283 87.2451C247.031 87.2519 246.267 87.401 245.848 87.401C245.612 87.3429 245.374 87.2906 245.138 87.2382C245.091 87.228 245.046 87.2189 245 87.2087L244.912 86.8559C244.907 86.8321 244.904 86.807 244.9 86.7809L246.538 86.8935C246.683 86.9003 246.833 86.9071 246.971 86.9561Z"
      fill="black"
    />
    <path
      d="M247.088 79.2145C247.133 79.4239 246.92 79.6321 246.92 79.6321C246.92 79.6321 246.867 79.64 246.774 79.6537C246.767 79.6435 246.762 79.6332 246.76 79.6218C246.754 79.5911 246.774 79.5638 246.793 79.5422C246.86 79.4603 246.928 79.3784 246.996 79.2964C247.022 79.2646 247.049 79.2327 247.085 79.2145C247.085 79.2145 247.085 79.2145 247.087 79.2145H247.088Z"
      fill="black"
    />
    <path
      d="M245 80.0405C244.998 80.4206 244.971 80.9997 244.937 81.6676C244.928 81.8337 244.92 82.0067 244.911 82.183C244.832 83.8089 244.748 85.7728 244.901 86.7797H244.894L244.913 86.8548C244.937 86.9948 244.966 87.1142 245.001 87.2075C244.555 87.1108 244.104 87.0209 243.647 87.013C243.322 87.0073 242.762 86.9561 242.617 87.1506C242.443 87.3839 242.041 87.4601 241.992 87.4681C241.992 87.4681 241.989 87.4681 241.988 87.4681C241.805 87.5307 241.611 87.5625 241.409 87.5568C240.421 87.5307 239.995 86.6876 239.995 86.6876C239.304 85.618 239.193 84.1935 239.706 83.0284C240.146 82.0328 240.988 81.2614 241.438 80.2715C241.632 79.8437 241.75 79.3852 241.924 78.9483C242.098 78.5114 242.339 78.0835 242.719 77.8093C242.844 77.7183 242.979 77.6478 243.121 77.5897C243.769 78.1427 244.655 77.5863 244.655 77.5863C244.731 77.4919 244.784 77.3827 244.818 77.2666C244.888 77.2723 244.96 77.2814 245.029 77.2973C245.368 77.3758 245.66 77.5931 245.907 77.8401C246.088 78.021 246.825 78.6718 247.067 79.1451C247.079 79.1667 247.086 79.1895 247.091 79.2122C247.091 79.2122 247.091 79.2122 247.09 79.2122C247.053 79.2316 247.027 79.2623 247.001 79.2942C246.933 79.3761 246.865 79.458 246.797 79.5399C246.778 79.5627 246.759 79.5888 246.764 79.6196C246.766 79.6309 246.772 79.6412 246.779 79.6514C246.617 79.6765 246.338 79.7231 246.021 79.788C245.691 79.854 245.323 79.9393 245.005 80.0383L245 80.0405ZM244.894 78.3361C244.894 78.2019 244.787 78.0938 244.653 78.0938C244.52 78.0938 244.412 78.2019 244.412 78.3361C244.412 78.4704 244.52 78.5796 244.653 78.5796C244.787 78.5796 244.894 78.4715 244.894 78.3361Z"
      fill="black"
    />
    <path
      d="M246.531 80.3785C246.642 80.4331 246.631 80.5958 246.587 80.7118C246.444 81.085 245.259 81.9782 244.911 82.183C244.92 82.0067 244.929 81.8337 244.937 81.6676C245.384 81.5606 245.654 81.3558 245.995 81.0464C245.916 80.9462 245.947 80.7983 245.995 80.68C246.015 80.6311 246.038 80.581 246.08 80.5469C246.107 80.5241 246.144 80.5116 246.177 80.5139C246.164 80.5696 246.177 80.6311 246.225 80.6618C246.272 80.6402 246.27 80.5605 246.227 80.5309C246.213 80.5207 246.196 80.515 246.179 80.5139C246.184 80.4911 246.194 80.4695 246.208 80.4524C246.258 80.391 246.34 80.366 246.418 80.3614C246.457 80.3591 246.496 80.3614 246.531 80.3773V80.3785Z"
      fill="black"
    />
    <path
      d="M246.086 87.4624C246.274 87.5136 246.486 87.5955 246.543 87.7821C245.179 88.0063 243.344 87.7389 241.987 87.4704C241.987 87.4704 241.99 87.4704 241.991 87.4704C242.04 87.4624 242.441 87.3862 242.616 87.1529C242.761 86.9584 243.322 87.0084 243.646 87.0152C244.103 87.0232 244.554 87.1131 245.001 87.2098C245.047 87.22 245.092 87.2292 245.139 87.2394C245.375 87.2906 245.612 87.3429 245.849 87.4021C245.928 87.4214 246.007 87.4419 246.086 87.4635V87.4624Z"
      fill="black"
    />
    <path
      d="M246.226 80.5321C246.269 80.5617 246.271 80.6413 246.224 80.6629C246.177 80.6322 246.164 80.5708 246.176 80.515C246.194 80.515 246.21 80.5218 246.225 80.5321H246.226Z"
      fill="black"
    />
    <path
      d="M244.653 78.0938C244.786 78.0938 244.894 78.2019 244.894 78.3361C244.894 78.4704 244.786 78.5796 244.653 78.5796C244.52 78.5796 244.412 78.4715 244.412 78.3361C244.412 78.2007 244.52 78.0938 244.653 78.0938Z"
      fill="black"
    />
    <path
      d="M244.811 76.6522C244.869 76.8513 244.872 77.0698 244.816 77.2689C244.543 77.2495 244.264 77.303 243.993 77.3576C243.698 77.4156 243.396 77.4782 243.12 77.5909C243.119 77.5909 243.118 77.5886 243.115 77.5875C243.025 77.567 242.725 76.8092 242.72 76.7159C242.69 76.2676 242.773 75.8114 242.957 75.4017C242.974 75.3642 242.993 75.3255 243.029 75.3096C243.06 75.2948 243.097 75.3005 243.13 75.3073C243.546 75.3858 243.942 75.5758 244.264 75.8523C244.513 76.0651 244.722 76.337 244.813 76.6522H244.811Z"
      fill="black"
    />
    <path
      d="M243.992 77.3576C244.264 77.303 244.543 77.2495 244.815 77.2689C244.783 77.3849 244.728 77.4942 244.652 77.5886C244.652 77.5886 243.767 78.145 243.118 77.592C243.395 77.4794 243.697 77.4168 243.991 77.3588L243.992 77.3576Z"
      fill="black"
    />
    <path
      d="M243.783 77.9072L243.236 78.9425C243.186 79.07 243.038 79.1257 242.905 79.153C242.319 79.2748 241.684 79.1314 241.204 78.7696C240.726 78.4066 240.414 77.8321 240.369 77.2313C240.348 76.9594 240.418 76.6305 240.672 76.5338C240.778 76.494 240.898 76.5042 241.011 76.519C241.576 76.593 242.13 76.7512 242.648 76.9867C243.099 77.1903 243.542 77.4725 243.783 77.9072ZM243.476 78.1745C243.476 78.1745 243.616 78.0608 243.616 77.9618C243.616 77.8628 241.703 76.5623 240.859 76.7659C240.016 76.9696 241.153 78.5796 241.859 78.7537C242.694 78.9608 243.303 78.6353 243.303 78.6353C243.36 78.4817 243.418 78.3281 243.476 78.1745Z"
      fill="black"
    />
    <path
      d="M243.476 78.1745L243.303 78.6353C243.303 78.6353 242.695 78.9608 241.859 78.7537C241.153 78.5796 240.016 76.9696 240.859 76.7659C241.703 76.5623 243.616 77.8639 243.616 77.9618C243.616 78.0596 243.476 78.1745 243.476 78.1745Z"
      fill="black"
    />
    <path
      d="M243.476 78.1745L243.303 78.6353C243.303 78.6353 242.695 78.9608 241.859 78.7537C241.153 78.5796 240.016 76.9696 240.859 76.7659C241.703 76.5623 243.616 77.8639 243.616 77.9618C243.616 78.0596 243.476 78.1745 243.476 78.1745Z"
      stroke="black"
    />
    <path
      d="M244.119 82.7405C244.634 83.0216 245.111 82.9146 245.625 83.1956C245.692 83.2321 245.764 83.2753 245.787 83.3481C245.799 83.3891 245.858 83.5529 245.851 83.5939C245.78 84.08 246.041 84.2701 246.365 83.7315C246.397 83.6769 246.49 83.4846 246.473 83.4232C246.41 83.1809 246.167 83.0568 245.964 82.9112C245.406 82.5084 244.744 82.2365 244.119 81.952"
      fill="black"
    />
    <path
      d="M291.133 69.0204C292.504 68.6492 293.609 67.4156 293.729 66.0272C295.48 67.5768 298.345 67.5086 300.293 66.2057C302.242 64.9028 303.329 62.6076 303.58 60.3092C304.069 55.827 300.686 55.1571 299.447 56.2771C302.039 52.6671 297.932 47.0357 293.71 49.1654C292.738 49.6556 291.794 51.1121 291.794 52.2732C289.82 47.3603 282.644 47.5898 284.386 54.5187C282.101 54.7502 280.982 56.0889 280.633 57.711C280.284 59.3331 280.608 61.1219 281.665 62.415C282.723 63.7092 284.545 64.418 286.187 64.0112C285.488 67.1786 288.31 69.7844 291.131 69.0225L291.133 69.0204Z"
      fill="#65AA4B"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M263.765 45.2752C263.786 44.0483 263.243 42.7511 262.156 42.1475C261.07 41.545 259.452 41.9005 258.962 43.0276C258.931 40.7559 257.801 38.4513 255.823 37.277C253.844 36.1027 251.04 36.2805 249.433 37.9124C247.827 39.5454 247.86 42.5524 249.681 43.9517C248.144 43.0979 246.142 42.998 244.623 43.8858C243.106 44.7737 242.229 46.6964 242.761 48.3525C243.292 50.0085 245.314 51.1027 246.966 50.4881C244.996 51.5647 244.424 54.5015 245.852 56.2168C247.281 57.9321 250.322 57.964 251.786 56.2783C251.735 58.0803 253.517 59.5004 255.324 59.8132C257.341 60.1633 259.518 59.4521 260.921 57.9848C262.325 56.5175 262.913 54.3391 262.433 52.3812L262.642 49.5706C263.224 48.4853 263.743 46.5 263.764 45.2741L263.765 45.2752Z"
      fill="#65AA4B"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M260.568 37.5274C262.64 38.8289 265.457 38.8289 267.529 37.5263C267.558 40.2545 270.098 42.5344 272.808 42.8162C275.518 43.0981 278.202 41.7106 280.057 39.7126C282.36 42.1807 286.383 42.6301 289.384 41.0903C292.386 39.5504 294.32 36.3064 294.704 32.9513C295.203 28.5973 292.737 26.6994 290.8 26.7745C292.458 25.165 293.492 22.8634 293.351 20.5552C293.21 18.2471 291.805 16.0031 289.69 15.0738C287.575 14.1455 284.838 14.7168 283.435 16.5527C283.988 13.4001 281.93 9.99714 278.883 9.02752C276.889 8.39308 274.64 8.85449 272.94 10.0603C268.37 4.96077 260.54 6.13825 257.386 11.6273C255.736 14.4992 255.713 18.2395 257.328 21.132C255.274 20.6586 252.973 21.5782 251.809 23.3368C250.646 25.0954 250.698 27.5755 251.935 29.284C253.172 30.9914 255.51 31.813 257.542 31.2515C257.271 33.6859 258.496 36.2248 260.568 37.5274Z"
      fill="#65AA4B"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M290.832 57.5925C286.115 56.7901 279.927 57.3482 275.928 59.9367C276.007 52.8434 276.1 37.064 276.671 36.0782C277.045 35.4311 279.225 34.3659 287.462 30.8242C281.109 31.0554 277.29 31.4637 274.648 32.3725C274.648 32.3725 274.436 28.265 274.013 26.7352C274.013 26.7352 269.895 19.3509 261.141 17.0361C265.436 20.327 269.217 24.2336 270.201 26.7352C271.366 30.8817 271.666 44.4526 270.519 52.1073C265.311 49.1507 256.541 48.357 250.479 49.9683C262.594 51.6979 267.375 54.7326 269.093 55.8357C269.967 58.9671 268.858 78.9745 268.265 82.8843C267.958 84.9006 267.634 86.2729 269.093 86.7039C271.236 87.3369 273.671 88.1947 275.353 86.7039C276.765 85.4542 276.224 84.6899 275.928 82.2708C275.034 74.9549 276.238 65.1461 276.238 65.1461C276.839 64.0169 284.541 59.6479 290.833 57.5925H290.832Z"
      fill="black"
    />
    <path
      d="M255.944 88.115C256.622 87.9126 293.735 88.115 293.735 88.115L292.157 86.4585C292.157 86.4585 274.953 88.3666 273.147 82.0227C271.341 75.6788 270.497 82.0227 270.497 82.0227C270.497 82.0227 270.663 83.7651 267.674 84.7161C264.684 85.667 256.788 87.072 256.616 87.1272C256.443 87.1825 255.938 88.115 255.938 88.115H255.944Z"
      fill="black"
    />
    <path
      d="M282.442 84.1114V86.9766L284.304 85.547"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M263.373 77.3956C263.373 77.3956 265.832 80.1131 264.661 81.9007C263.49 83.6884 263.065 83.9063 263.065 83.9063C263.065 83.9063 260.706 81.7286 261.623 79.7111C262.54 77.6936 263.377 77.3918 263.377 77.3918L263.373 77.3956Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M263.052 87.1055C262.985 86.6569 262.916 82.6926 263.574 79.4535"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M256.208 80.2097C256.208 80.2097 259.873 80.483 260.267 82.5808C260.661 84.6786 260.505 85.128 260.505 85.128C260.505 85.128 257.288 85.1761 256.551 83.0869C255.813 80.9978 256.209 80.2036 256.209 80.2036L256.208 80.2097Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M262.716 87.45C262.357 87.1715 259.555 84.3534 257.783 81.5588"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M269.556 11.7341C270.07 11.9449 270.309 12.5251 270.099 13.026C269.889 13.5268 269.306 13.7699 268.792 13.5591C268.278 13.3483 268.039 12.7681 268.248 12.2672C268.458 11.7664 269.041 11.5233 269.556 11.7341Z"
      fill="white"
      stroke="black"
    />
    <path
      d="M262.666 11.2469C263.18 11.4578 263.419 12.038 263.209 12.5388C263 13.0397 262.416 13.2828 261.902 13.072C261.388 12.8612 261.149 12.2809 261.359 11.7801C261.568 11.2793 262.152 11.0361 262.666 11.2469Z"
      fill="white"
      stroke="black"
    />
    <path
      d="M277.43 13.6825C277.944 13.8933 278.183 14.4735 277.973 14.9744C277.763 15.4752 277.18 15.7183 276.666 15.5075C276.152 15.2967 275.913 14.7165 276.122 14.2156C276.332 13.7148 276.915 13.4716 277.43 13.6825Z"
      fill="white"
      stroke="black"
    />
    <path
      d="M283.335 20.0148C283.849 20.2256 284.088 20.8059 283.878 21.3067C283.669 21.8075 283.085 22.0507 282.571 21.8399C282.057 21.6291 281.818 21.0488 282.028 20.548C282.237 20.0472 282.821 19.804 283.335 20.0148Z"
      fill="white"
      stroke="black"
    />
    <path
      d="M287.764 35.115C288.278 35.3258 288.517 35.9061 288.307 36.4069C288.097 36.9077 287.514 37.1509 287 36.9401C286.486 36.7293 286.247 36.149 286.456 35.6482C286.666 35.1474 287.249 34.9042 287.764 35.115Z"
      fill="white"
      stroke="black"
    />
    <path
      d="M296.622 59.4702C297.136 59.681 297.375 60.2613 297.165 60.7621C296.956 61.2629 296.372 61.5061 295.858 61.2953C295.344 61.0844 295.105 60.5042 295.315 60.0034C295.524 59.5026 296.108 59.2594 296.622 59.4702Z"
      fill="white"
      stroke="black"
    />
    <path
      d="M291.209 62.3928C291.723 62.6036 291.962 63.1839 291.752 63.6847C291.543 64.1855 290.959 64.4287 290.445 64.2179C289.931 64.0071 289.692 63.4268 289.902 62.926C290.111 62.4252 290.695 62.182 291.209 62.3928Z"
      fill="white"
      stroke="black"
    />
    <path
      d="M296.622 53.625C297.136 53.8358 297.375 54.416 297.165 54.9169C296.956 55.4177 296.372 55.6608 295.858 55.45C295.344 55.2392 295.105 54.659 295.315 54.1581C295.524 53.6573 296.108 53.4142 296.622 53.625Z"
      fill="white"
      stroke="black"
    />
    <path
      d="M257.252 54.1121C257.766 54.3229 258.005 54.9031 257.795 55.4039C257.586 55.9048 257.002 56.1479 256.488 55.9371C255.974 55.7263 255.735 55.1461 255.945 54.6452C256.154 54.1444 256.738 53.9012 257.252 54.1121Z"
      fill="white"
      stroke="black"
    />
    <path
      d="M253.808 42.4216C254.322 42.6324 254.561 43.2127 254.351 43.7135C254.141 44.2143 253.558 44.4575 253.044 44.2466C252.53 44.0358 252.291 43.4556 252.5 42.9548C252.71 42.4539 253.293 42.2108 253.808 42.4216Z"
      fill="white"
      stroke="black"
    />
    <path
      d="M248.394 46.3184C248.909 46.5292 249.148 47.1095 248.938 47.6103C248.728 48.1111 248.145 48.3543 247.631 48.1435C247.117 47.9327 246.878 47.3524 247.087 46.8516C247.297 46.3508 247.88 46.1076 248.394 46.3184Z"
      fill="white"
      stroke="black"
    />
    <path
      d="M263.649 26.8343C264.164 27.0451 264.402 27.6253 264.193 28.1261C263.983 28.627 263.4 28.8701 262.886 28.6593C262.371 28.4485 262.133 27.8683 262.342 27.3674C262.552 26.8666 263.135 26.6234 263.649 26.8343Z"
      fill="white"
      stroke="black"
    />
    <path
      d="M257.252 24.8859C257.766 25.0967 258.005 25.6769 257.795 26.1778C257.586 26.6786 257.002 26.9217 256.488 26.7109C255.974 26.5001 255.735 25.9199 255.945 25.419C256.154 24.9182 256.738 24.675 257.252 24.8859Z"
      fill="white"
      stroke="black"
    />
    <path
      d="M280.076 24.0638C280.795 24.3587 281.131 25.1716 280.837 25.8753C280.542 26.579 279.724 26.9186 279.005 26.6237C278.286 26.3288 277.95 25.516 278.244 24.8122C278.539 24.1085 279.357 23.769 280.076 24.0638Z"
      fill="white"
      stroke="black"
    />
    <path
      d="M274.171 18.2185C274.89 18.5133 275.226 19.3262 274.932 20.03C274.637 20.7337 273.819 21.0732 273.1 20.7783C272.38 20.4835 272.044 19.6706 272.339 18.9669C272.633 18.2631 273.452 17.9236 274.171 18.2185Z"
      fill="white"
      stroke="black"
    />
    <path
      d="M266.789 31.8575C267.508 32.1524 267.844 32.9652 267.55 33.669C267.255 34.3727 266.437 34.7122 265.718 34.4174C264.999 34.1225 264.662 33.3096 264.957 32.6059C265.251 31.9021 266.07 31.5626 266.789 31.8575Z"
      fill="white"
      stroke="black"
    />
    <path
      d="M286.966 24.5509C287.685 24.8458 288.021 25.6587 287.727 26.3624C287.432 27.0662 286.614 27.4057 285.895 27.1108C285.175 26.8159 284.839 26.0031 285.134 25.2993C285.428 24.5956 286.247 24.2561 286.966 24.5509Z"
      fill="white"
      stroke="black"
    />
    <defs>
      <clipPath id="clip0_808_24688">
        <rect
          width="43.907"
          height="78.1395"
          fill="white"
          transform="translate(54.3252 9.78366)"
        />
      </clipPath>
      <clipPath id="clip1_808_24688">
        <rect
          width="46.8837"
          height="87.0698"
          fill="white"
          transform="translate(0 0.853424)"
        />
      </clipPath>
    </defs>
  </svg>
);
